import React, { useState, useEffect } from 'react';

import { RiDeleteBin6Fill } from 'react-icons/ri';
import {
  AiTwotoneEdit,
  AiFillEye,
  AiTwotoneTag,
  AiTwotonePushpin,
  AiTwotoneBulb,
  AiOutlineInfoCircle,
  AiOutlineCheckCircle,
  AiOutlineSearch,
  AiOutlineMail,
} from 'react-icons/ai';
import { BsArrowBarDown, BsTelephoneFill } from 'react-icons/bs';
import { RxCrossCircled } from 'react-icons/rx';

import { GrMail } from 'react-icons/gr';

import { MdOutlineVerified } from 'react-icons/md';
import { Link } from 'react-router-dom';

// import ClickFriends from "../../../components/userCard/icons/add-user-click.png";
import addUserColor from './Images/Icons/addUserColor.png';
import addUserWhite from './Images/Icons/addUserWhite.png';
import heartWhite from './Images/Icons/heartWhite.png';
import heartColor from './Images/Icons/heartColor.png';
import noteWhite from './Images/Icons/noteWhite.png';
import noteColor from './Images/Icons/noteColor.png';
import pictureWhite from './Images/Icons/pictureWhite.png';
import pictureColor from './Images/Icons/pictureColor.png';
import videoWhite from './Images/Icons/videoWhite.png';
import videoColor from './Images/Icons/videoColor.png';
import checkWhite from './Images/Icons/checkWhite.png';
import checkColor from './Images/Icons/checkColor.png';
import axios from 'axios';

const HomepageUsers = ({ users, loading, text = 'Login to Access' }) => {
  const [isShowFull, setIsShowFull] = useState(false);
  function abbreviateName(name) {
    const nameArray = name.split(' ');
    const firstName = nameArray[0];
    const lastNameInitial = nameArray[1][0];
    const abbreviatedName = `${firstName} ${lastNameInitial}.`;
    return abbreviatedName;
  }
  const profileCompany = "testing paragraph"

  if (loading) {
    return <h2>loading</h2>;
  }
  return (
    <div>
     <div>
            <div className=" bg-[#ffffff]  lg:m-10 lg:mb-6 lg:mx-20 rounded-tr-md rounded-tl-md mb-5 border border-[#bebebe]">
              <div className="bg-[#ededed] rounded-tr rounded-tl px-2 py-3 flex justify-between items-center">
                <div className="text-lg font-bold">We Specialize in Short-term & First-lien bridge Loans</div>
                {/* <button className="bg-[#ff1717] text-white p-2 rounded ">
              <AiOutlineClose />
            </button> */}
              </div>
              <div className="lg:flex lg:justify-start px-3   mt-8">
                <div className="  ">
                  <p className="text-center text-sm text-[#969696]">
                    PROFILE ID: 43543
                  </p>
                  <div className="flex m-2 border w-32 h-36  lg:w-36 mx-auto">
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJ4lSks4J0r1JXjt4WosF6Y3IG-90ubicpoT4pPFGidzM4GeBhY2GiadpVcMidsaE5-Bc&usqp=CAU"
                      alt=""
                      className="w-40 border p-2 mx-auto object-cover"
                    />
                  </div>

                  <div className="flex justify-center lg:justify-between">
                    {/* Friends icon */}

                    <div>
                      <div className="relative cursor-pointer group mx-2 lg:mx-0">
                        <div className="relative">
                          <img src={addUserColor} alt="" className="w-5	" />
                          <img
                            src={addUserWhite}
                            alt=""
                            className="	absolute top-0 left-0 hover:opacity-0 w-5"
                          />
                        </div>
                        <span className="absolute w-auto p-2 m-2 min-w-max bottom-5 right-[-50px] rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100">
                          {text}
                        </span>
                      </div>
                      <img src={checkWhite} alt="" className="w-4 mx-auto mt-2" />
                    </div>

                    {/* Video Icon */}

                    <div>
                      <div className="relative cursor-pointer group mx-2 lg:mx-0">
                        <div className="relative">
                          <img src={videoColor} alt="" className="w-5	" />
                          <img
                            src={videoWhite}
                            alt=""
                            className="	absolute top-0 left-0 hover:opacity-0 w-5"
                          />
                        </div>
                        <span className="absolute w-auto p-2 m-2 min-w-max bottom-5 right-[-50px] rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100">
                          {text}
                        </span>
                      </div>
                      <img src={checkWhite} alt="" className="w-4 mx-auto mt-2" />
                    </div>

                    <div>
                      <div className="relative cursor-pointer group mx-2 lg:mx-0">
                        <div className="relative">
                          <img src={pictureColor} alt="" className="w-5	" />
                          <img
                            src={pictureWhite}
                            alt=""
                            className="	absolute top-0 left-0 hover:opacity-0 w-5"
                          />
                        </div>
                        <span className="absolute w-auto p-2 m-2 min-w-max bottom-5 right-[-50px] rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100">
                          {text}
                        </span>
                      </div>
                      <img src={checkWhite} alt="" className="w-4 mx-auto mt-2" />
                    </div>

                    <div>
                      <div className="relative cursor-pointer group mx-2 lg:mx-0">
                        <div className="relative">
                          <img src={heartColor} alt="" className="w-5	" />
                          <img
                            src={heartWhite}
                            alt=""
                            className="	absolute top-0 left-0 hover:opacity-0 w-5"
                          />
                        </div>
                        <span className="absolute w-auto p-2 m-2 min-w-max bottom-5 right-[-50px] rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100">
                          {text}
                        </span>
                      </div>
                      <img src={checkWhite} alt="" className="w-4 mx-auto mt-2" />
                    </div>

                    <div>
                      <div className="relative cursor-pointer group mx-2 lg:mx-0">
                        <div className="relative">
                          <img src={noteColor} alt="" className="w-5" />
                          <img
                            src={noteWhite}
                            alt=""
                            className="	absolute top-0 left-0 hover:opacity-0 w-5"
                          />
                        </div>
                        <span className="absolute w-auto p-2 m-2 min-w-max bottom-5 right-[-50px] rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100">
                          {text}
                        </span>
                      </div>
                      <img src={checkWhite} alt="" className="w-4 mx-auto mt-2" />
                    </div>
                  </div>
                </div>
                <div className="lg:ml-6 lg:w-full">
                  <div className="lg:flex lg:justify-between">
                    <div>
                      <div className="flex justify-center items-center text-center text-sm mt-2 lg:mt-0 text-[#969696] lg:flex lg:justify-start ">
                        <div className="pr-2 ">Sagar G</div>
                        <AiTwotonePushpin />
                        <div className="pl-1">New York City, New York</div>
                      </div>

                      <div className="lg:flex lg:justify-between">
                        <div className="flex  justify-center lg:flex lg:justify-start">
                          <button
                            className={`px-3 py-2 rounded mt-3 mr-2 text-white text-sm bg-[#28a745] cursor-default`}
                          >
                          Business & Private Investors
                          </button>
                          <button
                            className={`bg-[#17a2b8] px-3 py-2 rounded mt-3 text-white text-sm cursor-default`}
                          >
                            
                               AVAILABLE CAPITAL: $2M 
                              
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex  justify-center lg:flex lg:flex-col lg:w-32">
                      <div className="relative cursor-pointer group mx-2 lg:mx-0">
                        <div className="">
                          <button className="bg-[#ffc107] px-2 py-2 rounded mt-3 mr-2 lg:m-0 text-white text-sm hover:bg-[#edb205] w-full">
                            <div className="flex justify-between items-center">
                              <AiOutlineMail />
                              <div className="text-sm">Send Message</div>
                            </div>
                          </button>
                        </div>
                        <span className="absolute w-auto p-2 m-2 min-w-max bottom-[-6px] right-[130px] rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-right group-hover:scale-100">
                          {text}
                        </span>
                      </div>

                      <button className="flex justify-between items-center bg-[#007bff] px-2 py-2 rounded mt-3 text-white text-sm hover:bg-[#0069d9]">
                        <BsTelephoneFill />
                        <div>Login to View</div>
                      </button>
                    </div>
                  </div>

                  {/* paragraph about the company */}
                  <div>
                    <div className="h-5 border-b-2 border-[#b4b4b4] text-center text-2xl mb-1"></div>

                    We are a direct private lending firm specialising in loans. We originate short-term, first-lien bridge, development, and mezzanine loans to owners, project owners and developers. Our direct funding platform fills the void created by traditional lending institutions, providing developers and owners with alternative financing solutions that are creative, flexible, and fast.


                 

                    <div className="mt-1 border-b-2 border-[#b4b4b4] text-center text-2xl "></div>

                    <div className="lg:flex lg:justify-between lg:items-center mt-2">
                      {/* Type */}
                      <div className="flex justify-center">
                        <div className="flex justify-start mr-4">
                          <div className="font-medium uppercase mr-2">Type:</div>

                          <div className="flex justify-start items-center cursor-pointer inline-flex relative w-fit group">
                            <div>Network</div>
                            <div className="absolute w-auto top-6 left-0 p-2 min-w-max rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-top group-hover:scale-100">
                              <div className="flex justify-start items-center mb-1">
                                <img src={checkWhite} alt="" className="w-4 mr-1" />
                                <h6>Real Estate</h6>
                              </div>
                           
                            </div>
                            <BsArrowBarDown className="ml-1" />
                          </div>
                        </div>

                        {/* Industry */}
                        <div className="flex justify-start ml-3">
                          <div className="font-medium uppercase mr-2">Industry:</div>

                          <div className=" justify-start items-center cursor-pointer inline-flex relative w-fit group">
                            <div>Category</div>
                            <div className="absolute w-auto top-6 left-0 p-2 min-w-max rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-top group-hover:scale-100">
                              <div className="flex justify-start items-center mb-1">
                                <img src={checkWhite} alt="" className="w-4 mr-1" />
                                <h6>Financial</h6>
                              </div>
                            </div>
                            <BsArrowBarDown className="ml-1" />
                          </div>
                        </div>
                      </div>
                    
                        <div className="flex justify-center items-center bg-[#05CC03] text-white text-center my-3  py-3 font-semibold lg:bg-[#fff0] lg:text-[#05CC03] lg:text-xl lg:semi-bold lg:my-0 lg:py-0 lg:text-left lg:mt-0 mt-3">
                          {/* <div className="flex justify-center items-center bg-[#0C0] text-white text-center my-3  py-3 font-semibold lg:bg-[#fff0] lg:text-[#0C0] lg:text-xl lg:semi-bold lg:my-0 lg:py-0 lg:text-left lg:mt-0 mt-3"> */}
                          <div className="text-white lg:text-[#05CC03] pr-1 text-2xl">
                            <AiOutlineCheckCircle />
                          </div>
                          ID Verified
                        </div>
                      
                      
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap justify-center pb-3 lg:justify-start lg:mt-2 px-3">
              <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                  <AiTwotoneTag />
                  <p className="ml-1">Real Estate</p>
                </button>
                <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                  <AiTwotoneTag />
                  <p className="ml-1">Consulting</p>
                </button>
                <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                  <AiTwotoneTag />
                  <p className="ml-1">Commercial Lending</p>
                </button>
                <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                  <AiTwotoneTag />
                  <p className="ml-1">Property Developer</p>
                </button>
                <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                  <AiTwotoneTag />
                  <p className="ml-1">Short-Term Loans</p>
                </button>
            
           
          
              </div>
            </div>

            <div className=" bg-[#ffffff]  lg:m-10 lg:mb-6 lg:mx-20 rounded-tr-md rounded-tl-md mb-5 border border-[#bebebe]">
              <div className="bg-[#ededed] rounded-tr rounded-tl px-2 py-3 flex justify-between items-center">
                <div className="text-lg font-bold">E-Therapy on track to hit $30M</div>
                {/* <button className="bg-[#ff1717] text-white p-2 rounded ">
              <AiOutlineClose />
            </button> */}
              </div>
              <div className="lg:flex lg:justify-start px-3   mt-8">
                <div className="  ">
                  <p className="text-center text-sm text-[#969696]">
                    PROFILE ID: 34344
                  </p>
                  <div className="flex m-2 border w-32 h-36  lg:w-36 mx-auto">
                    <img
                      src="https://i.imgur.com/ztOlmli.png"
                      alt=""
                      className="w-40 border p-2 mx-auto object-cover"
                    />
                  </div>

                  <div className="flex justify-center lg:justify-between">
                    {/* Friends icon */}

                    <div>
                      <div className="relative cursor-pointer group mx-2 lg:mx-0">
                        <div className="relative">
                          <img src={addUserColor} alt="" className="w-5	" />
                          <img
                            src={addUserWhite}
                            alt=""
                            className="	absolute top-0 left-0 hover:opacity-0 w-5"
                          />
                        </div>
                        <span className="absolute w-auto p-2 m-2 min-w-max bottom-5 right-[-50px] rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100">
                          {text}
                        </span>
                      </div>
                      <img src={checkWhite} alt="" className="w-4 mx-auto mt-2" />
                    </div>

                    {/* Video Icon */}

                    <div>
                      <div className="relative cursor-pointer group mx-2 lg:mx-0">
                        <div className="relative">
                          <img src={videoColor} alt="" className="w-5	" />
                          <img
                            src={videoWhite}
                            alt=""
                            className="	absolute top-0 left-0 hover:opacity-0 w-5"
                          />
                        </div>
                        <span className="absolute w-auto p-2 m-2 min-w-max bottom-5 right-[-50px] rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100">
                          {text}
                        </span>
                      </div>
                      <img src={checkWhite} alt="" className="w-4 mx-auto mt-2" />
                    </div>

                    <div>
                      <div className="relative cursor-pointer group mx-2 lg:mx-0">
                        <div className="relative">
                          <img src={pictureColor} alt="" className="w-5	" />
                          <img
                            src={pictureWhite}
                            alt=""
                            className="	absolute top-0 left-0 hover:opacity-0 w-5"
                          />
                        </div>
                        <span className="absolute w-auto p-2 m-2 min-w-max bottom-5 right-[-50px] rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100">
                          {text}
                        </span>
                      </div>
                      <img src={checkWhite} alt="" className="w-4 mx-auto mt-2" />
                    </div>

                    <div>
                      <div className="relative cursor-pointer group mx-2 lg:mx-0">
                        <div className="relative">
                          <img src={heartColor} alt="" className="w-5	" />
                          <img
                            src={heartWhite}
                            alt=""
                            className="	absolute top-0 left-0 hover:opacity-0 w-5"
                          />
                        </div>
                        <span className="absolute w-auto p-2 m-2 min-w-max bottom-5 right-[-50px] rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100">
                          {text}
                        </span>
                      </div>
                      <img src={checkWhite} alt="" className="w-4 mx-auto mt-2" />
                    </div>

                    <div>
                      <div className="relative cursor-pointer group mx-2 lg:mx-0">
                        <div className="relative">
                          <img src={noteColor} alt="" className="w-5" />
                          <img
                            src={noteWhite}
                            alt=""
                            className="	absolute top-0 left-0 hover:opacity-0 w-5"
                          />
                        </div>
                        <span className="absolute w-auto p-2 m-2 min-w-max bottom-5 right-[-50px] rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100">
                          {text}
                        </span>
                      </div>
                      <img src={checkWhite} alt="" className="w-4 mx-auto mt-2" />
                    </div>
                  </div>
                </div>
                <div className="lg:ml-6 lg:w-full">
                  <div className="lg:flex lg:justify-between">
                    <div>
                      <div className="flex justify-center items-center text-center text-sm mt-2 lg:mt-0 text-[#969696] lg:flex lg:justify-start ">
                        <div className="pr-2 ">Sandra S.</div>
                        <AiTwotonePushpin />
                        <div className="pl-1">Seattle, Washington</div>
                      </div>

                      <div className="lg:flex lg:justify-between">
                        <div className="flex  justify-center lg:flex lg:justify-start">
                        <button
                            className={`px-3 py-2 rounded mt-3 mr-2 text-white text-sm cursor-default bg-[#f0ad4e] `}
                          >
                           STARTUP
                          </button>
                          <button
                            className={`bg-[#dc3545] px-3 py-2 rounded mt-3 text-white text-sm cursor-default`}
                          >
                            
                            FUNDING NEEDED: $5M
                              
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex  justify-center lg:flex lg:flex-col lg:w-32">
                      <div className="relative cursor-pointer group mx-2 lg:mx-0">
                        <div className="">
                          <button className="bg-[#ffc107] px-2 py-2 rounded mt-3 mr-2 lg:m-0 text-white text-sm hover:bg-[#edb205] w-full">
                            <div className="flex justify-between items-center">
                              <AiOutlineMail />
                              <div className="text-sm">Send Message</div>
                            </div>
                          </button>
                        </div>
                        <span className="absolute w-auto p-2 m-2 min-w-max bottom-[-6px] right-[130px] rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-right group-hover:scale-100">
                          {text}
                        </span>
                      </div>

                      <button className="flex justify-between items-center bg-[#007bff] px-2 py-2 rounded mt-3 text-white text-sm hover:bg-[#0069d9]">
                        <BsTelephoneFill />
                        <div>Login to View</div>
                      </button>
                    </div>
                  </div>

                  {/* paragraph about the company */}
                  <div>
                    <div className="h-5 border-b-2 border-[#b4b4b4] text-center text-2xl mb-1"></div>

                    We are an online therapy is now raising $5M Series A to expand our services globally and hit $30M in revenue in the next 24 mo. We are on track to cross $1M ARR by Dec and recently closed a $2M seed.Below are some of the major milestones we have achieved. - A fast-growing, innovative mental health tech company - YoY revenue increase of 340% in last year alone.



                 

                    <div className="mt-1 border-b-2 border-[#b4b4b4] text-center text-2xl "></div>

                    <div className="lg:flex lg:justify-between lg:items-center mt-2">
                      {/* Type */}
                      <div className="flex justify-center">
                        <div className="flex justify-start mr-4">
                          <div className="font-medium uppercase mr-2">Type:</div>

                          <div className="flex justify-start items-center cursor-pointer inline-flex relative w-fit group">
                            <div>Network</div>
                            <div className="absolute w-auto top-6 left-0 p-2 min-w-max rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-top group-hover:scale-100">
                              <div className="flex justify-start items-center mb-1">
                                <img src={checkWhite} alt="" className="w-4 mr-1" />
                                <h6>Startup</h6>
                              </div>
                           
                            </div>
                            <BsArrowBarDown className="ml-1" />
                          </div>
                        </div>

                        {/* Industry */}
                        <div className="flex justify-start ml-3">
                          <div className="font-medium uppercase mr-2">Industry:</div>

                          <div className=" justify-start items-center cursor-pointer inline-flex relative w-fit group">
                            <div>Category</div>
                            <div className="absolute w-auto top-6 left-0 p-2 min-w-max rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-top group-hover:scale-100">
                              <div className="flex justify-start items-center mb-1">
                                <img src={checkWhite} alt="" className="w-4 mr-1" />
                                <h6>Software</h6>
                              </div>
                            </div>
                            <BsArrowBarDown className="ml-1" />
                          </div>
                        </div>
                      </div>
                    
                        <div className="flex justify-center items-center bg-[#05CC03] text-white text-center my-3  py-3 font-semibold lg:bg-[#fff0] lg:text-[#05CC03] lg:text-xl lg:semi-bold lg:my-0 lg:py-0 lg:text-left lg:mt-0 mt-3">
                          {/* <div className="flex justify-center items-center bg-[#0C0] text-white text-center my-3  py-3 font-semibold lg:bg-[#fff0] lg:text-[#0C0] lg:text-xl lg:semi-bold lg:my-0 lg:py-0 lg:text-left lg:mt-0 mt-3"> */}
                          <div className="text-white lg:text-[#05CC03] pr-1 text-2xl">
                            <AiOutlineCheckCircle />
                          </div>
                          ID Verified
                        </div>
                      
                      
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap justify-center pb-3 lg:justify-start lg:mt-2 px-3">
                <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                  <AiTwotoneTag />
                  <p className="ml-1">network</p>
                </button>
                <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                  <AiTwotoneTag />
                  <p className="ml-1">Startup</p>
                </button>
                <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                  <AiTwotoneTag />
                  <p className="ml-1">Therapy</p>
                </button>
                <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                  <AiTwotoneTag />
                  <p className="ml-1">United States</p>
                </button>
                <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                  <AiTwotoneTag />
                  <p className="ml-1">SaaS</p>
                </button>
    
            
           
          
              </div>
            </div>

            <div className=" bg-[#ffffff]  lg:m-10 lg:mb-6 lg:mx-20 rounded-tr-md rounded-tl-md mb-5 border border-[#bebebe]">
              <div className="bg-[#ededed] rounded-tr rounded-tl px-2 py-3 flex justify-between items-center">
                <div className="text-lg font-bold">Our Fund Invests Across The US and Latin America In All Sectors</div>
                {/* <button className="bg-[#ff1717] text-white p-2 rounded ">
              <AiOutlineClose />
            </button> */}
              </div>
              <div className="lg:flex lg:justify-start px-3   mt-8">
                <div className="  ">
                  <p className="text-center text-sm text-[#969696]">
                    PROFILE ID: 53422
                  </p>
                  <div className="flex m-2 border w-32 h-36  lg:w-36 mx-auto">
                    <img
                      src="https://i.imgur.com/uKUkUSQ.png"
                      alt=""
                      className="w-40 border p-2 mx-auto object-cover"
                    />
                  </div>

                  <div className="flex justify-center lg:justify-between">
                    {/* Friends icon */}

                    <div>
                      <div className="relative cursor-pointer group mx-2 lg:mx-0">
                        <div className="relative">
                          <img src={addUserColor} alt="" className="w-5	" />
                          <img
                            src={addUserWhite}
                            alt=""
                            className="	absolute top-0 left-0 hover:opacity-0 w-5"
                          />
                        </div>
                        <span className="absolute w-auto p-2 m-2 min-w-max bottom-5 right-[-50px] rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100">
                          {text}
                        </span>
                      </div>
                      <img src={checkWhite} alt="" className="w-4 mx-auto mt-2" />
                    </div>

                    {/* Video Icon */}

                    <div>
                      <div className="relative cursor-pointer group mx-2 lg:mx-0">
                        <div className="relative">
                          <img src={videoColor} alt="" className="w-5	" />
                          <img
                            src={videoWhite}
                            alt=""
                            className="	absolute top-0 left-0 hover:opacity-0 w-5"
                          />
                        </div>
                        <span className="absolute w-auto p-2 m-2 min-w-max bottom-5 right-[-50px] rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100">
                          {text}
                        </span>
                      </div>
                      <img src={checkWhite} alt="" className="w-4 mx-auto mt-2" />
                    </div>

                    <div>
                      <div className="relative cursor-pointer group mx-2 lg:mx-0">
                        <div className="relative">
                          <img src={pictureColor} alt="" className="w-5	" />
                          <img
                            src={pictureWhite}
                            alt=""
                            className="	absolute top-0 left-0 hover:opacity-0 w-5"
                          />
                        </div>
                        <span className="absolute w-auto p-2 m-2 min-w-max bottom-5 right-[-50px] rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100">
                          {text}
                        </span>
                      </div>
                      <img src={checkWhite} alt="" className="w-4 mx-auto mt-2" />
                    </div>

                    <div>
                      <div className="relative cursor-pointer group mx-2 lg:mx-0">
                        <div className="relative">
                          <img src={heartColor} alt="" className="w-5	" />
                          <img
                            src={heartWhite}
                            alt=""
                            className="	absolute top-0 left-0 hover:opacity-0 w-5"
                          />
                        </div>
                        <span className="absolute w-auto p-2 m-2 min-w-max bottom-5 right-[-50px] rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100">
                          {text}
                        </span>
                      </div>
                      <img src={checkWhite} alt="" className="w-4 mx-auto mt-2" />
                    </div>

                    <div>
                      <div className="relative cursor-pointer group mx-2 lg:mx-0">
                        <div className="relative">
                          <img src={noteColor} alt="" className="w-5" />
                          <img
                            src={noteWhite}
                            alt=""
                            className="	absolute top-0 left-0 hover:opacity-0 w-5"
                          />
                        </div>
                        <span className="absolute w-auto p-2 m-2 min-w-max bottom-5 right-[-50px] rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100">
                          {text}
                        </span>
                      </div>
                      <img src={checkWhite} alt="" className="w-4 mx-auto mt-2" />
                    </div>
                  </div>
                </div>
                <div className="lg:ml-6 lg:w-full">
                  <div className="lg:flex lg:justify-between">
                    <div>
                      <div className="flex justify-center items-center text-center text-sm mt-2 lg:mt-0 text-[#969696] lg:flex lg:justify-start ">
                        <div className="pr-2 ">David F.</div>
                        <AiTwotonePushpin />
                        <div className="pl-1">Toronto, Canada</div>
                      </div>

                      <div className="lg:flex lg:justify-between">
                        <div className="flex  justify-center lg:flex lg:justify-start">
                          <button
                            className={`px-3 py-2 rounded mt-3 mr-2 text-white text-sm bg-[#28a745] cursor-default`}
                          >
                          Business & Private Investors
                          </button>
                          <button
                            className={`bg-[#17a2b8] px-3 py-2 rounded mt-3 text-white text-sm cursor-default`}
                          >
                            
                               AVAILABLE CAPITAL: $400K 
                              
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex  justify-center lg:flex lg:flex-col lg:w-32">
                      <div className="relative cursor-pointer group mx-2 lg:mx-0">
                        <div className="">
                          <button className="bg-[#ffc107] px-2 py-2 rounded mt-3 mr-2 lg:m-0 text-white text-sm hover:bg-[#edb205] w-full">
                            <div className="flex justify-between items-center">
                              <AiOutlineMail />
                              <div className="text-sm">Send Message</div>
                            </div>
                          </button>
                        </div>
                        <span className="absolute w-auto p-2 m-2 min-w-max bottom-[-6px] right-[130px] rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-right group-hover:scale-100">
                          {text}
                        </span>
                      </div>

                      <button className="flex justify-between items-center bg-[#007bff] px-2 py-2 rounded mt-3 text-white text-sm hover:bg-[#0069d9]">
                        <BsTelephoneFill />
                        <div>Login to View</div>
                      </button>
                    </div>
                  </div>

                  {/* paragraph about the company */}
                  <div>
                    <div className="h-5 border-b-2 border-[#b4b4b4] text-center text-2xl mb-1"></div>

                    We are a smart angel investment group, comprised of current and former top software and IT executives. We partners come from the full spectrum of the high-tech sector. No matter how innovative the idea, how big the market, and how bright the entrepreneurs, every early-stage company will benefit from smart and active investors who participate in strategic business decisions, open doors, and generally provide an experienced sounding board to management. That’s what we do at AfterDox.Our mission: Creating value through hands-on involvement in a hand-picked portfolio.


                 

                    <div className="mt-1 border-b-2 border-[#b4b4b4] text-center text-2xl "></div>

                    <div className="lg:flex lg:justify-between lg:items-center mt-2">
                      {/* Type */}
                      <div className="flex justify-center">
                        <div className="flex justify-start mr-4">
                          <div className="font-medium uppercase mr-2">Type:</div>

                          <div className="flex justify-start items-center cursor-pointer inline-flex relative w-fit group">
                            <div>Network</div>
                            <div className="absolute w-auto top-6 left-0 p-2 min-w-max rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-top group-hover:scale-100">
                              <div className="flex justify-start items-center mb-1">
                                <img src={checkWhite} alt="" className="w-4 mr-1" />
                                <h6>Investor Group</h6>
                              </div>
                           
                            </div>
                            <BsArrowBarDown className="ml-1" />
                          </div>
                        </div>

                        {/* Industry */}
                        <div className="flex justify-start ml-3">
                          <div className="font-medium uppercase mr-2">Industry:</div>

                          <div className=" justify-start items-center cursor-pointer inline-flex relative w-fit group">
                            <div>Category</div>
                            <div className="absolute w-auto top-6 left-0 p-2 min-w-max rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-top group-hover:scale-100">
                              <div className="flex justify-start items-center mb-1">
                                <img src={checkWhite} alt="" className="w-4 mr-1" />
                                <h6>SaaS</h6>
                              </div>
                            </div>
                            <BsArrowBarDown className="ml-1" />
                          </div>
                        </div>
                      </div>
                    
                        <div className="flex justify-center items-center bg-[#05CC03] text-white text-center my-3  py-3 font-semibold lg:bg-[#fff0] lg:text-[#05CC03] lg:text-xl lg:semi-bold lg:my-0 lg:py-0 lg:text-left lg:mt-0 mt-3">
                          {/* <div className="flex justify-center items-center bg-[#0C0] text-white text-center my-3  py-3 font-semibold lg:bg-[#fff0] lg:text-[#0C0] lg:text-xl lg:semi-bold lg:my-0 lg:py-0 lg:text-left lg:mt-0 mt-3"> */}
                          <div className="text-white lg:text-[#05CC03] pr-1 text-2xl">
                            <AiOutlineCheckCircle />
                          </div>
                          ID Verified
                        </div>
                      
                      
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap justify-center pb-3 lg:justify-start lg:mt-2 px-3">
                <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                  <AiTwotoneTag />
                  <p className="ml-1">network</p>
                </button>
                <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                  <AiTwotoneTag />
                  <p className="ml-1">IT Consulting</p>
                </button>
                <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                  <AiTwotoneTag />
                  <p className="ml-1">SaaS Technology</p>
                </button>
                <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                  <AiTwotoneTag />
                  <p className="ml-1">Fast-Growing</p>
                </button>
                <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                  <AiTwotoneTag />
                  <p className="ml-1">United States</p>
                </button>
            
            
           
          
              </div>
            </div>

            <div className=" bg-[#ffffff]  lg:m-10 lg:mb-6 lg:mx-20 rounded-tr-md rounded-tl-md mb-5 border border-[#bebebe]">
              <div className="bg-[#ededed] rounded-tr rounded-tl px-2 py-3 flex justify-between items-center">
                <div className="text-lg font-bold">We Partner With Fast Growing Technology Companies</div>
                {/* <button className="bg-[#ff1717] text-white p-2 rounded ">
              <AiOutlineClose />
            </button> */}
              </div>
              <div className="lg:flex lg:justify-start px-3   mt-8">
                <div className="  ">
                  <p className="text-center text-sm text-[#969696]">
                    PROFILE ID: 1434
                  </p>
                  <div className="flex m-2 border w-32 h-36  lg:w-36 mx-auto">
                    <img
                      src="https://linmac.com/content/uploads/2021/07/Henry_Villanueva_700x600.jpg"
                      alt=""
                      className="w-40 border p-2 mx-auto object-cover"
                    />
                  </div>

                  <div className="flex justify-center lg:justify-between">
                    {/* Friends icon */}

                    <div>
                      <div className="relative cursor-pointer group mx-2 lg:mx-0">
                        <div className="relative">
                          <img src={addUserColor} alt="" className="w-5	" />
                          <img
                            src={addUserWhite}
                            alt=""
                            className="	absolute top-0 left-0 hover:opacity-0 w-5"
                          />
                        </div>
                        <span className="absolute w-auto p-2 m-2 min-w-max bottom-5 right-[-50px] rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100">
                          {text}
                        </span>
                      </div>
                      <img src={checkWhite} alt="" className="w-4 mx-auto mt-2" />
                    </div>

                    {/* Video Icon */}

                    <div>
                      <div className="relative cursor-pointer group mx-2 lg:mx-0">
                        <div className="relative">
                          <img src={videoColor} alt="" className="w-5	" />
                          <img
                            src={videoWhite}
                            alt=""
                            className="	absolute top-0 left-0 hover:opacity-0 w-5"
                          />
                        </div>
                        <span className="absolute w-auto p-2 m-2 min-w-max bottom-5 right-[-50px] rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100">
                          {text}
                        </span>
                      </div>
                      <img src={checkWhite} alt="" className="w-4 mx-auto mt-2" />
                    </div>

                    <div>
                      <div className="relative cursor-pointer group mx-2 lg:mx-0">
                        <div className="relative">
                          <img src={pictureColor} alt="" className="w-5	" />
                          <img
                            src={pictureWhite}
                            alt=""
                            className="	absolute top-0 left-0 hover:opacity-0 w-5"
                          />
                        </div>
                        <span className="absolute w-auto p-2 m-2 min-w-max bottom-5 right-[-50px] rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100">
                          {text}
                        </span>
                      </div>
                      <img src={checkWhite} alt="" className="w-4 mx-auto mt-2" />
                    </div>

                    <div>
                      <div className="relative cursor-pointer group mx-2 lg:mx-0">
                        <div className="relative">
                          <img src={heartColor} alt="" className="w-5	" />
                          <img
                            src={heartWhite}
                            alt=""
                            className="	absolute top-0 left-0 hover:opacity-0 w-5"
                          />
                        </div>
                        <span className="absolute w-auto p-2 m-2 min-w-max bottom-5 right-[-50px] rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100">
                          {text}
                        </span>
                      </div>
                      <img src={checkWhite} alt="" className="w-4 mx-auto mt-2" />
                    </div>

                    <div>
                      <div className="relative cursor-pointer group mx-2 lg:mx-0">
                        <div className="relative">
                          <img src={noteColor} alt="" className="w-5" />
                          <img
                            src={noteWhite}
                            alt=""
                            className="	absolute top-0 left-0 hover:opacity-0 w-5"
                          />
                        </div>
                        <span className="absolute w-auto p-2 m-2 min-w-max bottom-5 right-[-50px] rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100">
                          {text}
                        </span>
                      </div>
                      <img src={checkWhite} alt="" className="w-4 mx-auto mt-2" />
                    </div>
                  </div>
                </div>
                <div className="lg:ml-6 lg:w-full">
                  <div className="lg:flex lg:justify-between">
                    <div>
                      <div className="flex justify-center items-center text-center text-sm mt-2 lg:mt-0 text-[#969696] lg:flex lg:justify-start ">
                        <div className="pr-2 ">Henry W.</div>
                        <AiTwotonePushpin />
                        <div className="pl-1">Miami, Florida</div>
                      </div>

                      <div className="lg:flex lg:justify-between">
                        <div className="flex  justify-center lg:flex lg:justify-start">
                          <button
                            className={`px-3 py-2 rounded mt-3 mr-2 text-white text-sm bg-[#28a745] cursor-default`}
                          >
                          Business & Private Investors
                          </button>
                          <button
                            className={`bg-[#17a2b8] px-3 py-2 rounded mt-3 text-white text-sm cursor-default`}
                          >
                            
                               AVAILABLE CAPITAL: $2M 
                              
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex  justify-center lg:flex lg:flex-col lg:w-32">
                      <div className="relative cursor-pointer group mx-2 lg:mx-0">
                        <div className="">
                          <button className="bg-[#ffc107] px-2 py-2 rounded mt-3 mr-2 lg:m-0 text-white text-sm hover:bg-[#edb205] w-full">
                            <div className="flex justify-between items-center">
                              <AiOutlineMail />
                              <div className="text-sm">Send Message</div>
                            </div>
                          </button>
                        </div>
                        <span className="absolute w-auto p-2 m-2 min-w-max bottom-[-6px] right-[130px] rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-right group-hover:scale-100">
                          {text}
                        </span>
                      </div>

                      <button className="flex justify-between items-center bg-[#007bff] px-2 py-2 rounded mt-3 text-white text-sm hover:bg-[#0069d9]">
                        <BsTelephoneFill />
                        <div>Login to View</div>
                      </button>
                    </div>
                  </div>

                  {/* paragraph about the company */}
                  <div>
                    <div className="h-5 border-b-2 border-[#b4b4b4] text-center text-2xl mb-1"></div>

                    We look for technology-based companies (software, data/analytics, medical devices, bio-tech, life sciences, photonics, ag-tech, clean-tech, and energy) in Montana, Northern Rockies, and the Northwest region at the “Seed” and “A” stages. We're a bit flexible on geography and will occasionally do a"Pre-Seed" or a "B" round, but we're pretty disciplined about sticking to our preferred tech sectors


                 

                    <div className="mt-1 border-b-2 border-[#b4b4b4] text-center text-2xl "></div>

                    <div className="lg:flex lg:justify-between lg:items-center mt-2">
                      {/* Type */}
                      <div className="flex justify-center">
                        <div className="flex justify-start mr-4">
                          <div className="font-medium uppercase mr-2">Type:</div>

                          <div className="flex justify-start items-center cursor-pointer inline-flex relative w-fit group">
                            <div>Network</div>
                            <div className="absolute w-auto top-6 left-0 p-2 min-w-max rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-top group-hover:scale-100">
                              <div className="flex justify-start items-center mb-1">
                                <img src={checkWhite} alt="" className="w-4 mr-1" />
                                <h6>Private Investor</h6>
                              </div>
                           
                            </div>
                            <BsArrowBarDown className="ml-1" />
                          </div>
                        </div>

                        {/* Industry */}
                        <div className="flex justify-start ml-3">
                          <div className="font-medium uppercase mr-2">Industry:</div>

                          <div className=" justify-start items-center cursor-pointer inline-flex relative w-fit group">
                            <div>Category</div>
                            <div className="absolute w-auto top-6 left-0 p-2 min-w-max rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-top group-hover:scale-100">
                              <div className="flex justify-start items-center mb-1">
                                <img src={checkWhite} alt="" className="w-4 mr-1" />
                                <h6>Medical Devices</h6>
                              </div>
                            </div>
                            <BsArrowBarDown className="ml-1" />
                          </div>
                        </div>
                      </div>
                    
                        <div className="flex justify-center items-center bg-[#05CC03] text-white text-center my-3  py-3 font-semibold lg:bg-[#fff0] lg:text-[#05CC03] lg:text-xl lg:semi-bold lg:my-0 lg:py-0 lg:text-left lg:mt-0 mt-3">
                          {/* <div className="flex justify-center items-center bg-[#0C0] text-white text-center my-3  py-3 font-semibold lg:bg-[#fff0] lg:text-[#0C0] lg:text-xl lg:semi-bold lg:my-0 lg:py-0 lg:text-left lg:mt-0 mt-3"> */}
                          <div className="text-white lg:text-[#05CC03] pr-1 text-2xl">
                            <AiOutlineCheckCircle />
                          </div>
                          ID Verified
                        </div>
                      
                      
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap justify-center pb-3 lg:justify-start lg:mt-2 px-3">
                <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                  <AiTwotoneTag />
                  <p className="ml-1">network</p>
                </button>
                <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                  <AiTwotoneTag />
                  <p className="ml-1">Software</p>
                </button>
                <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                  <AiTwotoneTag />
                  <p className="ml-1">Medical Devices</p>
                </button>
                <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                  <AiTwotoneTag />
                  <p className="ml-1">Bio-Tech</p>
                </button>
                <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                  <AiTwotoneTag />
                  <p className="ml-1">Life Sciences</p>
                </button>
                <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                  <AiTwotoneTag />
                  <p className="ml-1">Photonics</p>
                </button>
                <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                  <AiTwotoneTag />
                  <p className="ml-1">Ag-Tech</p>
                </button> 
                 <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                  <AiTwotoneTag />
                  <p className="ml-1">Data/Analytics</p>
                </button>
           
          
              </div>
            </div>
          </div>
    </div>
  );
};

export default HomepageUsers;
