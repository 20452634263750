import React, { useState, useEffect } from 'react';

import { AiOutlineFieldTime } from 'react-icons/ai';

import { MdOutlineVerified } from 'react-icons/md';
import { BiWallet } from 'react-icons/bi';
import axios from 'axios';

const Verify = () => {
  const [company, setCompany] = useState('');
  const [country, setCountry] = useState('');
  const [code, setCode] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [imagestatus, setimageStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [dataApproved, setDataApproved] = useState({});
  // TODO: newly added
  // const [previewSource, setPreviewSource] = useState('');
  // const [image,setImage] = useState('')
  const [base64Image, setBase64Image] = useState('');
  const token = localStorage.getItem('token');
  const [dataAwaitingApproval, setdataAwaitingApproval] = useState({});
  const [uploadBasicDataStatus, setuploadBasicDataStatus] = useState('');

  useEffect(() => {
    const statusChange = async () => {
      try {
        setLoading(true);
        const response = await axios.get('https://fargo-server.herokuapp.com/verification/status', {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log('response.data', response.data);
        if (response.data.status === 'pending' || response.data.status === 'rejected') {
          setdataAwaitingApproval(response.data);
          setLoading(false);
          return;
        }
        setDataApproved(response.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    statusChange();
  }, []);
  // text

  const handleOnImageFileChange = (e) => {
    let file = e.target.files[0];
    console.log('file', file);

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setBase64Image(reader.result);
      // setPreviewSource(reader.result);
    };
    console.log('base64', base64Image);
  };

  const submitDetails = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'https://fargo-server.herokuapp.com/verification',
        { company, country, code, city, address, phone },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log('Details submitted', response.data);
      setdataAwaitingApproval(response.data);
      setuploadBasicDataStatus(response.status);
      // setbasicDataStatus(response.status);
      console.log('dataAwaitingApproval', dataAwaitingApproval);
    } catch (error) {
      console.log(error);
    }
  };

  const uploadImage = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'https://fargo-server.herokuapp.com/verification/image',
        { image: base64Image },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log('image submitted', response.data);
      setBase64Image('');
      setimageStatus(response.status);
      setdataAwaitingApproval(response.data);
      if (response.status === 200 && response.data) {
        setuploadBasicDataStatus('');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="w-full">
        <div className=" lg:mr-0 lg:ml-4  ">
          {/* FIXME:
           menu navigation  */}

          <div className=" flex justify-between mb-3">
            <div className="text-2xl ">Verification</div>
            <div className="flex justify-center">
              <div className="">
                <button className="flex justify-center items-center border rounded py-1 px-2 hover:bg-[#565656] hover:text-white">
                  <MdOutlineVerified className="mr-1" /> <p className="text-sm">Verification</p>
                </button>
              </div>
            </div>
          </div>
          <div className="border p-4 ">
            <h5 className="mx-5 mt-2 font-semibold">Standard verification</h5>
            <p className="mx-5">This status is available after the standard verification process</p>

            {loading ? (
              <div>
                {/* <div class="flex">
                            <div class="relative">
 
                                <div class="w-12 h-12 rounded-full absolute
                            border-8 border-solid border-gray-200"></div>
 
 
                                <div class="w-12 h-12 rounded-full animate-spin absolute
                            border-8 border-solid border-purple-500 border-t-transparent shadow-md"></div>
                            </div>
                        </div>
 */}

                <div class="my-32   flex flex-col items-center justify-center">
                  <div class="absolute w-12 h-12 rounded-full border-8 border-solid border-gray-200"></div>
                  <div class="w-12 h-12 rounded-full animate-spin border-8 border-solid border-purple-500 border-t-transparent shadow-md"></div>
                </div>
              </div>
            ) : (
              <form action="">
                <div className="my-4 mx-5 md:flex">
                  <div className="flex flex-col w-full mr-10">
                    <label htmlFor="" className="pb-2">
                      Company Name
                    </label>
                    <input
                      disabled={dataAwaitingApproval.company ? true : false}
                      type="text"
                      placeholder="Company"
                      value={dataAwaitingApproval.company ? dataAwaitingApproval.company : company}
                      onChange={(event) => setCompany(event.target.value)}
                      className={
                        dataAwaitingApproval.company
                          ? 'bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'
                          : 'bg-white appearance-none border-2 border-gray-200 rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'
                      }
                    />
                  </div>
                  <div className="flex flex-col  w-full">
                    <label htmlFor="" className="pb-2">
                      Country<span>*</span>
                    </label>
                    <input
                      disabled={dataAwaitingApproval.country ? true : false}
                      type="text"
                      placeholder="Country"
                      value={dataAwaitingApproval.country ? dataAwaitingApproval.country : country}
                      onChange={(event) => setCountry(event.target.value)}
                      className={
                        dataAwaitingApproval.country
                          ? 'bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'
                          : 'bg-white appearance-none border-2 border-gray-200 rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'
                      }
                    />
                  </div>
                </div>
                <div className="my-4 mx-5 md:flex">
                  <div className="flex flex-col w-full mr-10">
                    <label htmlFor="" className="pb-2">
                      ZIP code / POST code
                    </label>
                    <input
                      disabled={dataAwaitingApproval.code ? true : false}
                      type="text"
                      placeholder="Zip Code"
                      value={dataAwaitingApproval.code ? dataAwaitingApproval.code : code}
                      onChange={(event) => setCode(event.target.value)}
                      className={
                        dataAwaitingApproval.code
                          ? 'bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'
                          : 'bg-white appearance-none border-2 border-gray-200 rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'
                      }
                    />
                  </div>
                  <div className="flex flex-col  w-full">
                    <label htmlFor="" className="pb-2">
                      City<span>*</span>
                    </label>
                    <input
                      disabled={dataAwaitingApproval.city ? true : false}
                      type="text"
                      placeholder="City"
                      value={dataAwaitingApproval.city ? dataAwaitingApproval.city : city}
                      onChange={(event) => setCity(event.target.value)}
                      className={
                        dataAwaitingApproval.city
                          ? 'bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'
                          : 'bg-white appearance-none border-2 border-gray-200 rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'
                      }
                    />
                  </div>
                </div>
                <div className="my-4 mx-5 md:flex">
                  <div className="flex flex-col w-full mr-10">
                    <label htmlFor="" className="pb-2">
                      Address Line 1<span>*</span>
                    </label>
                    <input
                      disabled={dataAwaitingApproval.address ? true : false}
                      type="text"
                      placeholder="Address"
                      value={dataAwaitingApproval.address ? dataAwaitingApproval.address : address}
                      onChange={(event) => setAddress(event.target.value)}
                      className={
                        dataAwaitingApproval.address
                          ? 'bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'
                          : 'bg-white appearance-none border-2 border-gray-200 rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'
                      }
                    />
                  </div>
                  <div className="flex flex-col  w-full">
                    <label htmlFor="" className="pb-2">
                      Phone<span>*</span>
                    </label>
                    <input
                      disabled={dataAwaitingApproval.phone ? true : false}
                      type="text"
                      placeholder="Address"
                      value={dataAwaitingApproval.phone ? dataAwaitingApproval.phone : phone}
                      onChange={(event) => setPhone(event.target.value)}
                      className={
                        dataAwaitingApproval.phone
                          ? 'bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'
                          : 'bg-white appearance-none border-2 border-gray-200 rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'
                      }
                    />
                  </div>
                </div>
                <p className="text-sm text-[#828282] mx-4">
                  You confirm that the entered data is correct and true. Your account can be locked
                  if you entered incorrect information
                </p>
                {!dataAwaitingApproval?.company &&
                  !dataAwaitingApproval?.country &&
                  !dataAwaitingApproval?.code &&
                  !dataAwaitingApproval?.city &&
                  !dataAwaitingApproval?.address &&
                  !dataAwaitingApproval?.phone && (
                    <div className="text-right">
                      <button
                        className="bg-[#28a745] text-white px-3 py-1.5 rounded hover:bg-[#28a745] text-sm mt-2"
                        onClick={(e) => submitDetails(e)}
                      >
                        Submit
                      </button>
                    </div>
                  )}
              </form>
            )}
          </div>
          {/* extended verification */}

          <div>
            {uploadBasicDataStatus === 200 && (
              // ||
              // (Object.keys(dataAwaitingApproval).length &&
              //   !dataAwaitingApproval.image.secureUrl &&
              //   !Object.keys(dataApproved).length &&
              //   !Object.keys(dataAwaitingApproval).length))
              <form>
                <div className="border p-4 mt-10 ">
                  <h5 className="mx-5 mt-2 font-semibold">Extended verification</h5>
                  <p className="mx-5">
                    This status is available after the standard verification process
                  </p>

                  <div className="text-center border-dashed border-2 border-[#b7b7b7] py-5 rounded md:m-4">
                    <p>Choose Verification Image or Drag and Drop it here</p>
                    {/* start here */}
                    <div className="w-full flex align-center justify-center">
                      <div className="mt-3 w-full text-center">
                        <label
                          htmlFor="upload-photo"
                          className="cursor-pointer px-4 py-2 text-white bg-[#4335dc] hover:bg-[#3228a2] rounded-md  w-full text-sm"
                        >
                          Upload
                        </label>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          name="profilefile"
                          id="upload-photo"
                          onChange={(e) => handleOnImageFileChange(e)}
                          className="border-2 opacity-0 absolute z-0"
                        />
                      </div>
                    </div>
                    <div>
                      {base64Image && (
                        <img
                          src={base64Image}
                          alt="chosen"
                          className="mx-auto mt-5"
                          style={{ height: '300px' }}
                        />
                      )}
                    </div>

                    {/* end here */}
                  </div>
                  <div className="text-right">
                    <button
                      className="bg-[#28a745] text-white px-3 py-1.5 rounded hover:bg-[#28a745] text-sm mt-2"
                      onClick={(e) => uploadImage(e)}
                    >
                      Verify
                    </button>
                  </div>
                </div>
              </form>
            )}

            {Object.keys(dataApproved).length && (
              <div className="border p-4 mt-10 ">
                <div className="text-center border-dashed border-2 border-[#b7b7b7] py-5 rounded md:m-4">
                  <div></div>
                  <AiOutlineFieldTime size={52} className="text-[#ffc107] mx-auto mb-4" />
                  <p>Approved by admin</p>
                  {/* start here */}

                  {/* end here */}
                </div>
              </div>
            )}

            {Object.keys(dataAwaitingApproval).length && dataAwaitingApproval.image.secureUrl && (
              <div className="border p-4 mt-10 ">
                <h5 className="mx-5 mt-2 font-semibold">Extended verification</h5>
                <div className="text-center border-dashed border-2 border-[#b7b7b7] py-5 rounded md:m-4">
                  <div></div>
                  <AiOutlineFieldTime size={52} className="text-[#ffc107] mx-auto mb-4" />
                  <p>Please wait 24-48 hours for Admin to verify documents</p>
                  {/* start here */}

                  {/* end here */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Verify;
