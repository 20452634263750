import axios from 'axios';
import { useState, useEffect } from 'react';
import EachTicket from './EachTicket';

const AllTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isSupportOpen, setIsSupportOpen] = useState(false);
  const token = localStorage.getItem('token');

  useEffect(() => {
    const getTickets = async () => {
      try {
        // right when the app renders, useEffect will call this function. get all the tickets, and pass the headers token to verify it's the correct user
        const response = await axios.get('https://fargo-server.herokuapp.com/tickets', {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log('response.data.allTickets', response.data.myTickets);
        // the value of tickets will be what I pass it setTickets
        setTickets(response.data.myTickets);
      } catch (error) {
        console.log(error);
      }
    };
    getTickets();
  }, []);

  const ticketSubmit = async (e) => {
    e.preventDefault();

    try {
      // this creates a new ticket. and the information we're going to pass into it will be the subject and message. the other info is automatically passed
      const response = await axios.post(
        'https://fargo-server.herokuapp.com/ticket',
        { subject, message },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response.data);
      // tickets will have the old tickets plus the new ones
      setTickets([...tickets, response.data]);
      // make the get functions an empty string so after i click submit, the values are empty again
      setMessage('');
      setSubject('');
      setIsSupportOpen(false);
      // setTickets("")
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className=" lg:w-full">
        <div className="flex justify-between items-start">
          <h4 className="text-2xl">All Tickets</h4>
          <button
            onClick={() => setIsSupportOpen((prev) => !prev)}
            className={
              isSupportOpen
                ? 'bg-[#28a776] hover:bg-[#228e64] px-3 py-2 rounded mt-0 text-white text-xs transition duration-300 ease-in-out  hover:shadow-sm'
                : 'bg-[#28a745] hover:bg-[#218a62] px-3 py-2 rounded mt-0 text-white text-xs transition duration-300 ease-in-out  hover:shadow-sm'
            }
          >
            <div className="">{isSupportOpen ? 'Close' : 'New Ticket'}</div>
          </button>
        </div>

        {/* Form to submit ticket */}

        <div className="border-2 rounded my-4">
          <div className={isSupportOpen ? 'visible' : 'hidden'}>
            <form>
              <div className="m-1">
                <input
                  type="text"
                  name="Subject"
                  value={subject}
                  onChange={(event) => setSubject(event.target.value)}
                  placeholder="Subject"
                  className=" text-gray-base w-full mr-3 py-5 px-2 h-2 border border-gray-primary rounded"
                />
              </div>
              <div className="m-1">
                <textarea
                  name="ticket"
                  cols="30"
                  rows="10"
                  value={message}
                  onChange={(event) => setMessage(event.target.value)}
                  placeholder="Message"
                  className=" text-gray-base w-full mr-3 py-2 px-2 h-40 border border-gray-primary rounded "
                ></textarea>
              </div>
              <div className="text-right m-1">
                <input
                  type="submit"
                  value="Submit"
                  className="bg-[#19A1B8] px-3 py-2 rounded cursor-pointer text-white text-sm transition duration-300 ease-in-out  hover:shadow-sm hover:bg-[#13798b]"
                  onClick={(e) => {
                    ticketSubmit(e);
                  }}
                />
              </div>
            </form>
          </div>
        </div>
        <EachTicket tickets={tickets} setTickets={setTickets} />
      </div>
    </>
  );
};

export default AllTickets;

// isAdmin in the model for user
// add isAdmin in the user controller, and then remove them form the req.body (once you created the admin)
// backend - When user creates a ticket, it goes to admin

// reply back to user about that ticket
