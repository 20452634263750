// Import necessary dependencies
import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import axios, { AxiosError } from 'axios';

import { MdOutlineVerified } from 'react-icons/md';
import { BiWallet } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import userPic from '../../components/Cards/icons/placeholderUser.png';
import { AiTwotoneBulb, AiOutlineInfoCircle, AiOutlineCheckCircle } from 'react-icons/ai';

// Create the JSX component
const SettingsDemo = () => {
  const [user, setUser] = useState({});
  const [updatedData, setUpdatedData] = useState({});
  const [base64Image, setBase64Image] = useState([]);
  const token = localStorage.getItem('token');
  const [expandedImage, setExpandedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imagesInDb, setImagesInDb] = useState([]);
  const [videoUrl, setVideoUrl] = useState();
  const [videoStatus, setVideoStatus] = useState();
  const [videoUrlInDb, setVideoUrlInDb] = useState();
  //TODO: added a profilePic state
  const [profilePic, setProfilePic] = useState();
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get('https://fargo-server.herokuapp.com/profile', {
          headers: { Authorization: `Bearer ${token}` },
        });

        setUser(response.data.usersInfo);
        setImagesInDb(response.data.usersInfo.imagesMedia);
        setVideoUrl(response.data.usersInfo.videosMedia);
        setVideoUrlInDb(response.data.usersInfo.videosMedia);
        setVideoStatus(response.status);
      } catch (error) {
        console.log(error);
      }
    };

    const getMyUpdatedData = async () => {
      try {
        const response = await axios.get('https://fargo-server.herokuapp.com/userUpdates', {
          headers: { Authorization: `Bearer ${token}` },
        });
        {console.log('user updates',updatedData)}

        setUpdatedData(response.data);
      } catch (error) {
        console.log(error);
        return error;
      }
    };
    setLoading(true);
    getMyUpdatedData();
    getUser();
    setLoading(false);
  }, []);

  const initialValues = {
    fullName: updatedData?.fullName || user.fullName,
    email: updatedData?.email || user.email,
    mobileNumber: updatedData?.mobileNumber || user.mobileNumber,
  };
  const initialValuesNetwork = {
    network: updatedData?.network || user?.network,
    industry: updatedData?.industry || user?.industry,
    amount: updatedData?.amount || user?.amount,
    category: updatedData?.category || user?.category,
  };
  const initialValuesAd = {
    profileAd: updatedData?.profileAd || user.profileAd,
    profileDescription: updatedData?.profileDescription || user.profileDescription,
  };
  const submitHandler = async (values) => {
    const body = {};
    if (values.fullName !== user?.fullName && values.fullName !== updatedData?.fullName) {
      body.fullName = values.fullName;
    }
    if (
      values.mobileNumber !== user?.mobileNumber &&
      values.mobileNumber !== updatedData.mobileNumber
    ) {
      body.mobileNumber = values.mobileNumber;
    }
    if (values.email !== user?.email && values.email !== updatedData.email) {
      body.email = values.email;
    }
    try {
      const response = await axios.post('https://fargo-server.herokuapp.com/userUpdates', body, {
        headers: { Authorization: `Bearer ${token}` },
      });
      response.data && setUpdatedData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const submitHandlerNetwork = async (values) => {
    try {
      const response = await axios.post('https://fargo-server.herokuapp.com/userUpdates', values, {
        headers: { Authorization: `Bearer ${token}` },
      });
      response.data && setUpdatedData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const submitHandlerAd = async (values) => {
    const body = {};
    if (values.profileAd !== user?.profileAd && values.profileAd !== updatedData?.profileAd) {
      body.profileAd = values.profileAd;
    }
    if (
      values.profileDescription !== user?.profileDescription &&
      values.profileDescription !== updatedData?.profileDescription
    ) {
      body.profileDescription = values.profileDescription;
    }

    try {
      const response = await axios.post('https://fargo-server.herokuapp.com/userUpdates', body, {
        headers: { Authorization: `Bearer ${token}` },
      });
      response.data && setUpdatedData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnImagesFileChange = (e) => {
    console.log('first');
    let file = e.target.files[0];
    console.log('file', file);

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setBase64Image((prevState) => [...prevState, reader.result]);
      // setPreviewSource(reader.result);
    };
    console.log('base64', base64Image);
  };

  const saveImages = async () => {
    if (!base64Image.length) return;
    try {
      const response = await axios.post(
        'https://fargo-server.herokuapp.com/media',
        { media: base64Image, type: 'image' },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (Array.isArray(response.data)) {
        setImagesInDb(response.data);
        setBase64Image([]);
      }
      console.log('response', response);
    } catch (error) {
      console.log(error);
    }
  };

  const saveVideoUrl = async (e) => {
    e.preventDefault();
    if (!videoUrl) return;
    try {
      const response = await axios.post(
        'https://fargo-server.herokuapp.com/media',
        { media: videoUrl, type: 'video' },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status !== 200) {
        setVideoUrl('');
        return;
      }
      if (response.status === 200) {
        setVideoUrlInDb(response.data);
      }

      setVideoStatus(response.status);
      console.log('response', response);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteMedia = async (media, videoUrlOrImagePubliId, e) => {
    e.preventDefault();
    try {
      let response;
      if (media === 'image') {
        response = await axios.post(
          'https://fargo-server.herokuapp.com/media-image',
          { public_id: videoUrlOrImagePubliId },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if (response.status === 200) {
          setImagesInDb(response.data);
          return;
        }
      } else if (media === 'video') {
        response = await axios.delete('https://fargo-server.herokuapp.com/media-video', {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200) {
          setVideoUrl('');
          setVideoUrlInDb('');
          return;
        }
        return;
      }
    } catch (error) {
      console.log(`error deleting ${media}`, error);
    }
  };

  //TODO: added a profile pic onchange function to set the profile pic state to the current choosen image
  const handleOnProfilePicChange = (e) => {
    e.preventDefault();
    const image = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = () => {
      setProfilePic(reader.result);
    };
  };

  // TODO: added a function to handle uploading a new profile pic for approval;
  const handleProfilePicUpload = async (e, originalImage) => {
    e.preventDefault();
    if (!profilePic) return;

    try {
      const response = await axios.post(
        'https://fargo-server.herokuapp.com/userUpdates',
        {
          profileImage: profilePic,
          originalImage: originalImage ? originalImage : { publicId: '', secureUrl: '' },
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      response.data && setUpdatedData(response.data);
      setProfilePic('');
    } catch (error) {
      console.log('error uploading profile pic', error);
    }
  };
  const handleImageClick = (image) => {
    setExpandedImage(image);
  };

  const handleImageClose = () => {
    setExpandedImage(null);
  };
  // form 2
  const networkOptions = [
    { value: 'Startups & Funding Needed', label: 'Startups & Funding Needed' },
    { value: 'Business & Private Investors', label: 'Business & Private Investors' },
    { value: 'Incubators', label: 'Incubators' },
    { value: 'Accelerators', label: 'Accelerators' },
  ];

  const categoryOptions = [
    { value: 'Thousand', label: 'Thousand' },
    { value: 'Million', label: 'Million' },
  ];

  const industryOptions = [
    { value: 'Aerospace', label: 'Aerospace' },
    { value: 'Agriculture', label: 'Agriculture' },
    { value: 'Biotechnology', label: 'Biotechnology' },
    { value: 'Business Products', label: 'Business Products' },
    { value: 'Business Services', label: 'Business Services' },
    { value: 'Chemicals and Chemical Products', label: 'Chemicals and Chemical Products' },
    { value: 'Clean Technology', label: 'Clean Technology' },
    { value: 'Computers and Peripheral', label: 'Computers and Peripherals' },
    { value: 'Construction', label: 'Construction' },
    { value: 'Consulting', label: 'Consulting' },
    { value: 'Consumer Products', label: 'Consumer Products' },
    { value: 'Consumer Services', label: 'Consumer Services' },
    { value: 'Digital Marketing', label: 'Digital Marketing' },
    { value: 'Education', label: 'Education' },
    { value: 'Electronics / Instrumentation', label: 'Electronics / Instrumentation' },
    { value: 'Fashion', label: 'Fashion' },
    { value: 'Financial Services', label: 'Financial Services' },
    { value: 'Fintech', label: 'Fintech' },
    { value: 'Food and Beverage', label: 'Food and Beverage' },
    { value: 'Gaming', label: 'Gaming' },
    { value: 'Healthcare Services', label: 'Healthcare Services' },
    { value: 'Industrial/Energy', label: 'Industrial/Energy' },
    { value: 'Internet / Web Services', label: 'Internet / Web Services' },
    { value: 'IT Services', label: 'IT Services' },
    { value: 'Legal', label: 'Legal' },
    { value: 'Lifestyle', label: 'Lifestyle' },
    { value: 'Marine', label: 'Marine' },
    { value: 'Maritime/Shipping', label: 'Maritime/Shipping' },
    { value: 'Media', label: 'Media' },
    { value: 'Medical Devices', label: 'Medical Devices' },
    { value: 'Mining', label: 'Mining' },
    { value: 'Mobile', label: 'Mobile' },
    { value: 'Non-Profit', label: 'Non-Profit' },
    { value: 'Oil and Gas', label: 'Oil and Gas' },
    { value: 'Pharmaceuticals', label: 'Pharmaceuticals' },
    { value: 'Real Estate', label: 'Real Estate' },
    { value: 'Retail', label: 'Retail' },
    { value: 'Security', label: 'Security' },
    { value: 'Software', label: 'Software' },
    { value: 'Sports', label: 'Sports' },
    { value: 'Telecommunications', label: 'Telecommunications' },
    { value: 'Transportation', label: 'Transportation' },
    { value: 'Travel', label: 'Travel' },
    { value: 'Utilities', label: 'Utilities' },
    { value: 'Venture Capital', label: 'Venture Capital' },
    { value: 'Other', label: 'Other' },
  ];

  return (
    <>
      <div className="w-full">
        <div className=" lg:mr-0 lg:ml-4">
          <div className="flex justify-left items-center bg-[#7867a7] text-white font-normal text-lg py-2 pl-5 mb-5  rounded">
            <AiTwotoneBulb className="mr-3 text-3xl" /> Account Settings
          </div>
          <div className=" flex justify-between mb-3">
            <div className="text-3xl ">Profile</div>
            <div className="flex justify-center">
              <Link to="/idverify">
                <button className="flex justify-center items-center border rounded py-1 px-2 hover:bg-[#565656] hover:text-white">
                  <MdOutlineVerified className="mr-1" /> <p className="text-sm">Verification</p>
                </button>
              </Link>
            </div>
          </div>
          <div className="border p-1 md:p-4 ">
            <div className="flex justify-start bg-[#CDE4FE] text-3xl text-[#014084] py-3 px-5 m-1 rounded">
              <AiOutlineInfoCircle className="mr-1" />
              <h3>Basic Info</h3>
            </div>

            <Formik initialValues={initialValues} enableReinitialize onSubmit={submitHandler}>
              {({ values, dirty, handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="my-4 px-1">
                    <div className="flex justify-between w-full">
                      <div className="w-full">
                        <label htmlFor="fullName" className="pb-2 flex justify-start items-center">
                          <p> Full Name: </p>
                          <span
                            className={`${
                              updatedData?.fullName ? 'visible text-yellow-500' : 'hidden'
                            } text-xs font-semibold relative flex h-2 w-2 ml-2 `}
                          >
                            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75"></span>
                            <span class="relative inline-flex rounded-full h-2 w-2 bg-yellow-500"></span>
                          </span>

                          <span
                            className={`${
                              updatedData?.fullName
                                ? 'visible text-yellow-500 text-xs font-semibold ml-1'
                                : 'hidden'
                            }`}
                          >
                            Waiting Admin Approval
                          </span>
                        </label>
                        <Field
                          id="fullName"
                          value={values.fullName}
                          onChange={handleChange}
                          name="fullName"
                          placeholder="John Doe"
                          className={`${
                            updatedData?.fullName ? 'border-yellow-500' : 'border-gray-300'
                          } bg-gray-200 appearance-none border-2  rounded py-2 px-2 w-full text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" `}
                        />
                      </div>
                      <div className="w-20"></div>
                      <div className="w-full">
                        <label htmlFor="email" className="pb-2 flex justify-start items-center">
                          {' '}
                          <p>Email: </p>
                          <span
                            className={`${
                              updatedData?.email ? 'visible text-yellow-500' : 'hidden'
                            } text-xs font-semibold relative flex h-2 w-2 ml-2 `}>

                            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75"></span>
                            <span class="relative inline-flex rounded-full h-2 w-2 bg-yellow-500"></span>
                          </span>
                          <span
                            className={`${
                              updatedData?.email
                                ? 'visible text-yellow-500 text-xs font-semibold ml-1'
                                : 'hidden'
                            }`}>
                            Waiting Admin Approval
                          </span>
                        </label>
                        <Field
                          id="email"
                          value={values.email}
                          onChange={handleChange}
                          name="email"
                          placeholder="john.doe@example.com"
                          className={`${
                            updatedData?.email ? 'border-yellow-500' : 'border-gray-300'
                          } bg-gray-200 appearance-none border-2  rounded py-2 px-2 w-full text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" `}
                        />
                      </div>
                    </div>

                    <div className="flex justify-start w-full mt-3">
                      <div className="w-full">
                        <label
                          htmlFor="mobileNumber"
                          className="pb-2 flex justify-start items-center"
                        >
                          <p> Mobile Number: </p>
                          <span
                            className={`${
                              updatedData?.mobileNumber ? 'visible text-yellow-500' : 'hidden'
                            } text-xs font-semibold relative flex h-2 w-2 ml-2 `}
                          >
                            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75"></span>
                            <span
                              className={`${
                                updatedData?.mobileNumber
                                  ? 'visible text-yellow-500 text-xs font-semibold ml-1'
                                  : 'hidden'
                              }`}
                            ></span>
                          </span>

                          <span
                            className={`${
                              updatedData?.mobileNumber
                                ? 'visible text-yellow-500 text-xs font-semibold ml-1'
                                : 'hidden'
                            }`}
                          >
                            Waiting Admin Approval
                          </span>
                        </label>
                        <Field
                          id="mobileNumber"
                          value={values.mobileNumber}
                          onChange={handleChange}
                          name="mobileNumber"
                          placeholder="123-456-7890"
                          className={`${
                            updatedData?.mobileNumber ? 'border-yellow-500' : 'border-gray-300'
                          } bg-gray-200 appearance-none border-2  rounded py-2 px-2 w-full text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" `}
                        />
                      </div>
                   
                    </div>

                    {dirty && (
                      <div className="mb-0 text-right">
                        <button
                          className="bg-[#28a745] text-sm px-3 py-2 rounded mt-3 text-white"
                          type="submit"
                        >
                          Save Changes
                        </button>
                      </div>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>

          <div className="border p-1 md:p-4 ">
            <div className="flex justify-start bg-[#4CB04F] text-3xl text-[#fff] py-3 px-5 m-1 rounded">
              <AiOutlineInfoCircle className="mr-1" />
              <h3>Industry Preferences</h3>
            </div>
            <div>
              <Formik
                initialValues={initialValuesNetwork}
                enableReinitialize
                onSubmit={submitHandlerNetwork}
              >
                {({ values, dirty, handleChange, handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="flex justify-start w-full mb-3">
                      <div className="w-full">
                        <label htmlFor="network" className="pb-2 flex justify-start items-center">
                          <p> Network: </p>

                          <span
                            className={`${
                              updatedData?.network ? 'visible text-yellow-500' : 'hidden'
                            } text-xs font-semibold relative flex h-2 w-2 ml-2 `}
                          >
                            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75"></span>
                            <span class="relative inline-flex rounded-full h-2 w-2 bg-yellow-500"></span>
                          </span>

                          <span
                            className={`${
                              updatedData?.network
                                ? 'visible text-yellow-500 text-xs font-semibold ml-1'
                                : 'hidden'
                            }`}
                          >
                            Waiting Admin Approval
                          </span>
                        </label>
                        <div className="relative">
                          <Field
                            id="network"
                            name="network"
                            component="select"
                            value={values.network}
                            onChange={handleChange}
                            className={`${
                              updatedData?.network ? 'border-yellow-500' : 'border-gray-200'
                            } bg-gray-200 appearance-none border-2 rounded w-full  py-2 px-2 text-gray-700 focus:outline-none focus:bg-white focus:border-purple-500`}
                          >
                            <option value="">Select a network</option>
                            {networkOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </Field>
                          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg class="h-4 w-4" viewBox="0 0 20 20" fill="none">
                              <path
                                d="M10 14.6L4.6 9.2C4.2 8.8 4.2 8.2 4.6 7.8C5 7.4 5.6 7.4 6 7.8L10 11.8L14 7.8C14.4 7.4 15 7.4 15.4 7.8C15.8 8.2 15.8 8.8 15.4 9.2L10 14.6Z"
                                fill="#4A5568"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="w-20"></div>
                      <div className="flex flex-col w-full justify-end">
                        <div
                          style={{
                            backgroundColor: '#16A2B8',
                          }}
                          className="w-full py-3   text-white  text-xs font-bold flex justify-center items-center "
                        >
                          <AiOutlineCheckCircle className="font-extrabold mr-2" />
                          <p> I'm seeking Business Partners or Investment Capital</p>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div className="w-full">
                        <label htmlFor="industry" className="pb-2 flex justify-start items-center">
                          <p>Industry: </p>
                          <span
                            className={`${
                              updatedData?.industry ? 'visible text-yellow-500' : 'hidden'
                            } text-xs font-semibold relative flex h-2 w-2 ml-2 `}>

                            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75"></span>
                            <span class="relative inline-flex rounded-full h-2 w-2 bg-yellow-500"></span>
                          </span>
                          <span
                            className={`${
                              updatedData?.industry
                                ? 'visible text-yellow-500 text-xs font-semibold ml-1'
                                : 'hidden'
                            }`}
                          >
                            Waiting Admin Approval
                          </span>
                        </label>
                        <div className="relative">
                          <Field
                            id="industry"
                            name="industry"
                            component="select"
                            value={values.industry}
                            onChange={handleChange}
                            className={`${
                              updatedData?.industry ? 'border-yellow-500' : 'border-gray-300'
                            } bg-gray-200 appearance-none border-2  rounded py-2 px-2 w-full text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" `}
                          >
                            <option value="">Select a industry</option>
                            {industryOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </Field>
                          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg class="h-4 w-4" viewBox="0 0 20 20" fill="none">
                              <path
                                d="M10 14.6L4.6 9.2C4.2 8.8 4.2 8.2 4.6 7.8C5 7.4 5.6 7.4 6 7.8L10 11.8L14 7.8C14.4 7.4 15 7.4 15.4 7.8C15.8 8.2 15.8 8.8 15.4 9.2L10 14.6Z"
                                fill="#4A5568"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="w-20"></div>
                      <div className="w-full">
                        <label htmlFor="industry" className="pb-2 flex justify-start items-center">
                          <p>Amount: </p>
                          <span className={`${
                              updatedData?.industry ? 'visible text-yellow-500' : 'hidden'
                            } text-xs font-semibold relative flex h-2 w-2 ml-2 `}>
                            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75"></span>
                            <span class="relative inline-flex rounded-full h-2 w-2 bg-yellow-500"></span>
                          </span>
                          <span className={`${
                              updatedData?.amount
                                ? 'visible text-yellow-500 text-xs font-semibold ml-1'
                                : 'hidden'
                            }`}>
                            Waiting Admin Approval
                          </span>
                        </label>
                        <div className="flex justify-start">
                          <Field
                            id="amount"
                            value={values.amount}
                            onChange={handleChange}
                            name="amount"
                            placeholder="55"
                            className={`${
                              updatedData?.amount ? 'border-yellow-500' : 'border-gray-200'
                            } bg-gray-200 text-center appearance-none border-2 rounded-l w-full  py-2 px-2 text-gray-700 focus:outline-none focus:bg-white focus:border-purple-500`}
                          ></Field>
                          <div className="relative ">
                            <Field
                              id="industry"
                              name="industry"
                              component="select"
                              value={values.category}
                              onChange={handleChange}
                              className={`${
                                updatedData?.category ? 'border-yellow-500' : 'border-gray-200'
                              } bg-gray-200 text-center appearance-none border-2 rounded-r w-60  py-2 px-2 text-gray-700 focus:outline-none focus:bg-white focus:border-purple-500`}
                            >
                              <option value="thousand">Select Amount</option>
                              {categoryOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </Field>
                            <div class="pointer-events-none absolute inset-y-0 right-8 flex items-center px-2 text-gray-700">
                              <svg class="h-4 w-4" viewBox="0 0 20 20" fill="none">
                                <path
                                  d="M10 14.6L4.6 9.2C4.2 8.8 4.2 8.2 4.6 7.8C5 7.4 5.6 7.4 6 7.8L10 11.8L14 7.8C14.4 7.4 15 7.4 15.4 7.8C15.8 8.2 15.8 8.8 15.4 9.2L10 14.6Z"
                                  fill="#4A5568"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {dirty && (
                      <div className="mb-0 text-right">
                        <button
                          className="bg-[#28a745] text-sm px-3 py-2 rounded mt-3 text-white"
                          type="submit"
                        >
                          Save Changes
                        </button>
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div className="border p-1 md:p-4 ">
            {' '}
            <div className="flex justify-start  bg-[#CDE4FE] text-2xl text-[#014084] py-3 px-5 m-1 rounded">
              <AiOutlineInfoCircle className="mr-1" />
              <h3>Financial Network Posts</h3>
            </div>
            <Formik initialValues={initialValuesAd} enableReinitialize onSubmit={submitHandlerAd}>
              {({ values, dirty, handleChange, handleSubmit, setFieldValue }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="flex justify-center align-center flex-col mb-4  w-32   lg:w-36 mx-auto">
                    {/* TODO: changed the image src to show a selected profile pic if any is choose or an updated profile pic or a set profile image or if none of the listed are true, it defaults to the icon of a user who has no profile pic  */}
                    <img
                      src={
                        profilePic
                          ? profilePic
                          : updatedData.profileImage?.secureUrl
                          ? updatedData.profileImage?.secureUrl
                          : user.profileImage?.secureUrl
                          ? user.profileImage.secureUrl
                          : userPic
                      }
                      alt="Profile pick"
                      className="w-36 h-36 border p-2 mx-auto object-cover"
                    />

                    <div className="mt-3 w-full text-center">
                      <label
                        htmlFor="upload-photo"
                        className="cursor-pointer px-4 py-2 text-white  bg-[#17a2b8] hover:bg-[#1c7380] rounded-md  w-full text-sm"
                      >
                        Upload Avatar
                      </label>

                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        name="profileImage"
                        id="upload-photo"
                        // TODO: added an onchange for the profile pic input
                        onChange={(e) => handleOnProfilePicChange(e)}
                        className="hidden"
                      />

                      {/* TODO: added a button for uploading a profile pic */}
                      {/* {profilePic && (
                        <button onClick={(e) => handleProfilePicUpload(e, user.profileImage)}>
                          Upload Avatar
                        </button>
                      )} */}
                    </div>
                  </div>
                  <div className="mx-auto border mb-3 py-3 w-3/4 text-center rounded bg-[#f3f3f3]">
                    <p>
                      Choose an Image that represent you, your product or company brand.
                      <br />
                      Quality images attract 80% more visitors!
                    </p>
                  </div>

                  <div className="flex flex-col w-full mr-10 mb-4">
                    <label htmlFor="" className="pb-2 flex justify-between">
                      <div className="flex justify-start items-center">
                        <p>
                          Ad Headline <span>*</span>
                        </p>
                        <span
                          className={`${
                            updatedData?.profileAd ? 'visible text-yellow-500' : 'hidden'
                          } text-xs font-semibold relative flex h-2 w-2 ml-2`}
                        >
                          <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75"></span>
                          <span class="relative inline-flex rounded-full h-2 w-2 bg-yellow-500"></span>
                        </span>
                        <span
                          className={`${
                            updatedData?.profileAd
                              ? 'visible text-yellow-500 text-xs font-semibold ml-1'
                              : 'hidden'
                          }`}
                        >
                          Waiting Admin Approval
                        </span>
                      </div>

                      <p className="text-sm">
                        Total Word Count: <strong>0</strong> words - Words left: <strong>15</strong>
                      </p>
                    </label>
                    <Field
                      id="profileAd"
                      value={values.profileAd}
                      onChange={handleChange}
                      name="profileAd"
                      placeholder="Ad Headline"
                      className={`${
                        updatedData?.profileAd ? 'border-yellow-500' : 'border-gray-300'
                      } bg-gray-200 appearance-none border-2  rounded py-2 px-2 w-full text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" `}
                    />
                  </div>

                  <div className="flex flex-col w-full mr-10 mb-4">
                    <label htmlFor="" className="pb-2 flex justify-between">
                      <div className="flex justify-start items-center">
                        <p>
                          profile Description <span>*</span>
                        </p>
                        <span
                          className={`${
                            updatedData?.profileDescription ? 'visible text-yellow-500' : 'hidden'
                          } text-xs font-semibold relative flex h-2 w-2 ml-2 `}
                        >
                          <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75"></span>
                          <span class="relative inline-flex rounded-full h-2 w-2 bg-yellow-500"></span>
                        </span>
                        <span
                          className={`${
                            updatedData?.profileDescription
                              ? 'visible text-yellow-500 text-xs font-semibold ml-1'
                              : 'hidden'
                          }`}
                        >
                          Waiting Admin Approval
                        </span>
                      </div>

                      <p className="text-sm">
                        Total Word Count: <strong>0</strong> words - Words left: <strong>15</strong>
                      </p>
                    </label>
                    <Field
                      id="profileDescription"
                      component="textarea"
                      rows="6"
                      values={values.profileDescription}
                      name="profileDescription"
                      onChange={handleChange}
                      placeholder="Ad Description"
                      className={`${
                        updatedData?.profileDescription ? 'border-yellow-500' : 'border-gray-300'
                      } bg-gray-200 appearance-none border-2  rounded py-2 px-2 w-full text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" `}
                    />
                  </div>
                  {/* {profilePic && (
                        <button onClick={(e) => handleProfilePicUpload(e, user.profileImage)}>
                          Upload Avatar
                        </button>
                      )} */}
                  {(dirty || profilePic) && (
                    <div className="mb-0 text-right">
                      <button
                        onClick={(e) => {
                          if (profilePic) handleProfilePicUpload(e, user.profileImage);
                        }}
                        className="bg-[#28a745] text-sm px-3 py-2 rounded mt-3 text-white"
                        type="submit"
                      >
                        Save Changes
                      </button>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
          <div className="border p-1 md:p-4 ">
            <div className="flex justify-start  bg-[#CDE4FE] text-2xl text-[#014084] py-3 px-5 m-1 mb-3 rounded">
              <AiOutlineInfoCircle className="mr-1" />
              <h3>Video Elevator Pitch</h3>
            </div>
            <div>
              <div className="md:m-5">
                <div>
                  <div className="text-center border-dashed border-2 border-[#b7b7b7] py-5 rounded">
                    <p>Paste in a YouTube Link</p>
                    <form className="bg-white rounded px-8 pt-6 pb-8 mb-4">
                      <input
                        type="text"
                        required
                        value={videoUrl}
                        onChange={(e) => {
                          console.log('reaching', videoUrl);
                          if (videoUrl) {
                            setVideoUrl('');
                            return;
                          }
                          if (e.target.value.includes('https://www.youtube.com/watch?v=')) {
                            setVideoUrl(
                              `https://www.youtube.com/embed/${e.target.value.slice(
                                e.target.value.indexOf('=') + 1
                              )}`
                            );
                            return;
                          }
                          if (e.target.value.includes('https://www.youtube.com/embed/')) {
                            setVideoUrl(e.target.value);
                            return;
                          }
                          if (e.target.value.slice(17)) {
                            setVideoUrl(
                              `https://www.youtube.com/embed/${e.target.value.slice(17)}`
                            );
                          }
                          return;
                        }}
                        placeholder="Youtube Link"
                        name="link"
                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500 mb-1"
                        // className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                      <button
                        className="bg-[#4335dc] px-3 py-2 rounded mt-3 text-white text-sm"
                        type="submit"
                        onClick={(e) => {
                          saveVideoUrl(e);
                        }}
                      >
                        Upload Video
                      </button>
                    </form>
                  </div>
                  {videoUrlInDb && (
                    <iframe
                      title="pitch"
                      className="mx-auto w-full h-96 my-6"
                      src={videoUrlInDb}
                    ></iframe>
                  )}
                  {videoUrlInDb && videoStatus === 200 && (
                    <div className="text-right">
                      <button
                        className="bg-[#dc3535] px-3 py-2 rounded text-white text-sm "
                        onClick={(e) => deleteMedia('video', '', e)}
                      >
                        Delete Video
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className="flex justify-start bg-[#CDE4FE] text-2xl text-[#014084] py-3 px-5 m-1 mt-5 mb-3 rounded">
                  <AiOutlineInfoCircle className="mr-1" />
                  <h3>Upload Images</h3>
                </div>
                <div className="text-center border-dashed border-2 border-[#b7b7b7] py-5 rounded md:m-4">
                  <p>Choose Image or Drag and Drop it here</p>
                  {/* start here */}
                  <div className="w-full flex align-center justify-center">
                    <div className="mt-3 w-full text-center">
                      <label
                        htmlFor="upload-photos"
                        className="cursor-pointer px-4 py-2 text-white bg-[#4335dc] hover:bg-[#3228a2] rounded-md  w-full text-sm"
                      >
                        Upload Images
                      </label>
                      <input
                        multiple
                        type="file"
                        accept="image/png, image/jpeg"
                        name="profilefile"
                        id="upload-photos"
                        onChange={(e) => handleOnImagesFileChange(e)}
                        className="hidden"
                      />
                    </div>
                  </div>
                  <div className=" flex flex-wrap justify-start mt-2">
                    {(base64Image.length || imagesInDb.length) &&
                      [...imagesInDb, ...base64Image].map((image) => {
                        return (
                          <div className="inline-flex relative w-40 m-4">
                            {image.secure_url && (
                              <button
                                onClick={(e) => deleteMedia('image', image.public_id, e)}
                                className="absolute top-[-10px] right-[-10px] bottom-auto shadow-2xl rounded-full bg-[#313131] text-white text-xs h-6 w-6 flex justify-center items-center cursor-pointer"
                              >
                                <p>X</p>
                              </button>
                            )}
                            <img
                              src={image.secure_url ? image.secure_url : image}
                              alt="chosen"
                              // className="mx-auto p-2.5"
                              className={`mx-auto shadow-xl cursor-pointer ${
                                expandedImage === image ? 'w-full' : 'w-full h-40 object-cover'
                              }`}
                              onClick={() => handleImageClick(image)}
                            />
                          </div>
                        );
                      })}

                    {expandedImage && (
                      <div
                        className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-black bg-opacity-75"
                        onClick={handleImageClose}
                      >
                        <div className="relative">
                          <button
                            onClick={handleImageClose}
                            className="absolute top-0 right-0 m-4 text-white font-bold text-xl cursor-pointer"
                          >
                            X
                          </button>
                          <img
                            src={
                              expandedImage.secure_url ? expandedImage.secure_url : expandedImage
                            }
                            className="w-11/12 h-11/12 mx-auto p-10"
                            alt=""
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="text-right mr-4">
                  <button
                    class="bg-[#28a745] px-3 py-2 rounded mt-3 mr-2 lg:m-0 text-white text-sm hover:bg-[#208437] transition duration-300 ease-in-out hover:shadow-sm"
                    onClick={saveImages}
                  >
                    Save Images
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </>
  );
};

// Export the component
export default SettingsDemo;

// When the input is deleted, the value will be an empty string, not null.
