import React, { useState } from 'react';
import { BsCheckLg } from 'react-icons/bs';
import shape from './shape.png';
import shapeHover from './shape_hover.png';
import { useStripe, useElements, PaymentElement, CardElement } from '@stripe/react-stripe-js';

const Purchase = () => {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: 'https://fargohub.com/dashboard',
        },
      });

      if (error) {
        console.log(error);
        setLoading(false);
      } else {
        console.log(paymentIntent);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      {/* <div className="flex lg:flex-row md:flex-row gap-x-5 gap-y-2"> */}
      <div className="flex">
        <form onSubmit={handleSubmit} className="w-full">
          <PaymentElement
            className="p-2 border rounded bg-white focus:border-blue-500"
            options={{
              style: {
                base: {
                  fontSize: '16px',
                  color: '#424770',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                },
                invalid: {
                  color: '#9e2146',
                },
              },
            }}
          />
          <button
            type="submit"
            disabled={!stripe || loading}
            className="mt-4 px-4 py-2 font-semibold text-white bg-blue-500 rounded cursor-pointer disabled:opacity-50"
          >
            Pay
          </button>
        </form>

        <div className="w-full md:ml-28 relative cursor-default">
          <div className="border border-[#EAE9E6] rounded-t-xl bg-[#EAE9E6] py-5">
            <div className="text-center">
              <h4 className="text-3xl font-bold uppercase">Gurinder</h4>
              <h5 className="border-b text-lg font-light uppercase py-4">Subscription</h5>
              <div className="mx-auto border-black border-b-2 w-10 mb-5 shadow-lg"></div>
              <h3 className="text-5xl uppercase hover:text-[#FCD129]">$69.00</h3>
              <h5 className="text-lg font-light pt-3 uppercase">69 Days</h5>
            </div>
          </div>
          <div className="absolute w-full">
            <img className="w-full opacity-0 hover:opacity-100" src={shapeHover} alt="" />
            <img className="w-full absolute top-0 left-0 hover:opacity-0" src={shape} alt="" />
          </div>
          <div className="bg-[#FCD129] rounded-b-lg ">
            <div className="h-44 lg:h-32"></div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Receive Messages</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p> Send Messages</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Post Ad to Network</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Free Profile Matching</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Unlock 32,000+ Investor Database</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>View Phone Numbers & URL</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Private Account Manager</p>
              <BsCheckLg />
            </div>
            <div className="text-center py-6 ">
              <button
                className="bg-[#363636] text-white py-2 px-4 rounded-lg"
                // onClick={packageUpdater}
              >
                Buy Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Purchase;
