import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { RxDoubleArrowRight } from 'react-icons/rx';
import axios from 'axios';

const ResetPassword = () => {
  const [queryString] = useState(window.location.search);
  const [email, setEmail] = useState(getParam('email'));
  const [otp] = useState(getParam('token'));
  const [newPassword, setNewPassword] = useState('');
  const [requestStatus, setRequestStatus] = useState();
  const [requestData, setRequestData] = useState('');
  const [otpStatus, setOtpStatus] = useState();
  function getParam(param) {
    const params = new URLSearchParams(window.location.search);
    if (params.get(param)) return params.get(param);
    return '';
  }
  const navigate = useNavigate();
  const requestOtp = async (e) => {
    e.preventDefault();
    try {
      if (!email) return;
      const response = await axios.post('https://fargo-server.herokuapp.com/reset-password', {
        email,
      });
      if (response.status === 200) setOtpStatus(response.status);
      setRequestStatus(response.status);
    } catch (error) {
      console.log('error resetting password', error);
    }
  };

  const resetPassword = async (e) => {
    e.preventDefault();
    try {
      if (!email || !otp || !newPassword) return;
      const response = await axios.post('https://fargo-server.herokuapp.com/update-password', {
        email,
        newPassword,
        otp,
      });
      setRequestStatus(response.status);
      setRequestData(response.data);
      if (response.status === 200) navigate('/login');
    } catch (error) {
      console.log('error resetting password', error);
    }
  };

  return (
    <>
      <div className="h-screen bg-[#f7f9fa] ">
        <div className="bg-[#f7f9fa]">
          <div className=" m-auto w-screen h-[180px] z-10  bg-[url('https://i.imgur.com/6ZyzH0D.png')] drop-shadow-lg relative">
            <h1 className="text-white text-4xl font-semibold absolute top-20 left-40">
              {queryString && otp && email ? 'Welcome back.' : 'Reset Password'}
            </h1>
          </div>

          <div className="container flex mx-auto max-w-screen-md items-center h-auto mt-20 ">
            <div className="flex flex-col w-11/12 md:w-4/5 mx-auto">
              <div className="flex flex-col items-center bg-white p-4 px-16 rounded border border-gray-primary mb-4">
                <h1 className="flex justify-left font-medium w-full text-2xl py-6">
                  Forgot Your Password?
                </h1>
                {/* <form onSubmit={handleClick} method="POST" className="w-full"> */}
                <form className="w-full">
                  <input
                    type="email"
                    disabled={queryString && otp && email ? true : false}
                    onChange={(e) => setEmail(e.target.value)}
                    aria-label="Enter your email address"
                    value={email}
                    placeholder="Email Address"
                    name="email"
                    className="text-sm text-gray-base w-full mr-3 py-5 px-4 h-2 border border-gray-primary rounded"
                  />
                  {queryString && otp && email && (
                    <input
                      type="password"
                      onChange={(e) => setNewPassword(e.target.value)}
                      value={newPassword}
                      placeholder="new password"
                      name="password"
                      className="text-sm text-gray-base w-full mr-3 py-5 px-4 h-2 border border-gray-primary rounded"
                    />
                  )}
                  {otp && (
                    <input
                      disabled={otp ? true : false}
                      type="text"
                      // onChange={(e) => setEmail(e.target.value)}
                      value={otp}
                      placeholder="otp"
                      name="otp"
                      className="text-sm text-gray-base w-full mr-3 py-5 px-4 h-2 border border-gray-primary rounded"
                    />
                  )}

                  {requestStatus !== 200 && requestData && (
                    <p className="text-red-500">hello {requestData}</p>
                  )}
                  <div className="flex justify-end text-sm mt-2">
                    <p className="mr-1">Already have an account?</p>
                    <Link to="/login" className="flex justify-end items-center text-blue-600">
                      <p className="mr-0.5 text-blue-600">Log in</p>
                      <RxDoubleArrowRight />
                    </Link>
                  </div>
                  <button
                    onClick={(e) => {
                      if (!queryString && !otp) requestOtp(e);
                      if (queryString && otp) resetPassword(e);
                    }}
                    type="submit"
                    className="bg-black my-4 text-white w-full rounded py-2 font-bold text-sm"
                  >
                    {!queryString && !otp ? 'request otp' : 'Reset Password'}
                  </button>
                </form>
                {!queryString && !otp && (
                  <p className="text-sm text-gray-500 text-center px-16">
                    It may take several minutes to receive a password reset email. Make sure to
                    check your junk mail.
                  </p>
                )}
                {otpStatus === 200 && <p>Email sent</p>}
              </div>
              <div className="flex justify-center items-center flex-col w-full bg-white p-4 border rounded border-gray-primary">
                <p className="text-sm">
                  Not registered?
                  <Link to="/register" className="pl-2 underline">
                    Create Account
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
