import React, { useEffect, useState, useContext } from 'react';
import UserCard from '../../components/Cards/UserCard';
import noNotes from './noNotes.png';
import axios from 'axios';
import { AppContext } from '../../Context/context';
import { AiTwotoneBulb } from 'react-icons/ai';

const Note = () => {
  const token = localStorage.getItem('token');
  const [user, setUser] = useState();
  // const [user, setUser] = useState('');
  const [notes, setNotes] = useState([]);
  const { updateNotes } = useContext(AppContext);

  useEffect(() => {
    try {
      const getNotes = async () => {
        let response = await axios.get('https://fargo-server.herokuapp.com/profile', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUser(response.data.usersInfo);
        setNotes(response.data.usersInfo.notes);
      };
      getNotes();
    } catch (error) {
      console.log(error);
    }
  }, [updateNotes]);
  return (
    <div>
      {notes.length === 0 && (
        <div className="text-center">
          <h1 className="text-xl mt-5">
            You currently have <span className="font-bold">No Notes</span>.
          </h1>
          <p className="my-4 px-4">
            To begin, click the <span className="font-bold">Add Notes</span> icon underneath the
            users Profile Picture. <br /> Notes are completely private, only you can see them.
          </p>
          <br />

          <img className="w-4/5 mx-auto mt-10" src={noNotes} alt="" />
        </div>
      )}

      {notes.length > 0 &&
        notes.map((note) => {
          return (
            <>
              <div className="flex justify-left items-center bg-[#7867a7] text-white font-normal text-lg py-2 pl-5 lg:mx-0 rounded mb-3">
                <AiTwotoneBulb className="mr-3 text-3xl" /> Network
              </div>
              <UserCard
                showFriendIcon={true}
                showFavoriteIcon={true}
                showNoteIcon={true}
                showContactButtons={true}
                acceptRejectFriend={false}
                users={[note]}
                user={user}
              />
            </>
          );
        })}
    </div>
  );
};

export default Note;
