import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineCheckCircle } from 'react-icons/ai';

import Footer from '../../layouts/footer';
import axios from 'axios';

const networkKeys = {
  startups: 'Startups & Funding Needed',
  business: 'Business & Private Investors',
  incubators: 'Incubators',
  accelerators: 'Accelerators',
};

const RegisterPreference = () => {
  const [network, setNetwork] = useState('');
  const [industry, setIndustry] = useState('');
  const [amount, setAmount] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [category, setCategory] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const prevStoredNetwork = localStorage.getItem('network') || '';
    const prevStoredIndustry = localStorage.getItem('industry') || '';
    const prevStoredAmount = localStorage.getItem('amount') || '';
    const prevStoredCategory = localStorage.getItem('category') || 'Thousand';
    const prevStoredState = localStorage.getItem('state') || '';
    const prevStoredCountry = localStorage.getItem('country') || '';
    setNetwork(prevStoredNetwork);
    setIndustry(prevStoredIndustry);
    setAmount(prevStoredAmount);
    setCategory(prevStoredCategory);
    setState(prevStoredState);
    setCountry(prevStoredCountry);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    // const token = localStorage.getItem('token');
     

        return navigate('/network');
 
  };




  
  return (
    <>
      <div className="flex flex-col justify-between h-screen bg-[#EBEFF4] ">
        <div className="bg-[#EBEFF4]  w-full">
          <div className=" h-[180px]  bg-[url('https://i.imgur.com/6ZyzH0D.png')] drop-shadow-lg text-center ">
            <h1 className="text-white text-4xl font-semibold pt-20">Create Your Profile</h1>
          </div>
          <div className="w-full">
            <div className=" max-w-[840px] mx-auto">
              <div className=" text-3xl py-10  text-center">
                <h6 className="text-sm mb-3">
                  <span>Profile • </span>
                  <span className="text-[#4335dc] font-medium">Preference • </span>
                  <span>Network • </span>
                  <span>Done</span>
                </h6>
                <h3>Industry Preferences</h3>
                <p className="text-base mt-5 px-10">
                  Apply privately to thousands of tech companies & startups with one profile.
                </p>
              </div>
              <form onSubmit={handleSubmit} className="border m-4 rounded-lg bg-white">
                <div className="m-5 md:flex  md:justify-center md:items-end">
                  <div className="relative flex flex-col w-full mr-10 ">
                    <label htmlFor="" className="pb-2">
                      Networks<span>*</span>
                    </label>

                    <select
                      className="block appearance-none w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 "
                      value={network}
                      onChange={(e) => {
                        setNetwork(e.target.value);
                        localStorage.setItem('network', e.target.value);
                      }}
                    >
                      <option value="">-- Select Option --</option>
                      {Object.keys(networkKeys).map((network, index) => (
                        <option key={index} value={networkKeys[network]}>
                          {networkKeys[network]}
                        </option>
                      ))}
                    </select>
                    <div className="absolute inset-y-1 top-8 right-0 flex items-center px-2 pointer-events-none">
                      <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                        <path d="M10 14l6-6H4z" />
                      </svg>
                    </div>
                  </div>
                  <div className="flex flex-col w-full justify-end">
                    <div
                      style={{
                        backgroundColor:
                          network === 'Startups & Funding Needed'
                            ? '#16A2B8'
                            : network === 'Business & Private Investors'
                            ? '#28a745'
                            : network === 'Incubators'
                            ? '#dc3545'
                            : network === 'Accelerators'
                            ? '#f0ad4e'
                            : '#16A2B8',
                      }}
                      className="w-full py-3   text-white  text-xs font-bold flex justify-center items-center "
                    >
                      <AiOutlineCheckCircle className="font-extrabold mr-2" />

                      {network === 'Startups & Funding Needed' ? (
                        <p> I'm seeking Business Partners or Investment Capital</p>
                      ) : network === 'Business & Private Investors' ? (
                        <p> I'm seeking Business Opportunities</p>
                      ) : network === 'Incubators' ? (
                        <p> I'm seeking Incubators </p>
                      ) : network === 'Accelerators' ? (
                        <p> I'm seeking Accelerators </p>
                      ) : (
                        <p> I'm seeking Business Partners or Investment Capital</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="m-5 md:flex md:justify-start">
                  <div className=" relative flex flex-col w-full md:mr-10">
                    <label htmlFor="" className="pb-2">
                      Industry Preferences<span>*</span>
                    </label>

                    <select
                      name=""
                      id=""
                      value={industry}
                      required
                      className="block appearance-none w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      onChange={(value) => {
                        setIndustry(value.target.value);
                        localStorage.setItem('industry', value.target.value);
                      }}
                    >
                      <option value="">-- Select Option --</option>

                      <option value="Aerospace">Aerospace</option>
                      <option value="Agriculture">Agriculture</option>
                      <option value="Biotechnology">Biotechnology</option>
                      <option value="Business Products">Business Products</option>
                      <option value="Business Services">Business Services</option>
                      <option value="Chemicals and Chemical Products">
                        Chemicals and Chemical Products
                      </option>
                      <option value="Clean Technology">Clean Technology</option>
                      <option value="Computers and Peripheral">Computers and Peripherals</option>
                      <option value="Construction">Construction</option>
                      <option value="Consulting">Consulting</option>
                      <option value="Consumer Products">Consumer Products</option>
                      <option value="Consumer Services">Consumer Services</option>
                      <option value="Digital Marketing">Digital Marketing</option>
                      <option value="Education">Education</option>
                      <option value="Electronics / Instrumentation">
                        Electronics / Instrumentation
                      </option>
                      <option value="Fashion">Fashion</option>
                      <option value="Financial Services">Financial Services</option>
                      <option value="Fintech">Fintech</option>
                      <option value="Food and Beverage">Food and Beverage</option>
                      <option value="Gaming">Gaming</option>
                      <option value="Healthcare Services">Healthcare Services</option>
                      <option value="Industrial/Energy">Industrial/Energy</option>
                      <option value="Internet / Web Services">Internet / Web Services</option>
                      <option value="IT Services">IT Services</option>
                      <option value="Legal">Legal</option>
                      <option value="Lifestyle">Lifestyle</option>
                      <option value="Marine">Marine</option>
                      <option value="Maritime/Shipping">Maritime/Shipping</option>
                      <option value="Marketing / Advertising">Marketing / Advertising</option>
                      <option value="Media and Entertainment">Media and Entertainment</option>
                      <option value="Medical Devices and Equipment">
                        Medical Devices and Equipment
                      </option>
                      <option value="Mining">Mining</option>
                      <option value="Mobile">Mobile</option>
                      <option value="Nanotechnology">Nanotechnology</option>
                      <option value="Networking">Networking and Equipment</option>
                      <option value="Oil and Gas">Oil and Gas</option>
                      <option value="Other">Other</option>
                      <option value="Real Estate / Construction">Real Estate / Construction</option>
                      <option value="Retailing / Distribution">Retailing / Distribution</option>
                      <option value="Robotics">Robotics</option>
                      <option value="Security">Security</option>
                      <option value="Semiconductors">Semiconductors</option>
                      <option value="Software">Software</option>
                      <option value="Sports">Sports</option>
                      <option value="Telecomunications">Telecommunications</option>
                      <option value="Transportation">Transportation</option>
                      <option value="Travel">Travel</option>
                    </select>
                    <div className="absolute inset-y-1 top-8 right-0 flex items-center px-2 pointer-events-none">
                      <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                        <path d="M10 14l6-6H4z" />
                      </svg>
                    </div>
                  </div>
                  <div className="flex flex-col w-full">
                    <label htmlFor="" className="pb-2">
                      Amount<span>*</span>
                    </label>
                    <div className="flex justify-left relative border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 ">
                      <input
                        type="number"
                        name="amount"
                        value={amount}
                        onChange={(e) => {
                          setAmount(e.target.value);
                          localStorage.setItem('amount', e.target.value);
                        }}
                        placeholder="55"
                        required
                        className="bg-white w-full text-center  rounded  py-2 px-2 pl-3 text-black focus:outline-none  focus:border-blue-500"
                      />
                      <select
                        name="category"
                        className="block appearance-none w-3/8 py-2 px-3 bg-white rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-right mr-5"
                        value={category}
                        onChange={(value) => {
                          setCategory(value.target.value);
                          localStorage.setItem('category', value.target.value);
                        }}
                      >
                        <option value={'Thousand'}> Thousand</option>
                        <option value={'Million'}> Million</option>
                      </select>
                      <div className="absolute inset-y-1 top-1 right-2 flex items-center px-2 pointer-events-none">
                        <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                          <path d="M10 14l6-6H4z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="m-5 md:flex md:justify-start">
                  <div className=" relative flex flex-col w-full md:mr-10">
                    <label htmlFor="" className="pb-2">
                      State<span>*</span>
                    </label>

                    <input
                      type="text"
                      className="block appearance-none w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      value={state}
                      onChange={(value) => {
                        setState(value.target.value);
                        localStorage.setItem('state', value.target.value);
                      }}
                    />
                  </div>
                  <div className="flex flex-col w-full">
                    <label htmlFor="" className="pb-2">
                      Country<span>*</span>
                    </label>

                    <input
                      type="text"
                      className="block appearance-none w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      value={country}
                      onChange={(value) => {
                        setCountry(value.target.value);
                        localStorage.setItem('country', value.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="text-right m-5">
                  <button
                    type="submit"
                    className="bg-[#4335dc] px-3 py-2 rounded mt-3 text-white text-sm"
                  >
                    Create Profile
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterPreference;
