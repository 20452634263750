import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import {
  AiOutlineInfoCircle,
  AiOutlineUser,
  AiOutlineEye,
  AiOutlineHdd,
  AiOutlineLaptop,
  AiOutlineSetting,
  AiOutlineStar,
} from 'react-icons/ai';

const Sidebar = () => {
  const [isShown, setIsShown] = useState(false);
  const location = useLocation();

  return (
    <>
      <div className="">
        {/* <div className="m-10 lg:mr-6 max-w-[1240px] mx-auto"> */}
        <div className=" lg:mr-6 max-w-[1240px] mx-auto">
          {/* <div className="mt-8 lg:flex lg:justify-between"> */}
          <div className="lg:flex lg:justify-between mb-10">
            <div className="lg:flex lg:justify-start lg:w-60">
              <ul className="flex flex-col divide-y divide-[#dadada] border border-[#b4b4b4] rounded  lg:w-60 lg:h-fit	">
                <NavLink to="/dashboard">
                  <div
                    style={{
                      backgroundColor: location.pathname === '/dashboard' ? '#7867a7' : undefined,
                      color: location.pathname === '/dashboard' ? '#fff' : undefined,
                    }}
                    className="flex justify-between items-center p-3 hover:bg-[#c1b7df] hover:text-[#000] cursor-pointer"
                  >
                    <li className="text-base">Browse Network</li>
                    <div className="flex ">
                      <div className="px-2 ">
                        <AiOutlineUser />
                      </div>

                      <div>
                        <div className="static">
                          <AiOutlineInfoCircle
                            onMouseEnter={() => setIsShown(true)}
                            onMouseLeave={() => setIsShown(false)}
                          />
                          <div>
                            {isShown && (
                              <p className="top-60 left-24 absolute text-xs	bg-[#4f4f4f] text-white p-3 rounded-xl">
                                Got a Question or Need Support? Create an Online Ticket and we will
                                help. <br />
                                Please allow 24-48 hours for a reply.
                                <br />
                                You can always follow up by calling us directly at (929) 297-0333
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>

                <NavLink to="/viewad">
                  <div
                    style={{
                      backgroundColor: location.pathname === '/viewad' ? '#7867a7' : undefined,
                      color: location.pathname === '/viewad' ? '#fff' : undefined,
                    }}
                    className="flex justify-between items-center p-3 hover:bg-[#c1b7df] hover:text-[#000] cursor-pointer"
                  >
                    <li className="text-base">View my Ad</li>
                    <div className="flex ">
                      <div className="px-2 ">
                        <AiOutlineEye />
                      </div>
                      <AiOutlineInfoCircle />
                    </div>
                  </div>
                </NavLink>

                <NavLink to="/support">
                  <div
                    style={{
                      backgroundColor: location.pathname === '/support' ? '#7867a7' : undefined,
                      color: location.pathname === '/support' ? '#fff' : undefined,
                    }}
                    className="flex justify-between items-center p-3 hover:bg-[#c1b7df] hover:text-[#000] cursor-pointer"
                  >
                    <li className="text-base">Support</li>
                    <div className="flex ">
                      <div className="px-2 ">
                        <AiOutlineLaptop />
                      </div>
                      <AiOutlineInfoCircle />
                    </div>
                  </div>
                </NavLink>

                <NavLink to="/settings">
                  <div
                    style={{
                      backgroundColor:
                        location.pathname === '/settings' || location.pathname === '/idverify'
                          ? '#7867a7'
                          : undefined,
                      color:
                        location.pathname === '/settings' || location.pathname === '/idverify'
                          ? '#fff'
                          : undefined,
                    }}
                    className="flex justify-between items-center p-3 hover:bg-[#c1b7df] hover:text-[#000] cursor-pointer"
                  >
                    {/* <div className="flex justify-between items-center p-3 hover:bg-[#7867a7] hover:text-[#fff] cursor-pointer" style = {location.pathname === "/settings" ? { backgroundColor: "red" } : {} }>

                  <div className="flex justify-between items-center p-3 hover:bg-[#7867a7] hover:text-[#fff] cursor-pointer" style={({ isActive }) =>
              isActive ? { backgroundColor: "red" } : undefined
            }> */}
                    <li className="text-base">Account Settings</li>
                    <div className="flex ">
                      <div className="px-2 ">
                        <AiOutlineSetting />
                      </div>
                      <AiOutlineInfoCircle />
                    </div>
                  </div>
                </NavLink>

                <NavLink to="/upgrade">
                  <div
                    style={{
                      backgroundColor: location.pathname === '/upgrade' ? '#7867a7' : undefined,
                      color: location.pathname === '/upgrade' ? '#fff' : undefined,
                    }}
                    className="flex justify-between items-center p-3 hover:bg-[#c1b7df] hover:text-[#000] cursor-pointer"
                  >
                    <li className="text-base">Upgrade Account</li>
                    <div className="flex ">
                      <div className="px-2 ">
                        <AiOutlineStar />
                      </div>
                      <AiOutlineInfoCircle />
                    </div>
                  </div>
                </NavLink>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
