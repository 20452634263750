import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FiUsers } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { AiOutlineDashboard } from 'react-icons/ai';
import { AiOutlineEye } from 'react-icons/ai';
import { TbUserCheck } from 'react-icons/tb';
import { MdOutlineVerified } from 'react-icons/md';
import { BiSupport } from 'react-icons/bi';
import Sidebar from './Sidebar';
import Pagination from '../../components/Pagination';
import Ticket from './Ticket';

const Support = () => {
  const [users, setUsers] = useState([]);

  const [tickets, setTickets] = useState([]);
  const [reply, setReply] = useState('');
  const token = localStorage.getItem('token');
  const [currentPage, setCurrentPage] = useState(1);
  // how many posts do you want to show per page
  const [ticketsPerPage, setPostsPerPage] = useState(10);
  const [isActionOpen, setIsActionOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // useEffect because we want this function to run as soon as the page renders
  useEffect(() => {
    // get the posts using async (bc js is an asynchornous language)
    const fetchTickets = async () => {
      // as soon loading is true, it'll hide the axios function until it's gotten the corrent information
      setLoading(true);
      // get the the value that's stored in the API
      const res = await axios.get('https://fargo-server.herokuapp.com/all-tickets', {
        headers: { Authorization: `Bearer ${token}` },
      });
      // sometimes put the value into clg to see what it returns. this one show sus all the posts
      console.log(res.data);
      setTickets(res.data.allTickets.reverse());
      // loading can be set to false now because the app received the info
      setLoading(false);
    };
    //   call this function
    fetchTickets();
  }, []);

  const adminReply = async (e, index, ticketId) => {
    e.preventDefault();
    try {
      // the first argument is the API, the second is the information you want to pass to the backend, and the third is the headers
      const response = await axios.put(
        'https://fargo-server.herokuapp.com/ticket/admin',
        { ticketId, message: reply },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // tickets will have the value of response.data.myTickets
      console.log('response.data', response.data);

      let copyTickets = [...tickets];

      copyTickets[index] = response.data.ticketExists;

      setTickets([...copyTickets]);

      setReply('');
    } catch (error) {
      console.log(error);
    }
  };

  const closeTicket = async (e, ticketId) => {
    e.preventDefault();
    try {
      // the first argument is the API, the second is the information you want to pass to the backend, and the third is the headers
      const response = await axios.patch(
        'https://fargo-server.herokuapp.com/ticket/close-ticket',
        { ticketId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // tickets will have the value of response.data.myTickets
      console.log('response.data', response.data);

      if (response.status === 200) {
        let newTickets = tickets.filter((ticket) => {
          return ticket._id !== ticketId;
        });
        setTickets([...newTickets]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Get current posts
  const indexOfLastTicket = currentPage * ticketsPerPage;
  const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;
  const currentTickets = tickets.slice(indexOfFirstTicket, indexOfLastTicket);

  // change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <div className="flex">
        <Sidebar />

        {/* rest of the website */}
        <div className="border-4 border-red-500 w-full bg-[#F0F3F6] 	">
          <div className="m-10 ">
            <h3 className="text-2xl font-medium pb-3 border-b-2 ">Tickets</h3>

            <div className="  border-4 border-green-500">
              {/* Table */}
              <div className="border-4 border-blue-500 mt-4 shadow-2xl rounded-md">
                <div
                  className="flex
  justify-between items-align p-6 bg-[#F5F5F5] "
                >
                  <h4 className="text-2xl">Untreated</h4>
                  <button className="text-white bg-[#ffc107] px-3 rounded-sm ">Search</button>
                </div>
                <div class="border-4 border-yellow-500 w-fulltable-responsive">
                  <table class="table table-striped w-full overflow-auto">
                    <thead>
                      <tr>
                        <th className="bg-blue-100 border py-4" scope="col">
                          Date Created
                        </th>
                        <th className="bg-blue-100 border py-4" scope="col">
                          Name
                        </th>
                        <th className="bg-blue-100 border py-4 hidden md:table-cell" scope="col">
                          Email
                        </th>
                        <th className="bg-blue-100 border py-4 hidden md:table-cell" scope="col">
                          Title
                        </th>
                        <th className="bg-blue-100 border py-4" scope="col">
                          Action
                        </th>
                      </tr>
                    </thead>

                    {loading ? (
                      <tbody>
                        <tr>
                          <td colSpan={5}>
                            <div class="my-32   flex flex-col items-center justify-center">
                              <div class="absolute w-12 h-12 rounded-full border-8 border-solid border-gray-200"></div>
                              <div class="w-12 h-12 rounded-full animate-spin border-8 border-solid border-purple-500 border-t-transparent shadow-md"></div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <>
                        <Ticket
                          tickets={currentTickets}
                          setIsActionOpen={setIsActionOpen}
                          isActionOpen={isActionOpen}
                          setReply={setReply}
                          reply={reply}
                          adminReply={adminReply}
                          closeTicket={closeTicket}
                        />
                        <Pagination
                          ticketsPerPage={ticketsPerPage}
                          totalTickets={tickets.length}
                          paginate={paginate}
                        />
                      </>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
