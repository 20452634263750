import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FiUsers } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { AiOutlineDashboard } from 'react-icons/ai';
import { TbUserCheck } from 'react-icons/tb';
import { MdOutlineVerified } from 'react-icons/md';
import { BiSupport } from 'react-icons/bi';
import Sidebar from '../Support/Sidebar';
// import UserRow from '../../components/Dashboard/UserRow';

// const Dashboard = () => {
//     const [users, setUsers] = useState([])
//     const token = localStorage.getItem('token')
//     useEffect(() => {
//         const getUser = async () => {
//          try {
//            const response = await axios.get("/allUsers", {
//              headers:{Authorization:`Bearer ${token}`}
//          })
//          console.log(response.data.getAllUsers)
//          setUsers(response.data.getAllUsers)
//          } catch (error) {
//            console.log(error)
//          }
//         }
//         getUser()
//        }, [])
//   return (
//     <>
//       <div>
//         {/* sidebar */}
//         <div>
//             sidebar
//         </div>
//    <div>

// <div>
//     list of all the users
// </div>

// {users.map((user)=> {
//     return (
// <div className='flex'>
//     <p className='mr-2'>{user.fullName}</p>
//     <p className='mr-2'>{user.email}</p>
//     <p className='mr-2'>Verified: {user.verified ? "Yes" : "No"}</p>
//     <p className='mr-2'>{user.profileAd}</p>
//     <p>admin {user.isAdmin  ? "Yes" : "No"}</p>
// </div>
//     )
// })}

//       </div>
//       </div>

//     </>
//   )
// }

const Dashboard = () => {
  const token = localStorage.getItem('token');

  const [unverified, setUnverified] = useState([]);

  useEffect(() => {
    const getUnverifiedUsers = async () => {
      try {
        const response = await axios.get('https://fargo-server.herokuapp.com/unverified', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUnverified(response.data);
      } catch (error) {
        console.log('error fetching all unverified users', error);
      }
    };
    getUnverifiedUsers();
  }, []);

  const verifyUser = async (userId) => {
    try {
      const response = await axios.post(
        'https://fargo-server.herokuapp.com/verify',
        { userId },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.status === 200) {
        let newUnverified = unverified.filter((user) => {
          return user._id !== userId;
        });
        setUnverified([...newUnverified]);
        return;
      }
    } catch (error) {
      console.log('error verifing user', error);
    }
  };
  return (
    <>
      <div className="flex">
        <Sidebar />
        {/* rest of the website */}
        <div className="border-4 border-red-500 w-full">
          <div className="m-10 ">
            <h3 className="text-2xl font-medium pb-3 border-b-2 ">Administration</h3>

            <div className="lg:flex lg:justify-start lg:items-start  border-4 border-green-500">
              <div className="border-4 border-orange-500 lg:w-2/5 lg:mr-6 p-4">
                <div className="mb-3">
                  <h6 className="text-xl">Overall stats</h6>
                  <p className="text-sm">Summary statistics of your project</p>
                  <div className="flex ">
                    <div className="border-4 border-blue-500 w-full mr-2">
                      <div className="mb-4">
                        <div className="flex justify-between items-center border-b-4 border-[#7f67ab]">
                          <FiUsers className="text-[#7f67ab]" size={28} />
                          <div className="text-right">
                            <p>2</p>
                            <p className="text-sm  pb-4">Total Users</p>
                          </div>
                        </div>
                      </div>
                      <div className="mb-4">
                        <div className="flex justify-between items-center border-b-4 border-[#dc3545]">
                          <FiUsers className="text-[#dc3545]" size={28} />
                          <div className="text-right">
                            <p>2</p>
                            <p className="text-sm pb-4">Total Tickets</p>
                          </div>
                        </div>
                      </div>
                      <div className="mb-4">
                        <div className="flex justify-between items-center border-b-4 border-[#ffc107]">
                          <FiUsers className="text-[#ffc107]" size={28} />
                          <div className="text-right">
                            <p>234</p>
                            <p className="text-sm pb-4">Total Verifications</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-4 border-red-500 w-full ml-2">
                      <div className="mb-4">
                        <div className="flex justify-between items-center border-b-4 border-[#28a745]">
                          <FiUsers className="text-[#28a745]" size={28} />
                          <div className="text-right">
                            <p>2</p>
                            <p className="text-sm  pb-4">Total Users</p>
                          </div>
                        </div>
                      </div>
                      <div className="mb-4">
                        <div className="flex justify-between items-center border-b-4 border-[#17a2b8]">
                          <FiUsers className="text-[#17a2b8]" size={28} />
                          <div className="text-right">
                            <p>2</p>
                            <p className="text-sm pb-4">Total Tickets</p>
                          </div>
                        </div>
                      </div>
                      <div className="mb-4">
                        <div className="flex justify-between items-center border-b-4 border-[#607d8b]">
                          <FiUsers className="text-[#607d8b]" size={28} />
                          <div className="text-right">
                            <p>234</p>
                            <p className="text-sm pb-4">Total Verifications</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Table */}
              <div class="border-4 border-yellow-500 lg:w-3/5 table-responsive">
                <table class="table table-striped w-full overflow-auto">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">First</th>
                      <th scope="col">Last</th>
                      <th scope="col">Handle</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    <tr>
                      <th scope="row">1</th>
                      <td>Mark</td>
                      <td>Otto</td>
                      <td>@mdo</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Jacob</td>
                      <td>Thornton</td>
                      <td>@fat</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td colspan="2">Larry the Bird</td>
                      <td>@twitter</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td colspan="2">Larry the Bird</td>
                      <td>@twitter</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td colspan="2">Larry the Bird</td>
                      <td>@twitter</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td colspan="2">Larry the Bird</td>
                      <td>@twitter</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td colspan="2">Larry the Bird</td>
                      <td>@twitter</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td colspan="2">Larry the Bird</td>
                      <td>@twitter</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td colspan="2">Larry the Bird</td>
                      <td>@twitter</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td colspan="2">Larry the Bird</td>
                      <td>@twitter</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td colspan="2">Larry the Bird</td>
                      <td>@twitter</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td colspan="2">Larry the Bird</td>
                      <td>@twitter</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td colspan="2">Larry the Bird</td>
                      <td>@twitter</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td colspan="2">Larry the Bird</td>
                      <td>@twitter</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td colspan="2">Larry the Bird</td>
                      <td>@twitter</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td colspan="2">Larry the Bird</td>
                      <td>@twitter</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td colspan="2">Larry the Bird</td>
                      <td>@twitter</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td colspan="2">Larry the Bird</td>
                      <td>@twitter</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td colspan="2">Larry the Bird</td>
                      <td>@twitter</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td colspan="2">Larry the Bird</td>
                      <td>@twitter</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td colspan="2">Larry the Bird</td>
                      <td>@twitter</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
