import React, { useState } from 'react'
import { createContext } from 'react'

 const AppContext = createContext()

 const ContextComponent = ({children}) => {
  const [updateMenu,setUpdateMenu] = useState(true)
  const [updateFriendRequestReceived,setupdateFriendRequestReceived] = useState(true)
  const [updateFavorites,setUpdateFavourites] = useState(true)
  const [updateNotes,setUpdateNotes] = useState(true)

  return (
    <AppContext.Provider value={{updateMenu,setUpdateMenu,updateFriendRequestReceived,setupdateFriendRequestReceived,updateFavorites,setUpdateFavourites,updateNotes,setUpdateNotes}}>
        {children}
    </AppContext.Provider>
  )
}

export {AppContext,ContextComponent}
