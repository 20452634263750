import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../../Context/context';
import friend from './icons/add-user-click.png';
import ClickFriendsToAdd from './icons/add-user-1.png';
import heart from './icons/heart-1.png';
import ClickHeart from './icons/heart-click.png';
import blankNote from './icons/note-1.png';
import ClickNote from './icons/note-click.png';
import picture from './icons/picture-1.png';
import ClickPicture from './icons/picture-click.png';
import video from './icons/video-1.png';
import ClickVideo from './icons/video-click.png';
import check from './icons/check-1.png';
import checkClick from './icons/check-click.png';
import userPic from './icons/placeholderUser.png';
import { AiTwotoneTag } from 'react-icons/ai';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { RxCrossCircled } from 'react-icons/rx';

import sendFriendRequest from './userCardHandlers';
// import checkMark3 from './checkMark3.png'

// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// import FriendsContext from "../../context/friends";
import { AiOutlineClose, AiOutlineCheckCircle, AiTwotonePushpin } from 'react-icons/ai';
import { BsArrowBarDown } from 'react-icons/bs';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const UserCard = ({
  showFriendIcon,
  showFavoriteIcon,
  showNoteIcon,
  showContactButtons,
  acceptRejectFriend,
  showEditIcon,
  user,
  setUser,
  hideAbbreviateName,
  users,
  loading,
}) => {
  const [isShowFull, setIsShowFull] = useState(false);
  const token = localStorage.getItem('token');
  const [note, setNote] = useState({});
  const [isNoteOpen, setIsNoteOpen] = useState(false);
  const [openImages, setOpenImages] = useState(false);
  const [openVideos, setOpenVideos] = useState(false);
  const [openToggle, setOpenToggle] = useState(null);
  const [friendRequestSent, setfriendRequestSent] = useState([]);
  const [friends, setFriends] = useState([]);
  const [favorites, setFavorites] = useState([]);
  // const [showImage, setShowImage] = useState(false);
  // const [isHovered, setIsHovered] = useState(false);
  const [hoverFavorite, setHoverFavorite] = useState(false);
  const [hoverFriend, setHoverFriend] = useState(false);
  const [hoverPicture, setHoverPicture] = useState(false);
  const [hoverVideo, setHoverVideo] = useState(false);
  const [hoverNote, setHoverNote] = useState(false);
  const [userNotes, setUserNotes] = useState([]);
  const [expandedImage, setExpandedImage] = useState(null);
  const [videoUrlInDb, setVideoUrlInDb] = useState();
  const { setUpdateMenu, setupdateFriendRequestReceived, setUpdateFavourites, setUpdateNotes } =
    useContext(AppContext);
  const [notes, setNotes] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (Object.keys(user).length) {
      let friends = user?.friends.map((friend) => {
        return friend.email;
      });
      let favorites = user?.favorites.map((fav) => {
        return fav.email;
      });

      let friendRequests = user?.friendRequestsSent.map((friendRequest) => {
        return friendRequest.email;
      });
      let presentNotes = user?.notes.map((note) => {
        if (note.notesArray.length > 0) {
          return note.email;
        }
      });

      setFriends(friends);
      setfriendRequestSent(friendRequests);
      setFavorites(favorites);
      setNotes(presentNotes);
      setUserNotes(user?.notes);
      setVideoUrlInDb(user?.videosMedia);
    }
  }, []);

  //all  functions dealing with friends
  const sendFriendRequest = async (email) => {
    try {
      if (friends.includes(email) || friendRequestSent.includes(email)) {
        return;
      }
      const response = await axios.post('https://fargo-server.herokuapp.com/friendRequest',{ email },
        {headers: { Authorization: `Bearer ${token}` }});
      console.log('response.data', response.data);
      setfriendRequestSent((prevstate) => {
        return [...prevstate, response.data];
      });
      setUpdateMenu((prevstate) => !prevstate);
    } catch (error) {
      console.log(error);
    }
  };
  const acceptFriend = async (email) => {
    try {
      const response = await axios.post(
        'https://fargo-server.herokuapp.com/friendAccepted',
        { email },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setUser(response.data);
      setFriends((prevstate) => {
        return [...prevstate, response.data];
      });
      setupdateFriendRequestReceived((prevstate) => !prevstate);
      setUpdateMenu((prevstate) => !prevstate);
    } catch (error) {
      console.log(error);
    }
  };
  const rejectFriend = async (id) => {
    try {
      const response = await axios.post(
        'https://fargo-server.herokuapp.com/friendRejected',
        { id },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setUser(response.data.user);
      let newFriends = friends.filter((friend) => {
        return friend !== response.data.email;
      });
      setFriends(newFriends);
      setupdateFriendRequestReceived((prevstate) => !prevstate);
      setUpdateMenu((prevstate) => !prevstate);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  // if (isFriendRequestSent) {
  // hide the pop-up message after 4 seconds

  // setIsFriendRequestSent(false);
  // const timeout = setTimeout(() => {
  //   setIsFriendRequestSent(false);
  // }, 4000);
  // return () => clearTimeout(timeout);
  // }
  // }, [isFriendRequestSent]);

  console.log('locatioin', location);

  //functions dealing with notes
  const saveNote = async (e, email) => {
    e.preventDefault();
    if (!note[email]) return;
    try {
      const response = await axios.post(
        'https://fargo-server.herokuapp.com/notes',
        { email, text: note[email] },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setNote((prevstate) => {
        return { ...prevstate, [email]: '' };
      });
      setNotes((prevstate) => {
        return [...prevstate, email];
      });
      console.log(response.data);
      setUserNotes(response.data);
      setUpdateMenu((prevstate) => !prevstate);
      if (location.pathname === '/notes') setUpdateNotes((prevstate) => !prevstate);
    } catch (error) {
      console.log(error);
    }
  };

  const addNote = (e, email) => {
    setNote((prevstate) => {
      return { ...prevstate, [email]: e.target.value };
    });
  };

  const deleteNote = async (e, email, noteId) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'https://fargo-server.herokuapp.com/deletenote',
        { email, noteId },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log(response.data);
      setUserNotes(response.data);
      setUpdateMenu((prevstate) => !prevstate);
      if (location.pathname === '/notes') setUpdateNotes((prevstate) => !prevstate);
    } catch (error) {
      console.log(error);
    }
  };

  //function dealing with favourites
  const FavoriteHandler = async (email, userId) => {
    try {
      if (favorites.includes(email)) {
        await unfavoriteHandler(userId, email);
        return;
      }
      const response = await axios.post(
        'https://fargo-server.herokuapp.com/favorites',
        { email },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setFavorites((prevstate) => {
        return [response.data, ...prevstate];
      });
      setUpdateMenu((prevstate) => !prevstate);
    } catch (error) {
      console.log(error);
    }
  };

  const unfavoriteHandler = async (userId, useremail) => {
    try {
      const response = await axios.post(
        'https://fargo-server.herokuapp.com/removeFavorite',
        { userId, useremail },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setFavorites((prevstate) => {
        return prevstate.filter((e) => e !== response.data);
      });
      setUpdateMenu((prevstate) => !prevstate);
      setUpdateFavourites((prevstate) => !prevstate);
    } catch (error) {
      console.log(error);
    }
  };

  //function that navigates to inbox
  const sendMessage = async (userid, fullname) => {
    console.log('userId', userid);
    return navigate('/inbox', { state: { userid, fullname } });
  };
  const date = new Date(note.createdAt);
  const formattedDate = `${date.toLocaleDateString()} ${date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;

  function abbreviateName(name) {
    const nameArray = name.split(' ');
    if(nameArray.length>1){

      const firstName = nameArray[0];
      const lastNameInitial = nameArray[1][0];
      const abbreviatedName = `${firstName} ${lastNameInitial}.`;
      return abbreviatedName;
    }
    else{
      return name
    }

  }

  const handleImageClick = (image) => {
    setExpandedImage(image);
  };

  const handleImageClose = () => {
    setExpandedImage(null);
  };

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <div>
          {users
            ?.filter((elemUser) => elemUser?._id !== user?._id)
            .map((user, index) => {
              console.log('user on question', user);
              return (
                <div className=" bg-[#ffffff]  lg:m-0 lg:mb-6 lg:mx-0 rounded-tr-md rounded-tl-md mb-5 border border-[#bebebe]">
                  <div className="bg-[#ededed] rounded-tr rounded-tl px-2 py-2 flex justify-between items-center  ">
                    <div className="text-lg font-bold">{user?.profileAd}</div>
                    {showEditIcon && (
                      <Link to="/settings">
                        <button className="bg-[#16A2B8] text-white px-3 py-1.5 rounded hover:bg-[#138a9d]">
                          Edit
                        </button>
                      </Link>
                    )}
                  </div>
                  <div className="lg:flex lg:justify-start px-3   mt-8">
                    <div>
                      <p className="text-center text-sm text-[#969696]">
                        PROFILE ID: {user?.profileNumber}
                      </p>
                      <div className="flex m-2 border w-32 h-36  lg:w-36 mx-auto">
                        <img
                          src={user?.profileImage?.secureUrl || userPic}
                          alt=""
                          className="w-40 border p-2 mx-auto object-cover"
                        />
                      </div>

                      <div className="flex justify-center lg:justify-around">
                        {/* Friends icon */}
                        {showFriendIcon && (
                          <div>
                            <div className="relative cursor-pointer group mx-2 lg:mx-0">
                              <div className="relative">
                                <img
                                  src={
                                    hoverFriend === user?._id ||
                                    friends.includes(user?.email) ||
                                    friendRequestSent.includes(user?.email)
                                      ? friend
                                      : ClickFriendsToAdd
                                  }
                                  alt=""
                                  // className="	absolute top-0 left-0 hover:opacity-0 w-5"
                                  className="	 w-5"
                                  onMouseOver={() => setHoverFriend(user?._id)}
                                  onMouseLeave={() => setHoverFriend('')}
                                  onClick={() =>
                                    sendFriendRequest(
                                      user?.email,
                                      friends,
                                      friendRequestSent,
                                      setfriendRequestSent,
                                      setUpdateMenu
                                    )
                                  }
                                />
                              </div>
                              <span className="absolute w-auto p-2  min-w-max bottom-7 rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100 group-hover:opacity-100 transform -translate-x-1/2 left-1/2">
                                {`${
                                  friends.includes(user?.email)
                                    ? 'Friend Added'
                                    : friendRequestSent.includes(user?.email)
                                    ? 'Friend request sent'
                                    : 'Add to friends'
                                }`}
                              </span>
                            </div>

                            <img
                              src={
                                friends.includes(user?.email) ||
                                friendRequestSent.includes(user?.email)
                                  ? checkClick
                                  : check
                              }
                              alt=""
                              className="w-4 mx-auto mt-2"
                            />
                          </div>
                        )}

                        {/* Video Icon */}
                        <div className="">
                          {/* FIXME: WHEN I CLICK THE VIDEO OR IMAGE ICON FOR THE FIRST TIME, NOTHING HAPPENS. ONLY THE SECOND TIME, IT OPENS */}
                          <div className="relative cursor-pointer group mx-2 lg:mx-0">
                            <div
                              className="relative"
                              onClick={() =>
                                setOpenToggle(
                                  openToggle === `${user?._id} video` ? null : `${user?._id} video`
                                )
                              }
                            >
                              <img
                                className="w-5"
                                alt="video"
                                src={
                                  hoverVideo === user?._id || user?.imagesMedia?.length
                                    ? ClickVideo
                                    : video
                                }
                                onMouseOver={() => setHoverVideo(user?._id)}
                                onMouseOut={() => setHoverVideo('')}
                              />
                            </div>
                            <span className="absolute w-auto p-2  min-w-max bottom-7 rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100 group-hover:opacity-100 transform -translate-x-1/2 left-1/2">
                              No Videos Uploaded
                            </span>
                          </div>
                          <img
                            src={user?.imagesMedia?.length ? checkClick : check}
                            alt=""
                            className="w-4 mx-auto mt-2"
                          />
                        </div>

                        <div className="">
                          <div
                            className="relative cursor-pointer group mx-2 lg:mx-0"
                            // onClick={() => setOpenImages((prev) => !prev)}
                          >
                            <div
                              className="relative"
                              onClick={() =>
                                setOpenToggle(
                                  openToggle === `${user?._id} image` ? null : `${user?._id} image`
                                )
                              }
                            >
                              <img
                                className="w-5"
                                alt="heart"
                                src={
                                  hoverPicture === user?._id || user?.imagesMedia?.length
                                    ? ClickPicture
                                    : picture
                                }
                                onMouseOver={() => setHoverPicture(user?._id)}
                                onMouseOut={() => setHoverPicture('')}
                              />
                            </div>
                            <span className="absolute w-auto p-2  min-w-max bottom-7 rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100 group-hover:opacity-100 transform -translate-x-1/2 left-1/2">
                              {user?.imagesMedia?.length ? 'View Images' : 'No Images Uploaded'}
                            </span>
                          </div>
                          <img src={check} alt="" className="w-4 mx-auto mt-2" />
                        </div>
                        {showFavoriteIcon && (
                          <div className="">
                            <div className="relative cursor-pointer group mx-2 lg:mx-0">
                              <div
                                className="relative"
                                onClick={() => {
                                  FavoriteHandler(user?.email, user?._id);
                                }}
                              >
                                <img
                                  className="w-5"
                                  alt="heart"
                                  src={
                                    hoverFavorite === user?._id || favorites.includes(user?.email)
                                      ? ClickHeart
                                      : heart
                                  }
                                  onMouseOver={() => setHoverFavorite(user?._id)}
                                  onMouseOut={() => setHoverFavorite('')}
                                />
                              </div>
                              <span className="absolute w-auto p-2  min-w-max bottom-7 rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100 group-hover:opacity-100 transform -translate-x-1/2 left-1/2">
                                {' '}
                                {`${
                                  favorites.includes(user?.email)
                                    ? 'Remove Favorite'
                                    : 'Add to Favorites'
                                }`}
                              </span>
                            </div>

                            <img
                              src={favorites.includes(user?.email) ? checkClick : check}
                              alt=""
                              className="w-4 mx-auto mt-2"
                            />
                          </div>
                        )}
                        {showNoteIcon && (
                          <div>
                            <div className="relative cursor-pointer group mx-2 lg:mx-0">
                              <div className="relative">
                                <img
                                  alt=""
                                  className="w-5"
                                  src={
                                    hoverNote === user?._id || notes.includes(user?.email)
                                      ? ClickNote
                                      : blankNote
                                  }
                                  onMouseOver={() => setHoverNote(user?._id)}
                                  onMouseOut={() => setHoverNote('')}
                                  onClick={() =>
                                    setOpenToggle(
                                      openToggle === `${user?._id} note` ? null : `${user?._id} note`
                                    )
                                  }
                                />
                                {/* <img
                  src={note}
                  alt=""
                  className="	absolute top-0 left-0 hover:opacity-0 w-5"
                /> */}
                              </div>
                              <span className="absolute w-auto p-2  min-w-max bottom-7 rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100 group-hover:opacity-100 transform -translate-x-1/2 left-1/2">
                                {/* {(text = 'Add Private Notes')} */}
                                Add Private Notes
                              </span>
                            </div>
                            <img
                              src={notes.includes(user?.email) ? checkClick : check}
                              alt=""
                              className="w-4 mx-auto mt-2"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="lg:ml-6 lg:w-full">
                      <div className="lg:flex lg:justify-between">
                        <div>
                          <div className="flex justify-center items-center text-center text-sm mt-2 lg:mt-0 text-[#969696] lg:flex lg:justify-start ">
                            {!hideAbbreviateName && <div className="pr-2 ">{user?.fullName}</div>}
                            {hideAbbreviateName && (
                              <div className="pr-2 ">{abbreviateName(user?.fullName)}</div>
                            )}
                            <AiTwotonePushpin />
                            <div className="pl-1">TEXAS, UNITED STATES</div>
                          </div>

                          <div className="lg:flex lg:justify-between">
                            <div className="flex  justify-center lg:flex lg:justify-start">
                              <button
                                className={`px-3 py-2 rounded mt-3 mr-2 text-white text-sm cursor-default ${
                                  user?.network === 'Startups & Funding Needed'
                                    ? 'bg-[#f0ad4e]'
                                    : user?.network === 'Business & Private Investors'
                                    ? 'bg-[#28a745]'
                                    : user?.network === 'Incubators'
                                    ? 'bg-purple-600'
                                    : user?.network === 'Accelerators'
                                    ? 'bg-[#16A2B8]'
                                    : 'bg-[#f0ad4e]'
                                }`}
                              >
                                {user?.network === 'Startups & Funding Needed'
                                  ? 'STARTUP'
                                  : user?.network === 'Business & Private Investors'
                                  ? 'BUSINESS INVESTOR'
                                  : user?.network === 'Incubators'
                                  ? 'INCUBATOR'
                                  : user?.network === 'Accelerators'
                                  ? 'ACCELERATOR'
                                  : ''}
                              </button>
                              <button
                                className={`${
                                  user?.network === 'Startups & Funding Needed'
                                    ? 'bg-[#dc3545]'
                                    : 'bg-[#17a2b8]'
                                } px-3 py-2 rounded mt-3 text-white text-sm cursor-default`}
                              >
                                {user?.network === 'Startups & Funding Needed' ?

                                (`FUNDING NEEDED: ${user?.amount} ${user?.category === 'Thousand' ? 'K $' : 'M $'}`)
                                
                                : `AVAILABLE CAPITAL: ${user?.amount} ${user?.category === 'Thousand' ? 'K $' : 'M $'}`
                                
                                }
                              </button>
                            </div>
                          </div>
                        </div>

                        {acceptRejectFriend && (
                          <div className="flex items-end">
                            <div
                              onClick={() => acceptFriend(user?.email)}
                              className="bg-[#28a745] px-3 py-2 h-9 mr-3 rounded  text-white text-sm transition duration-300 ease-in-out hover:shadow-sm cursor-pointer"
                            >
                              Accept
                            </div>
                            <div
                              onClick={() => rejectFriend(user?._id)}
                              className="bg-[#343a40] px-3 py-2 rounded h-9 text-white text-sm transition duration-300 ease-in-out  hover:shadow-sm cursor-pointer"
                            >
                              Reject
                            </div>
                          </div>
                        )}
                        {
                          showContactButtons && (
                            <div className="flex justify-center lg:flex lg:flex-col lg:w-32">
                              <div class="relative cursor-pointer group mx-2 lg:mx-0">
                                <button
                                  class="bg-[#ffc107] px-3 py-2 rounded mt-3 mr-2 lg:m-0 text-white text-sm hover:bg-[#edb205] w-full transition duration-300 ease-in-out hover:shadow-sm"
                                  onClick={() => sendMessage(user?._id, user?.fullName)}
                                >
                                  Send Message
                                </button>
                                <span class="absolute w-auto p-2  min-w-max bottom-10 rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100 group-hover:opacity-100 transform -translate-x-1/2 left-1/2">
                                  {friends.includes(user?.email)
                                    ? user?.isPro
                                      ? 'Friend Added'
                                      : 'Upgrade to Send Message'
                                    : 'Add to friends'}
                                </span>
                              </div>

                              <button className="bg-[#007bff] px-3 py-2 rounded mt-3 text-white text-sm  hover:bg-[#0069d9] transition duration-300 ease-in-out hover:shadow-sm">
                                Update to View
                              </button>
                            </div>
                          )

                          // {
                          //   `${friends.includes(user?.email) ? "Friend Added": friendRequestSent.includes(user?.email) ? "Friend request sent": "Add to friends"}`
                          // }
                        }
                      </div>

                      {/* paragraph about the company */}
                      <div>
                        <div className="h-5 border-b-2 border-[#b4b4b4] text-center text-2xl "></div>

                        {isShowFull===user?.profileNumber
                          ? user?.profileDescription
                          : user?.profileDescription?.substring(0, 200)}

                        <button
                          className="text-[#1075ce] font-bold"
                          onClick={() => {
                            if(isShowFull===user?.profileNumber){
                              setIsShowFull();
                              return;
                            }
                            setIsShowFull(user?.profileNumber)
                          }}
                        >
                          {isShowFull ? '...view less' : '...view more'}
                        </button>

                        <button
                          className="text-[#1075ce] font-bold"
                          // onClick={() => {
                          //   setIsShowFull((prev) => !prev);
                          // }}
                        ></button>
                        <div className="h-5 border-b-2 border-[#b4b4b4] text-center text-2xl "></div>

                        {/* <div className="flex justify-between items-center mt-3"> */}
                        {/* New Tab */}

                        <div className="lg:flex lg:justify-between lg:items-center mt-2">
                          {/* Type */}
                          <div className="flex justify-center">
                            <div className="flex justify-start mr-4">
                              <div className="font-medium uppercase mr-2">Type:</div>

                              <div className="flex justify-start items-center cursor-pointer inline-flex relative w-fit group">
                                <div>network</div>
                                <div className="absolute w-auto top-6 left-0 p-2 min-w-max rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-top group-hover:scale-100">
                                  <div className="flex justify-start items-center mb-2">
                                    <img src={checkClick} alt="" className="w-4 mr-1" />
                                    <h6>Venture</h6>
                                  </div>
                                  <div className="flex justify-start items-center mb-2">
                                    <img src={friend} alt="" className="w-4 mr-1" />
                                    <h6>Venture</h6>
                                  </div>
                                  <div className="flex justify-start items-center mb-2">
                                    <img src={friend} alt="" className="w-4 mr-1" />
                                    <h6>Venture</h6>
                                  </div>
                                  <div className="flex justify-start items-center mb-2">
                                    <img src={friend} alt="" className="w-4 mr-1" />
                                    <h6>Venture</h6>
                                  </div>
                                  <div className="flex justify-start items-center">
                                    <img src={friend} alt="" className="w-4 mr-1" />
                                    <h6>Venture</h6>
                                  </div>
                                </div>
                                <BsArrowBarDown className="ml-1" />
                              </div>
                            </div>

                            {/* Industry */}
                            <div className="flex justify-start ml-3">
                              <div className="font-medium uppercase mr-2">Industry:</div>

                              <div className=" justify-start items-center cursor-pointer inline-flex relative w-fit group">
                                <div>category</div>
                                <div className="absolute w-auto top-6 left-0 p-2 min-w-max rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-top group-hover:scale-100">
                                  <div className="flex justify-start items-center mb-2">
                                    <img src={checkClick} alt="" className="w-4 mr-1" />
                                    <h6>Venture</h6>
                                  </div>
                                  <div className="flex justify-start items-center mb-2">
                                    <img src={friend} alt="" className="w-4 mr-1" />
                                    <h6>Venture</h6>
                                  </div>
                                  <div className="flex justify-start items-center mb-2">
                                    <img src={friend} alt="" className="w-4 mr-1" />
                                    <h6>Venture</h6>
                                  </div>
                                  <div className="flex justify-start items-center mb-2">
                                    <img src={friend} alt="" className="w-4 mr-1" />
                                    <h6>Venture</h6>
                                  </div>
                                  <div className="flex justify-start items-center">
                                    <img src={friend} alt="" className="w-4 mr-1" />
                                    <h6>Venture</h6>
                                  </div>
                                </div>
                                <BsArrowBarDown className="ml-1" />
                              </div>
                            </div>
                          </div>

                          {/* Verification */}

                          {user?.verified ? (
                            <div className="flex justify-center items-center bg-[#05CC03] text-white text-center my-3  py-3 font-semibold lg:bg-[#fff0] lg:text-[#05CC03] lg:text-xl lg:semi-bold lg:my-0 lg:py-0 lg:text-left lg:mt-0 mt-3">
                              {/* <div className="flex justify-center items-center bg-[#0C0] text-white text-center my-3  py-3 font-semibold lg:bg-[#fff0] lg:text-[#0C0] lg:text-xl lg:semi-bold lg:my-0 lg:py-0 lg:text-left lg:mt-0 mt-3"> */}
                              <div className="text-white lg:text-[#05CC03] pr-1 text-2xl">
                                <AiOutlineCheckCircle />
                              </div>
                              ID Verified
                            </div>
                          ) : (
                            <div className="flex justify-center items-center bg-[#dc3545] text-white text-center my-3  py-3 font-semibold lg:bg-[#fff0] lg:text-[#dc3545] lg:text-xl lg:semi-bold lg:my-0 lg:py-0 lg:text-left lg:mt-0 mt-3">
                              {/* <div className="flex justify-center items-center bg-[#0C0] text-white text-center my-3  py-3 font-semibold lg:bg-[#fff0] lg:text-[#0C0] lg:text-xl lg:semi-bold lg:my-0 lg:py-0 lg:text-left lg:mt-0 mt-3"> */}
                              <div className="text-white lg:text-[#dc3545] pr-1 text-2xl">
                                <RxCrossCircled />
                              </div>
                              ID Not Verified
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {openToggle === `${user?._id} image` ? (
                    <div>
                      {user?.imagesMedia && user?.imagesMedia.length > 0 && (
                        <div>
                          {user?.imagesMedia.map((image) => {
                            return (
                              <div className="inline-flex w-40 m-4">
                                <img
                                  src={image.secure_url}
                                  alt=""
                                  className={`mx-auto shadow-xl cursor-pointer ${
                                    expandedImage === image ? 'w-full' : 'w-full h-40 object-cover'
                                  }`}
                                  onClick={() => handleImageClick(image)}
                                  // Add styles for hover effect
                                  style={{
                                    transition: 'opacity 0.3s ease',
                                    opacity: 1,
                                    border: '2px solid transparent',
                                    boxShadow: '0 0 3px rgba(0, 0, 0, 0.2)',
                                  }}
                                  // Add styles for hover effect
                                  onMouseEnter={(e) => {
                                    e.target.style.opacity = 0.8;
                                    e.target.style.border = '2px solid gray';
                                    e.target.style.boxShadow = '0 0 6px rgba(0, 0, 0, 0.4)';
                                  }}
                                  onMouseLeave={(e) => {
                                    e.target.style.opacity = 1;
                                    e.target.style.border = '2px solid transparent';
                                    e.target.style.boxShadow = '0 0 3px rgba(0, 0, 0, 0.2)';
                                  }}
                                />
                              </div>
                            );
                          })}

                          {expandedImage && (
                            <div
                              className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-black bg-opacity-75"
                              onClick={handleImageClose}
                            >
                              <div className="relative">
                                <button
                                  onClick={handleImageClose}
                                  className="absolute top-0 right-0 m-4 text-white font-bold text-xl cursor-pointer"
                                >
                                  X
                                </button>
                                <img
                                  src={
                                    expandedImage.secure_url
                                      ? expandedImage.secure_url
                                      : expandedImage
                                  }
                                  className="w-11/12 h-11/12 mx-auto p-10"
                                  alt=""
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ) : null}

                  {openToggle === `${user?._id} video` ? (
                    <div>
                      {user?.videosMedia && user?.videosMedia.length > 0 && (
                        <div>
                          <div className="">
                            <iframe
                              title="pitch"
                              className="mx-auto w-full h-[500px] p-10"
                              src={videoUrlInDb}
                            ></iframe>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : null}

                  {openToggle === `${user?._id} note` ? (
                    <div>
                      {' '}
                      <form className="m-3">
                        <div className="border-solid border-2 mb-3 px-3 pt-3">
                          <div className="bg-[#D8E1E5] p-4 mb-3 rounded-xl">
                            <p>
                              <span className="uppercase">
                                ADD PRIVATE NOTES, IMAGES & FILES "(.PNG, .JPG, .JPEG, .GIF, .BMP,
                                .PDF, .TXT)"
                              </span>
                            </p>
                            <p>
                              Only you can see this! Save private notes and details about your
                              conversation and interaction with this profile member! You can
                              download and share your uploads with other members of your team.
                            </p>
                          </div>
                          {!isLoading && notes.length && (
                            <>
                              {userNotes.map((noteObj) => {
                                if (noteObj.email === user?.email) {
                                  return noteObj.notesArray.map((note, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="flex justify-between items-center  bg-[#d8e1e551] py-1 px-4 rounded mb-3"
                                      >
                                        <div className="flex justify-between items-center w-full">
                                          <p className="pr-8">{note.note}</p>

                                          <p className="text-sm italic text-[#6e6e6e] mr-3 min-w-max">
                                            {new Date(note.createdAt)
                                              .toString()
                                              .slice(
                                                0,
                                                new Date(note.createdAt).toString().lastIndexOf('G')
                                              )}
                                          </p>
                                          <p className="text-sm italic text-[#6e6e6e] mr-3 min-w-max">
                                            {note.createdAt}
                                          </p>
                                          <p>
                                            {new Date(note.createdAt).toLocaleString('en-US', {
                                              month: '2-digit',
                                              day: '2-digit',
                                              year: 'numeric',
                                              hour: 'numeric',
                                              minute: 'numeric',
                                              second: 'numeric',
                                              hour12: true,
                                            })}
                                          </p>

                                          {/* <p>{new Date(note.createdAt).toLocaleString('en-US', {month: '2-digit', day: '2-digit', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true})}</p> */}
                                        </div>
                                        <button
                                          onClick={(e) => deleteNote(e, user?.email, note._id)}
                                          className="text-red-500"
                                        >
                                          <RiDeleteBin6Line />
                                        </button>
                                      </div>
                                    );
                                  });
                                }
                              })}
                            </>
                          )}
                        </div>

                        <textarea
                          className="border-dashed border-2 w-full p-4"
                          name="note"
                          cols="30"
                          rows="10"
                          placeholder="Add your Private Notes here or Drag and Drop your files or images to upload!"
                          value={note[user?.email] ? note[user?.email] : ''}
                          onChange={(e) => addNote(e, user?.email)}
                        ></textarea>
                        <div className="flex justify-between ">
                          <button className="bg-[#337ab7] px-3 py-2 rounded mt-3 text-white text-xs">
                            Upload Files
                          </button>
                          <div>
                            <button
                              className="bg-[#dc3545] px-3 py-2 rounded mt-3 text-white text-xs mr-3 transition duration-300 ease-in-out hover:bg-[#b22b38] hover:shadow-sm"
                              type="button"
                              onClick={() => setOpenToggle(openToggle === 3 ? null : 3)}
                            >
                              Close
                            </button>
                            <button
                              className="bg-[#28a745] px-3 py-2 rounded mt-3 text-white text-xs transition duration-300 ease-in-out hover:bg-[#1f8236]  hover:shadow-sm"
                              onClick={(e) => {
                                saveNote(e, user?.email);
                              }}
                            >
                              Save Note
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  ) : null}

                  {/* End of video app */}
                  {/* Start of picture app */}

                  <div className="flex flex-wrap justify-center pb-3 lg:justify-start lg:mt-2 px-3">
                    <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                      <AiTwotoneTag />
                      <p className="ml-1">{user?.network}</p>
                    </button>
                    {/* <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                      <AiTwotoneTag />
                      <p className="ml-1">IT Consulting</p>
                    </button>
                    <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                      <AiTwotoneTag />
                      <p className="ml-1">IT Consulting</p>
                    </button>
                    <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                      <AiTwotoneTag />
                      <p className="ml-1">IT Consulting</p>
                    </button>
                    <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                      <AiTwotoneTag />
                      <p className="ml-1">IT Consulting</p>
                    </button>
                    <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                      <AiTwotoneTag />
                      <p className="ml-1">IT Consulting</p>
                    </button>
                    <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                      <AiTwotoneTag />
                      <p className="ml-1">IT Consulting</p>
                    </button>
                    <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                      <AiTwotoneTag />
                      <p className="ml-1">IT Consulting</p>
                    </button>
                    <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                      <AiTwotoneTag />
                      <p className="ml-1">IT Consulting</p>
                    </button>
                    <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                      <AiTwotoneTag />
                      <p className="ml-1">IT Consulting</p>
                    </button>
                    <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                      <AiTwotoneTag />
                      <p className="ml-1">IT Consulting</p>
                    </button>
                    <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                      <AiTwotoneTag />
                      <p className="ml-1">IT Consulting</p>
                    </button> */}
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </>
  );
};

export default UserCard;
