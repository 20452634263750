import React from 'react';

import { GoSignOut } from 'react-icons/go';

const topbar = () => {
  function logout() {
    localStorage.removeItem('auth');
    localStorage.clear();
    window.location.href = '/';
  }

  return (
    <>
      <div className=" w-full h-[80px] bg-[url('https://i.imgur.com/6ZyzH0D.png')] drop-shadow-lg  ">
        <div className=" flex justify-between items-center  w-full h-full max-w-[1240px] m-auto px-7 ">
          <div className="flex items-center text-white ">
            <h1 className="text-3xl font-bold mr-4 sm:text-4xl ">FARGOHUB.</h1>
          </div>
          <div>
            <button onClick={logout}>
              <GoSignOut className="text-3xl text-white" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default topbar;
