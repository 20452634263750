import React, { useState } from 'react';
import { FiUsers } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { AiOutlineDashboard } from 'react-icons/ai';
import { AiOutlineEye } from 'react-icons/ai';
import { TbUserCheck } from 'react-icons/tb';
import { MdOutlineVerified } from 'react-icons/md';
import { BiSupport } from 'react-icons/bi';
import Pagination from '../../components/Pagination';
import axios from 'axios';

// in the parameters, we're passing 2 agruments, bc we want to use them here
const Verify = ({ verifications, loading, setVerifications }) => {
  const [isActionOpen, setIsActionOpen] = useState(false);
  // then return all the post titles
  const token = localStorage.getItem('token');

  const approveOrReject = async (verificationId, status) => {
    try {
      let response = await axios.post(
        'https://fargo-server.herokuapp.com/admin/verify',
        { verificationId, status },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        setVerifications((prevstate) => {
          let newVerifications = prevstate.filter((verification) => {
            return verification._id !== verificationId;
          });
          console.log('newVerifications', newVerifications);
          return [...newVerifications];
        });
      }
    } catch (error) {
      console.log('error approving or rejecting verification', error);
    }
  };

  return (
    <div className="border-4 border-red-500 w-full bg-[#F0F3F6] 	">
      <div className="m-10 ">
        <h3 className="text-2xl font-medium pb-3 border-b-2 ">Tickets</h3>
        <div className="  border-4 border-green-500">
          {/* Table */}
          <div className="border-4 border-blue-500 mt-4 shadow-2xl rounded-md">
            <div
              className="flex
    justify-between items-align p-6 bg-[#F5F5F5] "
            >
              <h4 className="text-2xl">Untreated</h4>
              <button className="text-white bg-[#ffc107] px-3 rounded-sm ">Search</button>
            </div>
            <div class="border-4 border-yellow-500 w-fulltable-responsive">
              <table class="table table-striped w-full overflow-auto">
                <thead>
                  <tr>
                    <th className="bg-blue-100 border py-4" scope="col">
                      Date Created
                    </th>
                    <th className="bg-blue-100 border py-4 hidden md:table-cell" scope="col">
                      Name
                    </th>
                    <th className="bg-blue-100 border py-4 hidden md:table-cell" scope="col">
                      Status
                    </th>
                    <th className="bg-blue-100 border py-4" scope="col">
                      Action
                    </th>
                  </tr>
                </thead>

                {loading ? (
                  <tbody>
                    <tr>
                      <td colSpan={5}>
                        <div class="my-32   flex flex-col items-center justify-center">
                          <div class="absolute w-12 h-12 rounded-full border-8 border-solid border-gray-200"></div>
                          <div class="w-12 h-12 rounded-full animate-spin border-8 border-solid border-purple-500 border-t-transparent shadow-md"></div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  verifications.map((verify, index) => {
                    return (
                      <>
                        <tbody className="border-2">
                          <tr>
                            {/* <th className="border px-4 py-2 hidden md:table-cell" scope="row">{verify.sentAt}</th> */}
                            <th className="border px-4 py-2 hidden md:table-cell" scope="row">
                              {verify.sentAt}
                            </th>
                            <td className="border px-4 py-2 ">{verify.userEmail}</td>

                            <td className="border px-4 py-2 hidden md:table-cell">
                              {verify.status}
                            </td>
                            <td className="border px-4 py-2 text-center">
                              <button
                                className="bg-[#007bff] text-white p-2.5 rounded"
                                onClick={() =>
                                  setIsActionOpen((prevstate) => {
                                    return prevstate === verify._id ? '' : verify._id;
                                  })
                                }
                              >
                                <AiOutlineEye />
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td
                              colSpan="5"
                              // className={isActionOpen===verify._id ? 'visible' : 'hidden'}
                              className="visible"
                            >
                              <div className="border-2 border-blue-500  md:flex md:justify-start">
                                <div className="border border-red-500 w-4/12">
                                  <div>
                                    <div className="flex justify-between items-center  ">
                                      <p>Date</p>
                                      <p>Jan 23, 2023</p>
                                    </div>
                                    <div className="flex justify-between items-center  ">
                                      <p>Username</p>
                                      <p>Username123</p>
                                    </div>
                                    <div className="flex justify-between items-center  ">
                                      <p>Status</p>
                                      <p>Jan 23, 2023</p>
                                    </div>
                                    <div>
                                      {/* <select className='w-full' name="" id="" on>
              <option value="">Select</option>
              <option value="">Approve</option>
              <option value="">Reject</option>
              </select> */}
                                      <button
                                        onClick={() => {
                                          approveOrReject(verify._id, 'approved');
                                        }}
                                      >
                                        Approve
                                      </button>
                                      <button
                                        onClick={() => {
                                          approveOrReject(verify._id, 'rejected');
                                        }}
                                      >
                                        Reject
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="w-full border-2 border-green-500 ml-4">
                                  <div className="">
                                    <div className="flex justify-between items-center bg-[#a8a8a8] p-4">
                                      <div>Address Verification</div>
                                      <div className="flex justify-end">
                                        <button className="mr-2">More info</button>
                                        <button>Expand</button>
                                      </div>
                                    </div>
                                    <div className="p-4 ">
                                      {/* <img src="https://cdn.vertex42.com/ExcelTemplates/Images/excel-invoice-template.png" alt="" className='w-10/12 mx-auto'/> */}
                                      <img src={verify.image.secureUrl} alt="test" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </>
                    );
                  })
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verify;
