import React from 'react';
import Topbar from '../../layouts/topbar';
import Menu from '../../layouts/menu';
import Sidebar from '../../layouts/sidebar';
import Setting from './Setting1';
import SettingsDemo from './SettingsDemo';

const Settings = () => {
  return (
    <>
      <Topbar />
      <Menu />
      <div className="mt-8 lg:flex lg:justify-start max-w-[1240px] mx-auto px-7">
        <Sidebar />
        {/* <Setting /> */}
        <SettingsDemo />
      </div>
    </>
  );
};

export default Settings;
