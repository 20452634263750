import axios from 'axios';
import React, { useEffect, useState } from 'react';
import UserCard from '../../components/Cards/UserCard';

// age = 50//in local storage
// localStorage.setItem('age',50)
// localStorage.getItem('age')//return 50

const Ads = () => {
  const [user, setUser] = useState({});
  const token = localStorage.getItem('token');
  useEffect(() => {
    async function requestsReceived() {
      try {
        const response = await axios.get('https://fargo-server.herokuapp.com/profile', {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log('response', response.data.usersInfo);
        setUser(response.data.usersInfo);
      } catch (error) {
        console.log(error);
      }
    }
    requestsReceived();
  }, []);

  return (
    <>
      {Object.keys(user).length && (
        <div>
          <UserCard
            showFriendIcon={false}
            showFavoriteIcon={false}
            showNoteIcon={false}
            showContactButtons={false}
            showEditIcon={true}
            hideAbbreviateName={false}
            users={[user]}
            user={{}}
          />
        </div>
      )}
    </>
  );
};

export default Ads;
