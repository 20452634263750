import React from 'react';
import { Link } from 'react-router-dom';

const PaginationUsers = ({ usersPerPage, totalUsers, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalUsers / usersPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <nav>
      <ul className="flex">
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`border hover:bg-gray-300 p-2 ${
              currentPage === number ? 'bg-purple-500' : ''
            }`}
          >
            <Link onClick={() => paginate(number)} to={'#'}>
              {number}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default PaginationUsers;
