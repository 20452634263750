import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './pages/Dashboard/Dashboard';
import Homepage from './pages/Homepage/Homepage';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import RegisterNetwork from './pages/Register/RegisterNetwork';
import RegisterPreference from './pages/Register/RegisterPreference';
import RegisterVerify from './pages/Register/RegisterVerify';
import Signin from './pages/Register/Signin';
import ViewAd from './pages/ViewAd/ViewAd';
import FriendRequest from './pages/FriendRequest/FriendRequest';
import MyFavorites from './pages/Favorites/MyFavorites';
import Friends from './pages/Friends/Friends';
import Notes from './pages/Notes/Notes';
import Support from './pages/Support/Support';
import Settings from './pages/Settings/Settings';
import Verification from './pages/Verification/Verification';
import AdminLogin from './admin/pages/Login/Login';
import AdminDashboard from './admin/pages/Dashboard/Dashboard';
import AdminSupport from './admin/pages/Support/Support';
import AdminVerify from './admin/pages/Verification/Verfication';
import AdminUsers from './admin/pages/Users/Users';
import Inbox from './pages/Inbox/Inbox';
import NotFound from './pages/404/404';
import DemoInbox from './pages/demoInbox/DemoPage';
import Upgrade from './pages/Upgrade/Upgrade';
import PurchasePage from './pages/Upgrade/Purchase/PurchasePage';
import Approvals from './admin/approvals/Approvals';
import Reset from './pages/Login/ResetPassword';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/register" element={<Register />} />
        <Route path="/preference" element={<RegisterPreference />} />
        <Route path="/network" element={<RegisterNetwork />} />
        <Route path="/verify" element={<RegisterVerify />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset" element={<Reset />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/viewad" element={<ViewAd />} />
        <Route path="/requests" element={<FriendRequest />} />
        <Route path="/friends" element={<Friends />} />
        <Route path="/favorites" element={<MyFavorites />} />
        <Route path="/notes" element={<Notes />} />
        <Route path="/support" element={<Support />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/idverify" element={<Verification />} />
        <Route path="/inbox" element={<Inbox />} />
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/admin/dashboard" element={<AdminDashboard />} />
        <Route path="/admin/support" element={<AdminSupport />} />
        <Route path="/admin/verification" element={<AdminVerify />} />
        <Route path="/admin/users" element={<AdminUsers />} />
        {/* <Route path="/admin/new-signups" element={<AdminUsers />} /> */}
        <Route path="/404" element={<NotFound />} />
        <Route path="/demoInbox" element={<DemoInbox />} />
        <Route path="/upgrade" element={<Upgrade />} />
        <Route path="/purchase/:subId" element={<PurchasePage />} />
        <Route path="/approvals" element={<Approvals />} />
      </Routes>
    </Router>
  );
}

export default App;
