import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Sidebar from '../pages/Support/Sidebar';
import { AiOutlineSearch, AiOutlineCloudDownload } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const Approvals = () => {
  const [approvals, setApprovals] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('token');
  const [editedUploads, setEditedUploads] = useState({});

  useEffect(() => {
    const getApprovals = async () => {
      try {
        setLoading(true);
        const response = await axios.get('https://fargo-server.herokuapp.com/approvals', {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log(response.data);
        setApprovals(response.data);

        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    getApprovals();
  }, []);

  const onInputUpdate = (e, clientId) => {
    let newEdition = { ...editedUploads };

    if (newEdition[clientId]) {
      newEdition[clientId][e.target.name] = e.target.value;
    } else {
      newEdition[clientId] = {};
      newEdition[clientId][e.target.name] = e.target.value;
    }

    setEditedUploads(newEdition);
  };
  console.log(editedUploads);
  return (
    <div className="flex">
      <Sidebar />

      {/* rest of the website */}

      {/* rest of the website */}
      <div className="border-4 border-red-500 w-full bg-[#F0F3F6] 	">
        <div className="m-10">
          <h3 className="text-2xl font-medium pb-3 border-b-2">User List</h3>
          <div className="w-full   ">
            {/* newHeader */}
            <div className="overflow-x-auto relative  bg-[#e2e2e2] rounded-t-md py-3 mt-3">
              <div className="md:flex md:justify-between md:items-center px-3">
                <ul className="md:flex md:justify-center text-sm font-semibold">
                  <li className="cursor-pointer  mr-2  hover:text-[#7f67ab]">All Users(265)</li>
                  <li className=" cursor-pointer mr-2 hover:text-[#7f67ab]">New Signups(11)</li>
                  <Link to="/approvals">
                    <li className=" cursor-pointer  mr-2  hover:text-[#7f67ab]">
                      Updates & Approvals(0)
                    </li>
                  </Link>
                  <li className=" cursor-pointer hover:text-[#7f67ab]">Bronze Emails(0)</li>
                </ul>
                <div className=" flex justify-end">
                  <button className="bg-[#28a745] px-3 py-2 rounded  text-white text-sm  hover:bg-[#208838] flex justify-start items-center mr-2">
                    <AiOutlineCloudDownload size={20} className="mr-2" />
                    CSV Export
                  </button>
                  <button className="bg-[#ffc107] px-3 py-2 rounded  text-white text-sm  hover:bg-[#D9A100] flex justify-start items-center">
                    <AiOutlineSearch size={20} className="mr-2" />
                    Search
                  </button>
                </div>
              </div>
            </div>

            <div className="border-4 border-blue-500 shadow-2xl rounded-md">
              <div className="flex justify-between items-center px-2 py-2 bg-[#F5F5F5] ">
                <div>
                  Show{' '}
                  <button
                    id="dropdownDefaultButton"
                    data-dropdown-toggle="dropdown"
                    class="px-1 py-0.5 text-center inline-flex items-center border"
                    type="button"
                  >
                    100{' '}
                    <svg
                      class="w-4 h-4 ml-2"
                      aria-hidden="true"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </button>
                  <div
                    id="dropdown"
                    class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
                  >
                    <ul
                      class="py-2 text-sm text-gray-700 dark:text-gray-200"
                      aria-labelledby="dropdownDefaultButton"
                    >
                      <li>
                        <Link
                          to="#"
                          class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          Dashboard
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          Settings
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          Earnings
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          Sign out
                        </Link>
                      </li>
                    </ul>
                  </div>{' '}
                  entries
                </div>
                <input
                  type="text"
                  id="table-search"
                  // className="block p-2 pl-10 w-80  text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-2"
                  className=" p-2 pl-2 w-full md:w-64  text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                  placeholder="Search for users"
                />
              </div>
              <div class="border-4 border-yellow-500 w-fulltable-responsive">
                <table class="table table-striped w-full overflow-auto">
                  <thead>
                    <tr className="text-sm">
                      <th className="border py-4 font-medium" scope="col">
                        Name
                      </th>
                      <th className="border py-4 font-medium hidden md:table-cell" scope="col">
                        Email
                      </th>
                      <th className="border py-4 font-medium hidden md:table-cell" scope="col">
                        Member Type
                      </th>
                      <th className="border py-4 font-medium hidden md:table-cell" scope="col">
                        Subscription
                      </th>
                      <th className="border py-4 font-medium" scope="col">
                        Action
                      </th>
                    </tr>
                  </thead>

                  {!loading && approvals.length && (
                    <tbody>
                      {approvals.map((approval) => {
                        return Object.keys(JSON.parse(approval.profileUpdate)).map((elm) => {
                          console.log(JSON.parse(approval.profileUpdate)[elm]);
                          return (
                            <tr className="text-sm">
                              <td className="border py-4">
                                {elm}: {JSON.parse(approval.currentUpdates)[elm]}
                              </td>
                              <td className="border py-4">
                                <input
                                  type={'text'}
                                  name={elm}
                                  value={
                                    editedUploads[approval.clientId]
                                      ? editedUploads[approval.clientId][elm]
                                        ? editedUploads[approval.clientId][elm]
                                        : JSON.parse(approval.profileUpdate)[elm]
                                      : JSON.parse(approval.profileUpdate)[elm]
                                  }
                                  onChange={(e) => onInputUpdate(e, approval.clientId)}
                                />
                              </td>
                            </tr>
                          );
                        });
                      })}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Approvals;
