import React from 'react'
import Topbar from '../../layouts/topbar'
import Menu from '../../layouts/menu'
import Sidebar from '../../layouts/sidebar'
import Messages from './Messages'


const Inbox = () => {
  return (
    <> 
    <Topbar />
    <Menu />
    <div className="mt-8 max-w-[1240px] mx-auto px-7">
      <Messages />
    </div>
  </>
  )
}

export default Inbox
 