import React, { useState, useEffect, useCallback } from 'react';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import {
  AiTwotoneEdit,
  AiFillEye,
  AiOutlineIdcard,
  AiOutlineCheckCircle,
  AiTwotonePushpin,
  AiTwotoneTag,
  AiOutlineInfoCircle,
  AiTwotoneBulb,
  AiOutlineSafety,
} from 'react-icons/ai';
import { BiCopy, BiWallet } from 'react-icons/bi';
import { BsArrowBarDown } from 'react-icons/bs';
import { GrMail } from 'react-icons/gr';
import { FiUserCheck } from 'react-icons/fi';
import { MdOutlineVerified } from 'react-icons/md';
import { CgArrowLongRight } from 'react-icons/cg';

import addUser from '../../components/Icons/addUser.png';
import addUserColor from '../../components/Icons/addUserColor.png';
import heart from '../../components/Icons/heart.png';
import heartColor from '../../components/Icons/heartColor.png';
import note from '../../components/Icons/note.png';
import noteColor from '../../components/Icons/noteColor.png';
import picture from '../../components/Icons/picture.png';
import pictureColor from '../../components/Icons/pictureColor.png';
import video from '../../components/Icons/video.png';
import videoColor from '../../components/Icons/videoColor.png';
import check from '../../components/Icons/check.png';
import checkColor from '../../components/Icons/checkColor.png';
import { Link } from 'react-router-dom';
import axios from 'axios';

const networkKeys = {
  startups: 'Startups & Funding Needed',
  business: 'Business & Private Investors',
  incubators: 'Incubators',
  accelerators: 'Accelerators',
};
const networkColorKeys = {
  startups: 'yellow',
  business: 'purple',
  incubators: 'red',
  accelerators: 'green',
};
// test
const industryOptions = [
  { value: 'Aerospace', label: 'Aerospace' },
  { value: 'Agriculture', label: 'Agriculture' },
  { value: 'Biotechnology', label: 'Biotechnology' },
  { value: 'Business Products', label: 'Business Products' },
  { value: 'Business Services', label: 'Business Services' },
  { value: 'Chemicals and Chemical Products', label: 'Chemicals and Chemical Products' },
  { value: 'Clean Technology', label: 'Clean Technology' },
  { value: 'Computers and Peripheral', label: 'Computers and Peripherals' },
  { value: 'Construction', label: 'Construction' },
  { value: 'Consulting', label: 'Consulting' },
  { value: 'Consumer Products', label: 'Consumer Products' },
  { value: 'Consumer Services', label: 'Consumer Services' },
  { value: 'Digital Marketing', label: 'Digital Marketing' },
  { value: 'Education', label: 'Education' },
  { value: 'Electronics / Instrumentation', label: 'Electronics / Instrumentation' },
  { value: 'Fashion', label: 'Fashion' },
  { value: 'Financial Services', label: 'Financial Services' },
  { value: 'Fintech', label: 'Fintech' },
  { value: 'Food and Beverage', label: 'Food and Beverage' },
  { value: 'Gaming', label: 'Gaming' },
  { value: 'Healthcare Services', label: 'Healthcare Services' },
  { value: 'Industrial/Energy', label: 'Industrial/Energy' },
  { value: 'Internet / Web Services', label: 'Internet / Web Services' },
  { value: 'IT Services', label: 'IT Services' },
  { value: 'Legal', label: 'Legal' },
  { value: 'Lifestyle', label: 'Lifestyle' },
  { value: 'Marine', label: 'Marine' },
  { value: 'Maritime/Shipping', label: 'Maritime/Shipping' },
  { value: 'Media', label: 'Media' },
  { value: 'Medical Devices', label: 'Medical Devices' },
  { value: 'Mining', label: 'Mining' },
  { value: 'Mobile', label: 'Mobile' },
  { value: 'Non-Profit', label: 'Non-Profit' },
  { value: 'Oil and Gas', label: 'Oil and Gas' },
  { value: 'Pharmaceuticals', label: 'Pharmaceuticals' },
  { value: 'Real Estate', label: 'Real Estate' },
  { value: 'Retail', label: 'Retail' },
  { value: 'Security', label: 'Security' },
  { value: 'Software', label: 'Software' },
  { value: 'Sports', label: 'Sports' },
  { value: 'Telecommunications', label: 'Telecommunications' },
  { value: 'Transportation', label: 'Transportation' },
  { value: 'Travel', label: 'Travel' },
  { value: 'Utilities', label: 'Utilities' },
  { value: 'Venture Capital', label: 'Venture Capital' },
  { value: 'Other', label: 'Other' },
];

const User = ({ user, loading, setUsers, users, refreshUsers = () => {} }) => {
  const [isShowFull, setIsShowFull] = useState(false);
  const [openToggle, setOpenToggle] = useState(null);
  const [openProfile, setOpenProfile] = useState('');
  const [openEdits, setOpenEdits] = useState('');
  const [openBronze, setOpenBronze] = useState('');
  const [deleteUser, setDeleteUser] = useState('');
  const [editBronzeMessage, setEditBronzeMessage] = useState(false);
  const [bronzeMessage, setBronzeMessage] = useState('');
  const [bronzeMessageStore, setBronzeMessageStore] = useState({});
  const [bronzeMessages, setBronzeMessages] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [currentMessageBeingEdited, setMessageBeingEdited] = useState('');
  const [network, setNetwork] = useState('');
  const [userUpdatesApprovals, setUserUpdatesApprovals] = useState({});
  const [openBiCopyByUserId, setOpenBiCopyByUserId] = useState({});
  const [toggleOpenAiTwotoneEditById, setToggleOpenAiTwotoneEditById] = useState({});
  // const [approvalsState, setApprovalsState] = useState({});
  const token = localStorage.getItem('token');

  let paragraphText =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';

  const toggleOpenBiCopy = (userId) => {
    setOpenBiCopyByUserId((prev) => ({ ...prev, [userId]: !prev[userId] }));
  };
  const toggleOpenAiTwotoneEdit = (userId) => {
    setToggleOpenAiTwotoneEditById((prev) => ({ ...prev, [userId]: !prev[userId] }));
  };
  const getBronzeMessages = useCallback(async () => {
    try {
      setLoading(true);

      const response = await axios.get('https://fargo-server.herokuapp.com/all-bronze', {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log('allBronzeMessages', response);
      setBronzeMessages(response.data.allBronzeMessages);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [setBronzeMessages, setLoading]);
  const getUserApprovals = useCallback(async () => {
    try {
      const response = await axios.get('https://fargo-server.herokuapp.com/approvals');
      console.log('response', response);

      const approvalsById = response.data?.reduce((acc, approval) =>
        Object.assign(acc, {
          [approval.clientId]: { ...approval, editingStarted: false },
        })
      );
      // let approvalsById = {};
      // response.data.map((approval) => {
      //   approvalsById[approval.clientId] = { ...approval, editingStarted: false };
      // });

      console.log('aorrvals', approvalsById);
      setUserUpdatesApprovals(approvalsById);
    } catch (error) {
      console.log(error);
    }
  }, [setUserUpdatesApprovals]);

  useEffect(() => {
    getBronzeMessages();
    getUserApprovals();
  }, [getUserApprovals, getBronzeMessages]);

  const verifyUser = async (userId) => {
    try {
      const response = await axios.post(
        'https://fargo-server.herokuapp.com/verify',
        { userId },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.status === 200) {
        let newUnverified = users.filter((user) => {
          return user._id !== userId;
        });
        setUsers([...newUnverified]);
        return;
      }
    } catch (error) {
      console.log('error verifing user', error);
    }
  };

  const approveMessage = async (messageId, elmMessage, conversationId, index) => {
    try {
      const response = await axios.post(
        'https://fargo-server.herokuapp.com/admin/message',
        {
          messageId,
          message: bronzeMessage ? bronzeMessage : elmMessage,
          conversationId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log('response', response.data);

      if (response.data === 'approved all') {
        const updatedMessages = bronzeMessages.filter((message) => {
          return message._id !== messageId;
        });
        setBronzeMessages(updatedMessages);
        return;
      }
      let newMessages = [...bronzeMessages];
      newMessages[index] = response.data;
      setBronzeMessages(newMessages);
    } catch (error) {
      console.log(error);
    }
  };

  const rejectMessage = async () => {
    try {
      console.log('reject message');
    } catch (error) {
      console.log(error);
    }
  };

  const setApprovalFieldValues = (field, value, userId) => {
    let userPrev = userUpdatesApprovals[userId];
    if (userPrev) {
      const updates = { ...userPrev, [field]: value };
      setUserUpdatesApprovals((prev) => ({
        ...prev,
        [userId]: updates,
        editingStarted: true,
      }));
    } else {
      setUserUpdatesApprovals((prev) => ({
        ...prev,
        [userId]: { [field]: value, editingStarted: true },
      }));
    }
  };

  const approveAndEditUpdates = async (userId) => {
    try {
      const updates = userUpdatesApprovals[userId] || {};
      delete updates._id;
      delete updates.editingStarted;
      delete updates.clientId;
      const response = await axios.post('https://fargo-server.herokuapp.com/adminProfile/approve', {
        clientId: userId,
        updates,
      });
      const newUpdateApprovals = { ...userUpdatesApprovals };
      delete newUpdateApprovals[userId];
      console.log('newUpdateApprovals', newUpdateApprovals);
      setUserUpdatesApprovals(newUpdateApprovals);
      refreshUsers();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {users.map((user) => {
        return (
          <tbody className="border-2">
            <tr>
              <th
                className=" px-2 font-normal text-sm text-gray-900 dark:text-white flex-col"
                scope="row"
              >
                <div className="flex justify-start items-center">
                  <FiUserCheck className="mr-1 text-green-600" />
                  {user.fullName}
                </div>
                <div className="flex justify-start items-center">
                  <AiOutlineIdcard className="mr-1 text-green-600" />
                  ID: 43094
                </div>
              </th>
              <td className="border px-2 py-1 font-normal text-sm text-gray-900 dark:text-white flex-col hidden md:table-cell">
                <div>{user.email}</div>
                <div>(324) 333 3333</div>
              </td>
              <td className="border px-2 py-1 font-normal text-sm text-gray-900 dark:text-white flex-col hidden md:table-cell">
                Startups & Funding Needed{' '}
              </td>
              <td className="border px-2 py-1 font-normal text-sm text-gray-900 dark:text-white flex-col hidden md:table-cell">
                <div>Corporate - $995.00</div>
                <div>No Expiry - Lifetime</div>
              </td>
              <td className="border px-2 py-1 text-center">
                <div className="flex justify-center">
                  <RiDeleteBin6Fill
                    className="bg-[#FF0100] text-white text-[28px] p-1.5 rounded-l hover:cursor-pointer"
                    onClick={() =>
                      setDeleteUser((prevstate) => {
                        return prevstate === user._id ? '' : user._id;
                      })
                    }
                  />
                  {/* Modal */}
                  {/* <div className={deleteUser===user._id  ? 'visible' : 'hidden'}> */}

                  <div className={deleteUser === user._id ? 'visible ' : 'hidden'}>
                    <div class="fixed top-0 left-0 right-0 bottom-0 z-50  opacity-75  flex flex-col items-center justify-center bg-[#00000077]">
                      {/* <h2 class="text-center text-white text-xl font-semibold">Loading...</h2> */}
                      <h2 class="text-center text-black">Are you sure?</h2>
                      <div>
                        <button className="mr-2">No</button>
                        <button>Yes</button>
                      </div>
                    </div>
                  </div>
                  <AiTwotoneEdit
                    className="bg-[#28A844] text-white text-[28px] p-1.5 hover:cursor-pointer"
                    onClick={() => {
                      setOpenToggle(openToggle === 1 ? null : 1);
                      toggleOpenAiTwotoneEdit(user._id);
                    }}
                  />
                  <AiFillEye className="bg-[#037AFE] text-white text-[28px] p-1.5 hover:cursor-pointer" />
                  <BiCopy
                    className="bg-[#FFC00C] text-white text-[28px] p-1.5 hover:cursor-pointer"
                    onClick={() => {
                      setOpenToggle(openToggle === 3 ? null : 3);
                      toggleOpenBiCopy(user._id);
                    }}
                    // onClick={() => setOpenProfile((prev) => !prev)}
                    // onClick={() =>
                    //   setOpenProfile((prevstate) => {
                    //     return prevstate === user._id ? "" : user._id;
                    //   })
                    // }
                    // onClick={viewProfile}
                  />
                  <div className="inline-flex relative w-fit">
                    <div className="absolute top-5 left-5 bottom-auto right-auto border rounded-full bg-black text-white text-xs h-4 w-4 flex justify-center items-center">
                      {bronzeMessages.map((messageObj) => {
                        if (messageObj.participants.includes(user._id)) {
                          return <p>{messageObj.conversations.length?.toString()}</p>;
                        } else {
                          return <p>0</p>;
                        }
                      })}
                    </div>
                    <GrMail
                      className="bg-[#16A2B8] text-white text-[28px] p-1.5 rounded-r hover:cursor-pointer"
                      //  onClick={() => setOpenBronze((prev) => !prev)}
                      // onClick={() =>
                      //   setOpenBronze((prevstate) => {
                      //     return prevstate === user._id ? "" : user._id;
                      //   })
                      // }

                      onClick={() => setOpenToggle(openToggle === 2 ? null : 2)}
                    />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              {openToggle === 3 && openBiCopyByUserId[user?._id] ? (
                <td
                  className="md:p-4 py-4 "
                  // className={
                  //   openProfile === user._id ? "visible p-10" : "hidden p-10"
                  // }
                  colspan="5"
                >
                  <div className=" bg-[#ffffff]  lg:m-0 lg:mb-6 lg:mx-0 rounded-tr-md rounded-tl-md mb-5 border border-[#bebebe]">
                    <div className="bg-[#ededed] rounded-tr rounded-tl px-2 py-2 flex justify-between items-center  ">
                      <div className="text-lg font-bold">Company Ad</div>

                      <button className="bg-[#16A2B8] text-white px-3 py-1.5 rounded hover:bg-[#138a9d]">
                        Edit
                      </button>
                    </div>

                    <div className="lg:flex lg:justify-start px-3 mt-8">
                      <div className="  ">
                        <p className="text-center text-sm text-[#969696]">PROFILE ID: 868678</p>
                        <div className="flex m-2 border w-32 h-36  lg:w-36 mx-auto">
                          <img
                            src={'https://i.imgur.com/1Ge7acx.png'}
                            alt=""
                            className="w-40 border p-2 mx-auto object-cover"
                          />
                        </div>
                        <div className="flex justify-center lg:justify-around">
                          <div>
                            <div className="relative cursor-pointer group mx-2 lg:mx-0">
                              <div className="relative">
                                <img src={addUser} alt="" className="w-5" />
                                <img
                                  src={addUserColor}
                                  alt=""
                                  className="	absolute top-0 left-0 hover:opacity-0 w-5"
                                />
                              </div>
                              <span className="absolute w-auto p-2 m-2 min-w-max bottom-5 right-[-42px] rounded-md shadow-md  text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100">
                                Friend Added
                              </span>
                            </div>
                            <img src={check} alt="" className="w-4 mx-auto mt-2" />
                          </div>

                          {/* Video Icon */}
                          <div className="">
                            {/* FIXME: WHEN I CLICK THE VIDEO OR IMAGE ICON FOR THE FIRST TIME, NOTHING HAPPENS. ONLY THE SECOND TIME, IT OPENS */}
                            <div
                              className="relative cursor-pointer group mx-2 lg:mx-0"
                              // onClick={() => setOpenVideos((prev) => !prev)}
                            >
                              <div
                                className="relative"
                                // onClick={viewVideo}
                              >
                                <img src={videoColor} alt="" className="w-5	" />
                                <img
                                  src={video}
                                  alt=""
                                  className="absolute top-0 left-0 hover:opacity-0 w-5"
                                />
                              </div>
                              <span className="absolute w-auto p-2 m-2 min-w-max bottom-5 right-[-30px] rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100">
                                No Videos Uploaded
                              </span>
                            </div>
                            <img src={check} alt="" className="w-4 mx-auto mt-2" />
                          </div>

                          <div className="">
                            <div
                              className="relative cursor-pointer group mx-2 lg:mx-0"
                              // onClick={() => setOpenImages((prev) => !prev)}
                            >
                              <div
                                className="relative"
                                // onClick={viewPicture}
                              >
                                <img src={pictureColor} alt="" className="w-5	" />
                                <img
                                  src={picture}
                                  alt=""
                                  className="	absolute top-0 left-0 hover:opacity-0 w-5"
                                />
                              </div>
                              <span className="absolute w-auto p-2 m-2 min-w-max bottom-5 right-[-30px] rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100">
                                No Images Uploaded
                              </span>
                            </div>
                            <img src={check} alt="" className="w-4 mx-auto mt-2" />
                          </div>

                          <div className="">
                            <div className="relative cursor-pointer group mx-2 lg:mx-0">
                              <div
                                className="relative"
                                // onClick={() => {
                                //   addToFav(id)
                                // }}
                              >
                                <img src={heartColor} alt="" className="w-5" />
                                <img
                                  src={heart}
                                  alt=""
                                  className="	absolute top-0 left-0 hover:opacity-0 w-5"
                                />
                              </div>
                              <span className="absolute w-auto p-2 m-2 min-w-max bottom-5 right-[-30px] rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100">
                                Add to Favorites
                              </span>
                            </div>
                            <img src={check} alt="" className="w-4 mx-auto mt-2" />
                          </div>

                          <div>
                            <div className="relative cursor-pointer group mx-2 lg:mx-0">
                              <div
                                className="relative"
                                // onClick={() => {
                                //   var x = document.getElementById(`note${index}`);
                                //   document.getElementById("picture").style.display = "none";
                                //   document.getElementById("video").style.display = "none";
                                //   x.style.display = "block"
                                // }}
                              >
                                <img src={noteColor} alt="" className="w-5" />
                                <img
                                  src={note}
                                  alt=""
                                  className="	absolute top-0 left-0 hover:opacity-0 w-5"
                                />
                              </div>
                              <span className="absolute w-auto p-2 m-2 min-w-max bottom-5 right-[-30px] rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-bottom group-hover:scale-100">
                                Add Private Notes
                              </span>
                            </div>
                            <img src={check} alt="" className="w-4 mx-auto mt-2" />
                          </div>
                        </div>
                      </div>
                      <div className="lg:ml-6 lg:w-full">
                        <div className="lg:flex lg:justify-between">
                          <div>
                            <div className="flex justify-center items-center text-center text-sm mt-2 lg:mt-0 text-[#969696] lg:flex lg:justify-start ">
                              <div className="pr-2 ">Name</div>
                              <AiTwotonePushpin />
                              <div className="pl-1">TEXAS, UNITED STATES</div>
                            </div>

                            <div className="lg:flex lg:justify-between">
                              <div className="flex  justify-center lg:flex lg:justify-start">
                                <button className="bg-[#f0ad4e] px-3 py-2 rounded mt-3 mr-2 text-white text-sm cursor-default	">
                                  STARTUP
                                </button>
                                <button className="bg-[#dc3545] px-3 py-2 rounded mt-3 text-white text-sm cursor-default	">
                                  FUNDING NEEDED 1212
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="flex  justify-center lg:flex lg:flex-col lg:w-32">
                            <button
                              className="bg-[#ffc107] px-3 py-2 rounded mt-3 mr-2 lg:m-0 text-white text-sm hover:bg-[#edb205]"
                              // onClick={() => sendMessage()}
                            >
                              Send Message
                            </button>
                            <button className="bg-[#007bff] px-3 py-2 rounded mt-3 text-white text-sm  hover:bg-[#0069d9]">
                              Update to View
                            </button>
                          </div>
                        </div>

                        {/* paragraph about the company */}
                        <div>
                          <div className="h-5 border-b-2 border-[#b4b4b4] text-center text-2xl "></div>

                          {isShowFull ? paragraphText : paragraphText?.substring(0, 200)}

                          <button
                            className="text-[#1075ce] font-bold"
                            onClick={() => {
                              setIsShowFull((prev) => !prev);
                            }}
                          >
                            {isShowFull ? '...view less' : '...view more'}
                          </button>

                          <button className="text-[#1075ce] font-bold"></button>
                          <div className="h-5 border-b-2 border-[#b4b4b4] text-center text-2xl "></div>

                          {/* <div className="flex justify-between items-center mt-3"> */}
                          {/* New Tab */}

                          <div className="lg:flex lg:justify-between lg:items-center mt-2">
                            {/* Type */}
                            <div className="flex justify-center">
                              <div className="flex justify-start mr-4">
                                <div className="font-medium uppercase mr-2">Type:</div>
                                <div>Startup</div>
                              </div>

                              {/* Industry */}
                              <div className="flex justify-start ml-3">
                                <div className="font-medium uppercase mr-2">Industry:</div>

                                <div className=" justify-start items-center cursor-pointer inline-flex relative w-fit group">
                                  <div>Category</div>
                                  <div className=""></div>
                                </div>
                              </div>
                            </div>

                            {/* Verification */}
                            <div className="flex justify-center items-center bg-[#05CC03] text-white text-center my-3  py-3 font-semibold lg:bg-[#fff0] lg:text-[#05CC03] lg:text-xl lg:semi-bold lg:my-0 lg:py-0 lg:text-left lg:mt-0 mt-3">
                              {/* <div className="flex justify-center items-center bg-[#0C0] text-white text-center my-3  py-3 font-semibold lg:bg-[#fff0] lg:text-[#0C0] lg:text-xl lg:semi-bold lg:my-0 lg:py-0 lg:text-left lg:mt-0 mt-3"> */}
                              <div className="text-white lg:text-[#05CC03] pr-1 text-2xl">
                                <AiOutlineCheckCircle />
                              </div>
                              <div className="text-white lg:text-[#05CC03]">ID VERIFIED</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-wrap justify-center pb-3 lg:justify-start lg:mt-2 px-3">
                      <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                        <AiTwotoneTag />
                        <p className="ml-1">IT Consulting</p>
                      </button>
                      <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                        <AiTwotoneTag />
                        <p className="ml-1">IT Consulting</p>
                      </button>
                      <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                        <AiTwotoneTag />
                        <p className="ml-1">IT Consulting</p>
                      </button>
                      <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                        <AiTwotoneTag />
                        <p className="ml-1">IT Consulting</p>
                      </button>
                      <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                        <AiTwotoneTag />
                        <p className="ml-1">IT Consulting</p>
                      </button>
                      <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                        <AiTwotoneTag />
                        <p className="ml-1">IT Consulting</p>
                      </button>
                      <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                        <AiTwotoneTag />
                        <p className="ml-1">IT Consulting</p>
                      </button>
                      <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                        <AiTwotoneTag />
                        <p className="ml-1">IT Consulting</p>
                      </button>
                      <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                        <AiTwotoneTag />
                        <p className="ml-1">IT Consulting</p>
                      </button>
                      <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                        <AiTwotoneTag />
                        <p className="ml-1">IT Consulting</p>
                      </button>
                      <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                        <AiTwotoneTag />
                        <p className="ml-1">IT Consulting</p>
                      </button>
                      <button className="flex justify-center items-center bg-[#17a2b8] text-white text-xs font-semibold pl-1 pr-2 py-0.5 rounded mr-1 mt-1">
                        <AiTwotoneTag />
                        <p className="ml-1">IT Consulting</p>
                      </button>
                    </div>
                  </div>
                </td>
              ) : null}

              {openToggle === 1 && toggleOpenAiTwotoneEditById[user?._id] ? (
                <td colspan="5">
                  <div className="w-full">
                    <div className=" my-4 md:mx-2  ">
                      {/* FIXME:
           menu navigation  */}
                      <div className="flex justify-left items-center bg-[#7867a7] text-white font-normal  text-lg py-2 pl-5 mb-5 lg:mx-0 rounded">
                        <AiTwotoneBulb className="mr-3 text-3xl" /> Account Settings
                      </div>

                      <div className=" flex justify-between mb-3">
                        <div className="text-3xl ">Profile</div>
                        <div className="flex justify-center">
                          <Link to="/idverify">
                            <button className="flex justify-center items-center border rounded-l py-1 px-2 hover:bg-[#565656] hover:text-white">
                              <MdOutlineVerified className="mr-1" />{' '}
                              <p className="text-sm">Verification</p>
                            </button>
                          </Link>
                          <Link to="/idverify">
                            <button className="flex justify-center items-center border rounded-r py-1 px-2 hover:bg-[#565656] hover:text-white">
                              <BiWallet className="mr-1" /> <p className="text-sm">Bill Settings</p>{' '}
                            </button>
                          </Link>
                        </div>
                      </div>

                      <div className="border p-1 md:p-4 ">
                        <div className="flex justify-start bg-[#CDE4FE] text-3xl text-[#014084] py-3 px-5 m-1 rounded">
                          <AiOutlineInfoCircle className="mr-1" />
                          <h3>Basic Info</h3>
                        </div>
                        <form
                        // onSubmit={updateAccount}
                        >
                          <div className="md:m-5 md:flex">
                            <div className="flex flex-col w-full mr-10">
                              <label htmlFor="" className="pb-2">
                                Full Name<span>*</span>
                              </label>
                              {/* the original value */}
                              <input
                                type="text"
                                name="name"
                                // value={name==="" ? user.fullName : name ==="null" ? "" : name}
                                // onChange={(e) => updateBasicData(e)}
                                value={
                                  userUpdatesApprovals[user?._id]?.editingStarted
                                    ? userUpdatesApprovals[user?._id]?.fullName
                                    : user.fullName
                                }
                                onChange={(e) =>
                                  setApprovalFieldValues('fullName', e.target.value, user._id)
                                }
                                placeholder="Johnny Sims"
                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                              />
                              {/* The value the user needs approved */}
                              {userUpdatesApprovals[user?._id]?.fullName && (
                                <input
                                  type="text"
                                  name="name"
                                  // value={name==="" ? user.fullName : name ==="null" ? "" : name}
                                  onChange={(e) =>
                                    setApprovalFieldValues('fullName', e.target.value, user._id)
                                  }
                                  value={userUpdatesApprovals[user?._id]?.fullName}
                                  placeholder="Johnny Sims"
                                  className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                />
                              )}
                            </div>
                            <div className="flex flex-col  w-full">
                              <label htmlFor="" className="pb-2">
                                Email<span>*</span>
                              </label>
                              <input
                                type="email"
                                name="email"
                                placeholder="example@domain.tld"
                                value={
                                  userUpdatesApprovals[user?._id]?.editingStarted
                                    ? userUpdatesApprovals[user?._id]?.email
                                    : user?.email
                                }
                                onChange={(e) =>
                                  setApprovalFieldValues('email', e.target.value, user._id)
                                }
                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                              />
                              {userUpdatesApprovals[user?._id]?.email && (
                                <input
                                  type="email"
                                  name="email"
                                  placeholder="example@domain.tld"
                                  // value={email ==="" ? user.email : email ==="null" ? "" : email}
                                  onChange={(e) =>
                                    setApprovalFieldValues('email', e.target.value, user._id)
                                  }
                                  value={userUpdatesApprovals[user?._id].email}
                                  className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                />
                              )}
                            </div>
                          </div>

                          {/* Five row */}
                          <div className="md:m-5 md:flex">
                            <div className="flex flex-col  w-full">
                              <label htmlFor="" className="pb-2">
                                Mobile<span>*</span>
                              </label>
                              <input
                                type="text"
                                name="Mobile"
                                placeholder="(555) 555-5555"
                                value={
                                  userUpdatesApprovals[user?._id]?.editingStarted
                                    ? userUpdatesApprovals[user?._id]?.mobileNumber
                                    : user?.mobileNumber
                                }
                                onChange={(e) =>
                                  setApprovalFieldValues('mobileNumber', e.target.value, user._id)
                                }
                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                              />
                              {userUpdatesApprovals[user?._id]?.mobileNumber && (
                                <input
                                  type="text"
                                  name="mobileNumber"
                                  placeholder="(555) 555-5555"
                                  // value={mobileNumber ==="" ? user.mobileNumber : mobileNumber ==="null" ? "" : mobileNumber}
                                  onChange={(e) =>
                                    setApprovalFieldValues('mobileNumber', e.target.value, user._id)
                                  }
                                  value={userUpdatesApprovals[user?._id].mobileNumber}
                                  className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                />
                              )}
                            </div>

                            <div>
                              <label for="frm-whatever" class="sr-only">
                                Verified?
                              </label>
                              <button
                                className={`border-2 border-red-500`}
                                onClick={() => verifyUser(user._id)}
                              >
                                Verify User
                              </button>
                              <label htmlFor="">Is this user admin_verified</label>
                              <p>{user.admin_verified ? 'Yes' : 'No'}</p>
                            </div>
                            <label htmlFor="">Did the user verify his account via mobile?</label>
                            <p>{user.verified ? 'Yes' : 'No'}</p>
                          </div>

                          <div className="md:m-5 md:flex">
                            <div className="flex flex-col w-full mr-10">
                              <label htmlFor="" className="pb-2">
                                Mobile<span>*</span>
                              </label>
                              <input
                                type="text"
                                name="name"
                                // value={name==="" ? user.fullName : name ==="null" ? "" : name}
                                // onChange={(e) => updateBasicData(e)}
                                placeholder="Johnny Sims"
                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                              />
                            </div>

                            <div class="w-full relative ">
                              <label htmlFor="" className="">
                                Mobile<span>*</span>
                              </label>
                              <select class="w-full py-1 px-2 bg-white appearance-none">
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                              </select>
                              <div class="pointer-events-none absolute right-0 top-0 bottom-0 flex items-center px-2 text-gray-700 ">
                                <svg
                                  class="h-4 w-4"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 12"
                                >
                                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-row gap-x-5">
                            <button
                              type="button"
                              onClick={() => approveAndEditUpdates(user._id)}
                              className="bg-[#28a745] text-sm px-3 py-2 rounded mt-3 text-white"
                            >
                              Approve Updates
                            </button>
                            <button
                              className="bg-red-500 text-sm px-3 py-2 rounded mt-3 text-white"
                              type="submit"
                            >
                              Reject Updates
                            </button>
                          </div>
                          {/* {
  submitChanges === "touched"
  &&
              <div className="mb-0 text-right mr-5">
              <button
              onClick={updateUserBasics} 
              className="bg-[#28a745] text-sm px-3 py-2 rounded mt-3 text-white"
                type="submit"
                >
                Save Changes
              </button>
            </div>
              } */}
                        </form>
                      </div>

                      <div className="border p-1 md:p-4 ">
                        <div className="flex justify-start bg-[#4CB04F] text-3xl text-[#fff] py-3 px-5 m-1 rounded">
                          <AiOutlineInfoCircle className="mr-1" />
                          <h3>Industry Preferences</h3>
                        </div>

                        <div>
                          <form>
                            <div className="md:m-5 md:flex ">
                              <div className="flex flex-col w-full mr-10 mb-4">
                                <label htmlFor="" className="pb-2">
                                  Networks<span>*</span>
                                </label>
                                <select
                                  value={
                                    userUpdatesApprovals[user?._id]?.editingStarted
                                      ? userUpdatesApprovals[user?._id]?.network
                                      : user?.network
                                  }
                                  onChange={(e) =>
                                    setApprovalFieldValues('network', e.target.value, user._id)
                                  }
                                >
                                  <option>Network</option>
                                  {Object.keys(networkKeys).map((network, index) => (
                                    <option key={index} value={networkKeys[network]}>
                                      {networkKeys[network]}
                                    </option>
                                  ))}
                                </select>

                                {userUpdatesApprovals[user?._id]?.network && (
                                  <select
                                    value={userUpdatesApprovals[user?._id]?.network}
                                    onChange={(e) =>
                                      setApprovalFieldValues('network', e.target.value, user._id)
                                    }
                                  >
                                    <option>Network</option>
                                    {Object.keys(networkKeys).map((network, index) => (
                                      <option key={index} value={networkKeys[network]}>
                                        {networkKeys[network]}
                                      </option>
                                    ))}
                                  </select>
                                )}
                              </div>
                              <div
                                style={{
                                  backgroundColor: networkColorKeys[network] || '#19A1B8',
                                  borderColor: networkColorKeys[network] || '#19A1B8',
                                }}
                                className="w-full py-2 px-2  text-white border-4 text-xs font-bold flex justify-center items-center"
                              >
                                <AiOutlineCheckCircle className="font-extrabold mr-2" />
                                <p> I'm seeking Network</p>
                              </div>
                            </div>
                            <div className="md:m-5 md:flex ">
                              <div className="flex flex-col w-full mr-10 mb-4">
                                <label htmlFor="" className="pb-2">
                                  Industry Preferences<span>*</span>
                                </label>
                                {/* start */}
                                <select>
                                  <option value="">Category</option>

                                  <option value="Aerospace">Aerospace</option>
                                  <option value="Agriculture">Agriculture</option>
                                  <option value="Biotechnology">Biotechnology</option>
                                  <option value="Business Products">Business Products</option>
                                  <option value="Business Services">Business Services</option>
                                  <option value="Chemicals and Chemical Products">
                                    Chemicals and Chemical Products
                                  </option>
                                  <option value="Clean Technology">Clean Technology</option>
                                  <option value="Computers and Peripheral">
                                    Computers and Peripherals
                                  </option>
                                  <option value="Construction">Construction</option>
                                  <option value="Consulting">Consulting</option>
                                  <option value="Consumer Products">Consumer Products</option>
                                  <option value="Consumer Services">Consumer Services</option>
                                  <option value="Digital Marketing">Digital Marketing</option>
                                  <option value="Education">Education</option>
                                  <option value="Electronics / Instrumentation">
                                    Electronics / Instrumentation
                                  </option>
                                  <option value="Fashion">Fashion</option>
                                  <option value="Financial Services">Financial Services</option>
                                  <option value="Fintech">Fintech</option>
                                  <option value="Food and Beverage">Food and Beverage</option>
                                  <option value="Gaming">Gaming</option>
                                  <option value="Healthcare Services">Healthcare Services</option>
                                  <option value="Industrial/Energy">Industrial/Energy</option>
                                  <option value="Internet / Web Services">
                                    Internet / Web Services
                                  </option>
                                  <option value="IT Services">IT Services</option>
                                  <option value="Legal">Legal</option>
                                  <option value="Lifestyle">Lifestyle</option>
                                  <option value="Marine">Marine</option>
                                  <option value="Maritime/Shipping">Maritime/Shipping</option>
                                  <option value="Marketing / Advertising">
                                    Marketing / Advertising
                                  </option>
                                  <option value="Media and Entertainment">
                                    Media and Entertainment
                                  </option>
                                  <option value="Medical Devices and Equipment">
                                    Medical Devices and Equipment
                                  </option>
                                  <option value="Mining">Mining</option>
                                  <option value="Mobile">Mobile</option>
                                  <option value="Nanotechnology">Nanotechnology</option>
                                  <option value="Networking">Networking and Equipment</option>
                                  <option value="Oil and Gas">Oil and Gas</option>
                                  <option value="Other">Other</option>
                                  <option value="Real Estate / Construction">
                                    Real Estate / Construction
                                  </option>
                                  <option value="Retailing / Distribution">
                                    Retailing / Distribution
                                  </option>
                                  <option value="Robotics">Robotics</option>
                                  <option value="Security">Security</option>
                                  <option value="Semiconductors">Semiconductors</option>
                                  <option value="Software">Software</option>
                                  <option value="Sports">Sports</option>
                                  <option value="Telecomunications">Telecommunications</option>
                                  <option value="Transportation">Transportation</option>
                                  <option value="Travel">Travel</option>
                                </select>
                              </div>

                              <div className="flex flex-col w-full mr-10 mb-4">
                                <label htmlFor="" className="pb-2">
                                  Amount<span>*</span>
                                </label>

                                <div className="flex justify-space align-center">
                                  <input
                                    type="text"
                                    name="amount"
                                    // value={ad==="" ? user.amount : ad === "null" ? "" : ad}
                                    // onChange={(e) => updatePreferenceData(e)}
                                    placeholder="19"
                                    className="mr-2 bg-gray-200 border-2 border-gray-200 rounded-l w-4/12 py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                  />
                                  <select name="bracketamount" required>
                                    <option value={'Thousand'}> Thousand</option>
                                    <option value={'Million'}> Million</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-row gap-x-5">
                              <button
                                type="button"
                                onClick={() => approveAndEditUpdates(user._id)}
                                className="bg-[#28a745] text-sm px-3 py-2 rounded mt-3 text-white"
                              >
                                Approve Updates
                              </button>
                              <button
                                className="bg-red-500 text-sm px-3 py-2 rounded mt-3 text-white"
                                type="submit"
                              >
                                Reject Updates
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>

                      <div className="border p-1 md:p-4 ">
                        <div className="flex justify-start  bg-[#CDE4FE] text-2xl text-[#014084] py-3 px-5 m-1 rounded">
                          <AiOutlineInfoCircle className="mr-1" />
                          <h3>Financial Network Posts</h3>
                        </div>
                        <form>
                          <div className="md:m-5 ">
                            <div className="flex justify-center align-center flex-col mb-4  w-32   lg:w-36 mx-auto">
                              {!userUpdatesApprovals[user?._id]?.profileImage && (
                                <img
                                  src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=1400&q=60"
                                  alt="Profile"
                                  className="w-40 border object-cover"
                                />
                              )}

                              {userUpdatesApprovals[user?._id]?.profileImage && (
                                <>
                                  <p>from</p>
                                  <img
                                    src={userUpdatesApprovals[user?._id]?.originalImage?.secureUrl}
                                    alt="Profile"
                                    className="w-40 border object-cover"
                                  />
                                  <p>to</p>
                                  <img
                                    src={userUpdatesApprovals[user?._id]?.profileImage?.secureUrl}
                                    alt="Profile"
                                    className="w-40 border object-cover"
                                  />
                                </>
                              )}

                              <div className="mt-3 w-full text-center">
                                <label
                                  htmlFor="upload-photo"
                                  className="cursor-pointer px-4 py-2 text-white  bg-[#17a2b8] hover:bg-[#1c7380] rounded-md  w-full text-sm"
                                >
                                  Upload Avatar
                                </label>
                                <input
                                  type="file"
                                  accept="image/png, image/jpeg"
                                  name="profilefileimage"
                                  id="upload-photo"
                                  className="hidden"
                                />
                              </div>
                            </div>

                            <div className="mx-auto border mb-3 py-3 w-3/4 text-center rounded bg-[#f3f3f3]">
                              <p>
                                Choose an Image that represent you, your product or company brand.
                                <br />
                                Quality images attract 80% more visitors!
                              </p>
                            </div>
                            <div className="flex flex-col w-full mr-10 mb-4">
                              <label htmlFor="" className="pb-2 flex justify-between">
                                <p>
                                  Ad Headline <span>*</span>
                                </p>
                                <p className="text-sm">
                                  Total Word Count: <strong>0</strong> words - Words left:{' '}
                                  <strong>15</strong>
                                </p>
                              </label>
                              <input
                                type="text"
                                name="profileAd"
                                placeholder="Min. 5 words - Max. 15 words"
                                value={
                                  userUpdatesApprovals[user?._id]?.editingStarted
                                    ? userUpdatesApprovals[user?._id]?.profileAd
                                    : user.profileAd
                                }
                                onChange={(e) =>
                                  setApprovalFieldValues('profileAd', e.target.value, user._id)
                                }
                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                              />
                              {userUpdatesApprovals[user?._id]?.profileAd && (
                                <input
                                  type="text"
                                  name="profileAd"
                                  placeholder="example@domain.tld"
                                  // value={profileAd ==="" ? user.profileAd : profileAd ==="null" ? "" : profileAd}
                                  onChange={(e) =>
                                    setApprovalFieldValues('profileAd', e.target.value, user._id)
                                  }
                                  value={userUpdatesApprovals[user?._id]?.profileAd}
                                  className="mt-2 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                />
                              )}
                            </div>

                            <div>
                              <label htmlFor="" className="pb-2 flex justify-between">
                                <p>
                                  profile Description <span>*</span>
                                </p>
                                <p className="text-sm">
                                  Total Word Count: <strong>0</strong> words - Words left:{' '}
                                  <strong>15</strong>
                                </p>
                              </label>

                              <textarea
                                type="text"
                                name="profileDescription"
                                value={user.profileDescription}
                                onChange={(e) =>
                                  setApprovalFieldValues(
                                    'profileDescription',
                                    e.target.value,
                                    user?._id
                                  )
                                }
                                placeholder="Min. 25 words - Max. 350 words"
                                className="w-full border p-2 mb-1 bg-gray-200"
                              ></textarea>
                              {userUpdatesApprovals[user?._id]?.profileDescription && (
                                <textarea
                                  type="text"
                                  name="profileDescription"
                                  value={userUpdatesApprovals[user?._id]?.profileDescription}
                                  onChange={(e) =>
                                    setApprovalFieldValues(
                                      'profileDescription',
                                      e.target.value,
                                      user?._id
                                    )
                                  }
                                  placeholder="Min. 25 words - Max. 350 words"
                                  className="w-full border p-2 mb-1 bg-gray-200"
                                ></textarea>
                              )}
                            </div>
                          </div>

                          {/* {
  submitPostChanges === "touched"
  &&
              <div className="mb-0 text-right mr-5">
              <button className="bg-[#28a745] text-sm px-3 py-2 rounded mt-3 text-white"
                type="submit"
                >
                Save Changes
              </button>
            </div>
              } */}
                          <div className="flex flex-row gap-x-5">
                            <button
                              type="button"
                              onClick={() => approveAndEditUpdates(user._id)}
                              className="bg-[#28a745] text-sm px-3 py-2 rounded mt-3 text-white"
                            >
                              Approve Updates
                            </button>
                            <button
                              className="bg-red-500 text-sm px-3 py-2 rounded mt-3 text-white"
                              type="submit"
                            >
                              Reject Updates
                            </button>
                          </div>
                        </form>
                      </div>

                      <div className="border p-1 md:p-4 ">
                        <div className="flex justify-start  bg-[#CDE4FE] text-2xl text-[#014084] py-3 px-5 m-1 mb-3 rounded">
                          <AiOutlineInfoCircle className="mr-1" />
                          <h3>Video Elevator Pitch</h3>
                        </div>
                        <div>
                          <div className="md:m-5">
                            <div>
                              <label htmlFor="" className="pb-2 ">
                                <p className="mb-2">OR Upload Video</p>
                              </label>
                              <div className="text-center  border-2 border-[#b7b7b7] py-5 rounded">
                                <p>Paste in a YouTube Link</p>
                                <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                                  <input
                                    type="text"
                                    required
                                    placeholder="Youtube Link"
                                    name="link"
                                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500 mb-1"
                                  />
                                  <button
                                    className="bg-[#4335dc] px-3 py-2 rounded mt-3 text-white text-sm"
                                    type="submit"
                                  >
                                    Upload Video
                                  </button>
                                </form>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="flex justify-start bg-[#CDE4FE] text-2xl text-[#014084] py-3 px-5 m-1 mt-5 mb-3 rounded">
                              <AiOutlineInfoCircle className="mr-1" />
                              <h3>Upload Images</h3>
                            </div>
                            <div className="text-center border-dashed border-2 border-[#b7b7b7] py-5 rounded md:m-4">
                              <p>Choose Image or Drag and Drop it here</p>

                              <div className="w-full flex align-center justify-center">
                                <div className="mt-3 w-full text-center">
                                  <label
                                    htmlFor="upload-photo"
                                    className="cursor-pointer px-4 py-2 text-white bg-[#4335dc] hover:bg-[#3228a2] rounded-md  w-full text-sm"
                                  >
                                    Upload Image
                                  </label>
                                  <input
                                    type="file"
                                    accept="image/png, image/jpeg"
                                    name="profilefile"
                                    id="upload-photo"
                                    className="hidden"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              ) : null}

              {openToggle === 2 ? (
                <td
                  // className={openBronze === user._id ? "visible" : "hidden"}
                  colspan="5"
                >
                  {!isLoading && bronzeMessages.length && (
                    <>
                      {console.log(bronzeMessages)}

                      {bronzeMessages.map((messageObj, messageIndex) => {
                        if (messageObj.participants.includes(user._id)) {
                          return messageObj.conversations.map((convo, index) => {
                            if (convo.paid === 'false') {
                              return (
                                <div
                                  className="text-center py-10 border-2 border-red-500"
                                  key={index}
                                >
                                  <div className="border-2 border-blue-500 w-3/4 lg:w-1/2 mx-auto">
                                    <div className="md:flex justify-center items-center">
                                      {' '}
                                      <p>{convo.sender}</p>
                                      <CgArrowLongRight
                                        className="md:mx-3 text-[#17a2b8]"
                                        size={28}
                                      />
                                      <p>{convo.receiver}</p>{' '}
                                    </div>

                                    <textarea
                                      className={`${
                                        editBronzeMessage ? 'px-10 py-3  bg-[#fff]' : 'px-10 py-3'
                                      }`}
                                      onChange={(e) => {
                                        setMessageBeingEdited(index);
                                        setBronzeMessage(e.target.value);
                                      }}
                                      value={
                                        bronzeMessage && currentMessageBeingEdited === index
                                          ? bronzeMessage
                                          : convo.message
                                      }
                                    ></textarea>

                                    <div className="flex justify-between ">
                                      <button
                                        onClick={rejectMessage}
                                        className="bg-[#dc3545] px-3 py-2 rounded mt-3 mr-2 text-white text-sm"
                                      >
                                        Reject
                                      </button>
                                      <div>
                                        {/* <button
                    onClick={() => setEditBronzeMessage(prevstate=>{ return !prevstate })}
                    className="bg-[#f0ad4e] px-3 py-2 rounded mt-3 mr-2 text-white text-sm">Edit</button> */}
                                        <button
                                          onClick={() =>
                                            approveMessage(
                                              messageObj._id,
                                              convo.message,
                                              convo._id,
                                              messageIndex
                                            )
                                          }
                                          className="bg-[#28a745] px-3 py-2 rounded mt-3 text-white text-sm"
                                        >
                                          Approve
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          });
                        }
                      })}
                    </>
                  )}

                  {/* {
       bronzeMessage.length &&
      <div>
        {
          bronzeMessage.map(elm => {
            return (
              elm.conversations.map(elm => {
                return(
                  <p>Message: {elm.message}</p>
                )
              })
            )
          })
        }
      </div>
      } */}
                </td>
              ) : null}
            </tr>
          </tbody>
        );
      })}
    </>
  );
};

export default User;
