import React from 'react';
import Topbar from '../../layouts/topbar';
import Menu from '../../layouts/menu';
import Sidebar from '../../layouts/sidebar';
import Package from './Package';

const Upgrade = () => {
  return (
    <>
      <Topbar />
      <Menu />
      <div className="mt-8 lg:flex lg:justify-between max-w-[1240px] mx-auto px-7">
        <Sidebar />
        <Package />
      </div>
    </>
  );
};

export default Upgrade;
