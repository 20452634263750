import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import showPwdImg from '../../svg/show-password.svg';
import hidePwdImg from '../../svg/hide-password.svg';
import { BiErrorCircle } from 'react-icons/bi';
import { useEffect } from 'react';

const Register = () => {
  const [fullName, setFullName] = useState('');
  const [fullNameErrorMessage, setFullNameErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [errorMessage, setErrorMessage] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // clear the token from local storage
    localStorage.removeItem('amount');
    localStorage.removeItem('category');
    localStorage.removeItem('network');
    localStorage.removeItem('industry');
    localStorage.removeItem('description');
    localStorage.removeItem('bio');
    localStorage.removeItem('profileAwaitingApproval');
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    const token = "gjdhgi3w8wafhoia";
        localStorage.setItem('token', token);
        return navigate('/preference');

  };

  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
  };

  const handleFullNameBlur = () => {
    const regex = /^[a-zA-Z]+(\s[a-zA-Z]+)+$/;

    const digitRegex = /\d/;
    if (!regex.test(fullName) || digitRegex.test(fullName)) {
      // setFullName('');
      setFullNameErrorMessage('Full name must have two words and no numbers');
      document.querySelector('input[name="fullName"]').style.borderColor = '';
    } else {
      setFullNameErrorMessage('');
      document.querySelector('input[name="username"]').style.borderColor = '';
    }
  };

  const handleEmailBlur = () => {
    if (!emailRegex.test(email)) {
      // setEmail('');
      setErrorMessage('Please enter a valid email address');
      document.querySelector('input[name="email"]').style.borderColor = '';
    } else {
      setErrorMessage('');
    }
  };

  const handlePasswordBlur = () => {
    if (password.length < 8) {
      // setPassword('');
      setPasswordErrorMessage('Password must be at least 8 characters');
      document.querySelector('input[name="password"]').style.borderColor = '';
    } else {
      setPasswordErrorMessage('');
    }
  };

  return (
    <>
      <div className="flex flex-col h-screen bg-[#f7f9fa]">
        <div className="">
          <div className=" m-auto w-screen h-[180px] z-10  bg-[url('https://i.imgur.com/6ZyzH0D.png')] drop-shadow-lg relative">
            <h1 className="text-white text-4xl font-semibold absolute px-28 pt-20 ">
              Create Account
            </h1>
          </div>
          <div className="container flex mx-auto max-w-screen-md items-center mt-20 ">
            <div className="flex flex-col w-11/12 md:w-4/5 mx-auto">
              <div className="flex flex-col items-center bg-white lg:p-4 lg:px-16 p-2 rounded border border-gray-primary mb-4 ">
                <h1 className="flex justify-left font-medium w-full text-2xl py-6">Register</h1>

                <form onSubmit={handleSubmit} method="POST" className="w-full">
                  <label htmlFor="" className="text-sm font-semibold">
                    Full Name
                  </label>
                  <input
                    type="text"
                    placeholder="enter text"
                    name="fullName"
                    value={fullName}
                    onChange={handleFullNameChange}
                    onBlur={handleFullNameBlur}
                    className="border-2 border-gray-200 rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:border-purple-500 mb-1"
                  />
                  {fullNameErrorMessage && (
                    <p className="text-red-500 text-sm mb-1">{fullNameErrorMessage}</p>
                  )}
                  <label className="text-sm font-semibold" htmlFor="">
                    Email
                  </label>
                  <input
                    type="email"
                    required
                    name="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    onBlur={handleEmailBlur}
                    placeholder="mail@website.com"
                    className="border-2 border-gray-200 rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:border-purple-500 mb-1"
                  />
                  {errorMessage && <p className="text-red-500 text-sm mb-1">{errorMessage}</p>}
                  <label className="text-sm font-semibold" htmlFor="">
                    Password
                  </label>
                  <div className="relative">
                    <input
                      aria-label="Enter your password"
                      type={showPassword ? 'text' : 'password'}
                      id="password"
                      name="password"
                      placeholder="min 8 characters"
                      required
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                      onBlur={handlePasswordBlur}
                      className="border-2 border-gray-200 rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:border-purple-500 mb-1"
                    />
                    <img
                      className="absolute cursor-pointer w-5 right-2 top-3"
                      title={showPassword ? 'Hide password' : 'Show password'}
                      src={showPassword ? hidePwdImg : showPwdImg}
                      onClick={() => setShowPassword((prevState) => !prevState)}
                      alt="img"
                    />
                    {passwordErrorMessage && (
                      <p className="text-red-500 text-sm mb-1">{passwordErrorMessage}</p>
                    )}
                  </div>
                  {error && (
                    <div className="text-red-500 flex justify-start items-center">
                      <div>
                        <BiErrorCircle size={16} className="mr-1" />
                      </div>
                      <div>{error}</div>
                    </div>
                  )}
                  <div className="my-4"></div>
                  <button
                    type="submit"
                    className={`${
                      !(
                        password.length >= 6 &&
                        email.includes('@') &&
                        email.includes('.') &&
                        fullName.length >= 5
                      )
                        ? 'bg-[#767676]'
                        : 'bg-black'
                    } mb-3 text-white w-full rounded h-8 font-bold`}
                  >
                    Register
                  </button>
                </form>
              </div>
              <div className="flex justify-center items-center flex-col w-full bg-white p-4 border rounded border-gray-primary mb-20">
                <p className="text-sm">
                  Already have an account?
                  {` `}
                  <Link to="/login" className="underline">
                    Log in
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="mt-auto"></div> */}
      </div>
    </>
  );
};

export default Register;
