import React, { useEffect, useRef, useState } from 'react';
import user from './user.png';

import { FaEllipsisH } from 'react-icons/fa';
import { BiArrowBack, BiConversation } from 'react-icons/bi';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import conversation from './conversation.png';

const Messages = () => {
  const [messageObjs, setMessageObjs] = useState('');
  const [loggedInUser, setLoggedInUser] = useState({});
  const [lastMessages, setLastMessages] = useState([]);
  const token = localStorage.getItem('token');
  const [message, setMessage] = useState('');
  const [currentMessageObj, setcurrentMessageObj] = useState('');
  const [userId, setUserId] = useState('');
  const [stateFullname, setFullname] = useState('');
  console.log('uselocaion', useLocation().state);
  let userid;
  let fullname;
  let state = useLocation().state;
  if (state) {
    userid = state.userid;
    fullname = state.fullname;
  }

  useEffect(() => {
    newCurrentRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [JSON.stringify(currentMessageObj)]);

  const currentMessageObjRef = useRef(null);
  const newCurrentRef = useRef(null);

  // useEffect(() => {
  //   if (currentMessageObjRef.current) {
  //     currentMessageObjRef.current.scrollTop = currentMessageObjRef.current.scrollHeight;
  //   }
  // }, [currentMessageObj]);

  useEffect(() => {
    const getMessagesAndUser = async () => {
      try {
        const userResponse = await axios.get('https://fargo-server.herokuapp.com/profile', {
          headers: { Authorization: `Bearer ${token}` },
        });

        // console.log('logged in user data', response.data.usersInfo)
        setLoggedInUser(userResponse.data.usersInfo);

        const response = await axios.get('https://fargo-server.herokuapp.com/messages', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setMessageObjs(response.data.myMessages);

        console.log('messages', response.data.myMessages);

        if (!response.data.myMessages.length && !userid) return;
        console.log('id', userid);

        if (userid) {
          let targetMessageObj = response.data.myMessages.find((messageObj) => {
            return messageObj.participants.includes(userid);
          });
          console.log('targetMessageObj', targetMessageObj);
          if (targetMessageObj) setcurrentMessageObj(targetMessageObj.conversations);
          else if (!targetMessageObj) {
            setUserId(userid);
            setFullname(fullname);
          }
        }

        let lastMessagesArray = [];

        response.data.myMessages.map((messageObj) => {
          //if logged in user is last message's sender ..show last message whether paid equals to false or not

          if (
            messageObj.conversations[messageObj.conversations.length - 1].sender ===
            userResponse.data.usersInfo._id
          ) {
            lastMessagesArray.push(
              messageObj.conversations[messageObj.conversations.length - 1].message
            );
            return;
          }

          let falseMessageIndex = messageObj.conversations.findIndex((convo) => {
            return convo.paid === 'false';
          });
          if (falseMessageIndex !== -1) {
            lastMessagesArray.push(messageObj.conversations[falseMessageIndex - 1].adminMessage);
            return;
          }
          if (messageObj.conversations[messageObj.conversations.length - 1].adminMessage) {
            lastMessagesArray.push(
              messageObj.conversations[messageObj.conversations.length - 1].adminMessage
            );
            return;
          }
          lastMessagesArray.push(
            messageObj.conversations[messageObj.conversations.length - 1].message
          );
        });

        setLastMessages(Array.from(new Set(lastMessagesArray.flat())));
        console.log('dfhd', Array.from(new Set(lastMessagesArray.flat())));
      } catch (error) {
        console.log(error);
      }
    };

    getMessagesAndUser();
  }, []);

  const createMessage = async (e, receiverID, userId) => {
    e.preventDefault();

    if (!message || (!receiverID && !userId)) return;
    try {
      const response = await axios.post(
        'https://fargo-server.herokuapp.com/message',
        { message, receiver: receiverID ? receiverID : userId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log('message response', response.data);

      let indexOfUpdatedMessageObj = messageObjs.findIndex((messageObj) => {
        return messageObj.participants.includes(receiverID ? receiverID : userId);
      });
      console.log('indexOfUpdatedMessageObj', indexOfUpdatedMessageObj);

      if (indexOfUpdatedMessageObj === -1 && userId) {
        setMessageObjs((prevstate) => {
          return [response.data.messageObj, ...prevstate];
        });
        setLastMessages((prevstate) => {
          return [message, ...prevstate];
        });
        setUserId('');
        setFullname('');
      } else if (indexOfUpdatedMessageObj !== -1 && !userId) {
        let newMessages = [...messageObjs];
        newMessages.splice(indexOfUpdatedMessageObj, 1);
        newMessages.unshift(response.data.messageObj);
        setMessageObjs([...newMessages]);
        let newLastMessages = lastMessages;
        newLastMessages.splice(indexOfUpdatedMessageObj, 1);
        newLastMessages.unshift(
          response.data.messageObj.conversations[response.data.messageObj.conversations.length - 1]
            .message
        );
        setLastMessages([...newLastMessages]);
      }
      console.log('messageObjs', messageObjs);
      setcurrentMessageObj(response.data.messageObj.conversations);
      setMessage('');
      newCurrentRef.current?.scrollIntoView({ behavior: 'smooth' });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-full border md:flex md:justify-center md:items-start mb-10">
      <div className=" w-full md:w-96">
        <div className="text-center text-lg py-3 pt-4 border-b shadow-sm">
          <h5>All Conversations</h5>
        </div>

        {/* user  */}
        {stateFullname && (
          <div className="flex justify-start px-2 border-b">
            <div className="my-auto">
              <img src={user} alt="" className="border w-12 rounded-full" />
            </div>
            <div className="flex justify-between items-start w-full p-2  py-4">
              <div className="ml-2">
                <h6 className="font-semibold">{stateFullname}</h6>
              </div>
              <div>
                <div className="text-sm text-[#7d7d7d] pb-1">1 day</div>
                <div className="mx-auto border bg-[#1CBF73] text-white  rounded-full w-6">
                  <p className="text-center text-sm">1</p>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="overflow-y-scroll h-[550px]">
          {messageObjs &&
            messageObjs.length &&
            messageObjs.map((messageObj, index) => {
              return (
                <div
                  className="flex justify-start px-2 border-b cursor-pointer hover:bg-[#ececec]"
                  onClick={() => {
                    setcurrentMessageObj(messageObj.conversations);
                    // newCurrentRef.current?.scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  <div className="my-auto">
                    <img src={user} alt="" className="border w-12 rounded-full" />
                  </div>
                  <div className="flex justify-between items-start w-full p-2  py-4">
                    <div className="ml-2">
                      <h6 className="font-semibold">
                        {messageObj.conversations[0].senderFullname === loggedInUser.fullName
                          ? messageObj.conversations[0].receiverFullname
                          : messageObj.conversations[0].senderFullname}
                      </h6>
                      <p className="text-sm text-[#7d7d7d]">{lastMessages[index]}</p>
                    </div>
                    <div>
                      <div className="text-sm text-[#7d7d7d] pb-1">1 day</div>
                      <div className="mx-auto border bg-[#1CBF73] text-white  rounded-full w-6">
                        <p className="text-center text-sm">1</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      {/* The actual message box */}
      <div className="w-full ">
        {!currentMessageObj && !userId && (
          <div className="border-l  flex items-center justify-center h-[610px] ">
            <div className="text-center">
              <img alt="" src={conversation} className="text-[#1CBF73] mx-auto w-40" />
              <p className="text-xl font-bold my-3">Pick up where you left off</p>
              <p>Select a converation and chat away.</p>
            </div>
          </div>
        )}
        {(currentMessageObj || userId) && (
          <div className="border-l">
            <div className="flex justify-between py-3 border-b px-4">
              <div className="text-3xl">
                <BiArrowBack />
              </div>
              <div className="flex justify-center items-center">
                <div className="w-2 h-2 bg-[#1CBF73] rounded-full mr-2"></div>
                <div className="text-xl font-medium">
                  {currentMessageObj
                    ? currentMessageObj[currentMessageObj.length - 1].receiverFullname ===
                      loggedInUser.fullName
                      ? currentMessageObj[currentMessageObj.length - 1].senderFullname
                      : currentMessageObj[currentMessageObj.length - 1].receiverFullname
                    : fullname}
                </div>
              </div>
              <div className="text-3xl ">
                <FaEllipsisH />
              </div>
            </div>
            {/* {currentMessageObjRef.current && ( */}
            <div className="overflow-y-scroll h-[500px]" ref={currentMessageObjRef}>
              {currentMessageObj &&
                currentMessageObj.map((convo) => {
                  console.log('convo', convo);
                  if (convo.paid === 'false' && convo.receiver === loggedInUser._id) return;
                  return (
                    <div className="px-3">
                      <div className="flex justify-start px-2 py-2">
                        <div className="my-auto">
                          <img src={user} alt="" className="w-10 rounded-full" />
                        </div>
                        <div className="w-full p-3 py-2">
                          <div className="font-medium">
                            {convo.sender === loggedInUser._id ? 'ME: ' : convo.sender}{' '}
                            <span className="text-sm text-[#7d7d7d] ml-2">
                              {new Date(convo.msgAt)
                                .toString()
                                .slice(0, new Date(convo.msgAt).toString().lastIndexOf('G'))}
                            </span>
                          </div>
                          <p className="font-normal">
                            {convo.receiver === loggedInUser._id && convo.adminMessage
                              ? convo.adminMessage
                              : convo.message}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              <div ref={newCurrentRef}></div>
            </div>
            {/* )} */}

            {/* Message box */}
            <form>
              <div className="md:flex md:justify-start items-center ">
                <div className="m-2">
                  <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    cols="100"
                    rows="3"
                    placeholder="Start typing..."
                    className="py-1 px-1 border w-full"
                  ></textarea>
                </div>
                <div className="text-right">
                  <button
                    onClick={(e) => {
                      createMessage(
                        e,
                        currentMessageObj
                          ? currentMessageObj[0].sender === loggedInUser._id
                            ? currentMessageObj[0].receiver
                            : currentMessageObj[0].sender
                          : null,
                        userId ? userId : null
                      );
                    }}
                    className="bg-[#4335dc] px-5 py-2 rounded mr-4 mb-2 text-white text-sm"
                  >
                    Send
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Messages;
