import axios from 'axios';
import React, { useState, useEffect } from 'react';
// import Pagination from '../../components/Pagination'
import Verify from './Verify';
// import { FiUsers } from 'react-icons/fi';
// import { Link } from 'react-router-dom';
// import { AiOutlineDashboard } from "react-icons/ai";
// import { AiOutlineEye } from "react-icons/ai";
// import { TbUserCheck } from "react-icons/tb";
// import { MdOutlineVerified } from "react-icons/md";
// import { BiSupport } from "react-icons/bi";
import Sidebar from '../Support/Sidebar';

const Verfication = () => {
  // all the posts will be an object of arrays
  const [verifications, setVerifications] = useState([]);
  // const [reply, setReply] = useState("")
  // since we're getting data from outside our app, it'll take some time. This helps us avoid the issue of posts.map is undefined
  const [loading, setLoading] = useState(false);
  // defines the current page we're on <- this state is for pagination
  // const [currentPage, setCurrentPage] = useState(1)
  // how many posts do you want to show per page
  // const [ticketsPerPage, setPostsPerPage] = useState(10)
  // const [isActionOpen, setIsActionOpen] = useState(false);
  const token = localStorage.getItem('token');

  // useEffect because we want this function to run as soon as the page renders
  useEffect(() => {
    // get the posts using async (bc js is an asynchornous language)
    const fetchVerifications = async () => {
      // as soon loading is true, it'll hide the axios function until it's gotten the corrent information
      setLoading(true);
      // get the the value that's stored in the API
      const res = await axios.get('https://fargo-server.herokuapp.com/verifications', {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log('reeaching');
      // sometimes put the value into clg to see what it returns. this one show sus all the posts
      console.log('res.data', res.data);
      setVerifications(res.data.allVerifications);
      // loading can be set to false now because the app received the info
      setLoading(false);
    };
    //   call this function
    fetchVerifications();
  }, []);

  // Get current posts
  // const indexOfLastTicket = currentPage * ticketsPerPage
  // const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage
  // const currentTickets = tickets.slice(indexOfFirstTicket, indexOfLastTicket)

  // change page
  // const paginate = (pageNumber) =>  setCurrentPage(pageNumber)

  return (
    <div className="flex">
      <Sidebar />
      {/* since the component is on another page, pass in props so the app can get the values from this page */}
      <div className="flex-col w-full">
        <Verify
          verifications={verifications}
          loading={loading}
          setVerifications={setVerifications}
        />
        {/* <Pagination ticketsPerPage={ticketsPerPage} totalTickets={tickets.length} paginate={paginate} /> */}
      </div>
    </div>
  );
};

export default Verfication;
