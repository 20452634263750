import React from 'react'
import { AiOutlineEye } from "react-icons/ai";

const ticket = ({ tickets, setIsActionOpen, isActionOpen, reply, setReply, adminReply,closeTicket }) => {
    return (

    <>
    {tickets.map((t,index)=> {
    return (
    <>
		<tbody className='border-2'>
			<tr>
				<th className="border px-4 py-2" scope="row">{t.openAt}</th>
				<td className="border px-4 py-2 ">{t.conversations[0].sender}</td>
				<td className="border px-4 py-2 hidden md:table-cell">
       
        {t.clientEmail}

          </td>
				<td className="border px-4 py-2 hidden md:table-cell">{t.subject}</td>
				<td  className="border px-4 py-2 text-center">
          <button className='bg-[#007bff] text-white p-2.5 rounded' 
          // onClick={() => setIsActionOpen((prev) => !prev)}
          onClick={() => setIsActionOpen(prevstate=>{ return prevstate===t._id?'':t._id })} 
          ><AiOutlineEye /></button>
        </td>
			</tr>
      <tr>
      <td colSpan="5" 
      className={isActionOpen===t._id ? 'visible' : 'hidden'}
      >
        <div className='border border-blue-500 px-40'>
          <div className='border border-red-500 '>
{/* waiting for response */}

<h2 className='bg-[#FFC00C] text-[11px] font-bold px-1 py-0.5 rounded ml-1'>Last Reply {t.lastReply}</h2>
               {
            t.conversations.map(convo => {
              return (
                <div>
                  {convo.sender} {convo.message}
                </div>)
        })
        }
        
          </div>
          <div className='flex flex-col mt-3'>
          <textarea placeholder='Type reply here...' name="" id="" cols="30" rows="10" onChange={(e) => setReply(e.target.value)} value={reply}></textarea>
          <button onClick={(e) => {adminReply(e,index,t._id)}} className='border-2 text-right mt-3'>Reply</button>
          <button onClick={(e) => {closeTicket(e,t._id)}} className='border-2 text-right mt-3'>close ticket</button>

          </div>
        </div>
      </td>
      </tr>
	

		</tbody>
    </>
    )
})}
</>
  )
}

export default ticket