import axios from 'axios';
import React, { useState } from 'react';
import { AiFillEye } from 'react-icons/ai';
import { BsTrashFill } from 'react-icons/bs';
import { RiDeleteBin6Line } from 'react-icons/ri';

const EachTicket = ({ tickets, setTickets }) => {
  const token = localStorage.getItem('token');
  const [reply, setReply] = useState('');
  const [isSupportOpen, setIsSupportOpen] = useState('');

  const deleteTicket = async (ticketId) => {
    try {
      // pass in the Id of the ticket you want to delete.
      // TODO: the delete component doesn't work yet
      const response = await axios.patch(
        'https://fargo-server.herokuapp.com/ticket',
        { ticketId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response.data);
      let newTickets = tickets.filter((ticket) => {
        return ticket._id !== response.data._id;
      });
      setTickets([...newTickets]);
    } catch (error) {
      console.log(error);
    }
  };

  const userReply = async (e, ticketId, index) => {
    e.preventDefault();
    try {
      // the first argument is the API, the second is the information you want to pass to the backend, and the third is the headers
      const response = await axios.put(
        'https://fargo-server.herokuapp.com/ticket',
        { ticketId, message: reply },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // tickets will have the value of response.data.myTickets
      setTickets(response.data.myTickets);
      // the box where we reply should be empty after we click send
      setReply('');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {tickets
        .map((t, index) => {
          return (
            <div key={index}>
              {/* Overall Users */}
              <div>
                <div className="flex justify-between items-center border">
                  <p>Ticket: {t.subject} </p>
                  <div className="flex justify-start items-center">
                    <p className="">Status: </p>
                    <p className="bg-[#FFC00C] text-[11px] font-bold px-1 py-0.5 rounded ml-1">
                      {t.lastReply}
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <AiFillEye
                      onClick={() =>
                        setIsSupportOpen((prevstate) => {
                          return prevstate === t._id ? '' : t._id;
                        })
                      }
                      className="bg-[#FFC00C] text-white text-[28px] p-1.5 rounded-l hover:cursor-pointer"
                    />
                    <BsTrashFill
                      onClick={() => deleteTicket(t._id)}
                      className="bg-[#FF0100] text-white text-[28px] p-1.5 rounded-r hover:cursor-pointer "
                      // onClick={() => deleteTicket(id)}
                    />
                  </div>
                </div>
                <div className={isSupportOpen === t._id ? 'visible' : 'hidden'}>
                  <h6 className="py-1">
                    {' '}
                    <span className="font-bold">Username: </span>User
                  </h6>
                  <p className="pb-1">
                    <span className="font-bold">Title:</span> Title
                  </p>

                  <p className="pb-1">
                    <span className="font-bold">Message:</span>

                    {t.conversations.map((elm) => {
                      return (
                        <div className="flex justify-between items-center bg-[#d8e1e551] py-1 px-2 rounded my-2">
                          <div className="flex justify-between items-center w-full">
                            <p className=" ">
                              {' '}
                              <span className="font-bold">{elm.sender}</span>: {elm.message}
                            </p>
                            <p className="text-sm italic text-[#6e6e6e] mr-3 min-w-max">
                              {new Date(elm.msgAt)
                                .toString()
                                .slice(0, new Date(elm.msgAt).toString().lastIndexOf('G'))}
                            </p>
                          </div>
                          <button className="text-red-500" onClick={(e) => {}}>
                            <RiDeleteBin6Line />
                          </button>
                        </div>
                      );
                    })}
                  </p>

                  <form className="flex flex-col" onSubmit={(e) => userReply(e, t._id, index)}>
                    <textarea
                      id="myTextarea"
                      cols="30"
                      rows="4"
                      value={reply}
                      className="p-1 text-gray-base w-full border border-gray-primary"
                      placeholder="Type your message here..."
                      onChange={(e) => setReply(e.target.value)}
                    ></textarea>
                    <div className="text-right">
                      <button
                        className="bg-[#28a745] px-3 py-2 rounded mt-3 text-white text-xs transition duration-300 ease-in-out hover:bg-[#1f8236]  hover:shadow-sm"
                        type="submit"
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          );
        })
        .reverse()}

      {/* <div key={index}>
      //   <p>{t.subject}</p>
      //   <button onClick={() => deleteTicket(ticket._id)}>Delete</button>
      // </div> */}
    </div>
  );
};

export default EachTicket;
