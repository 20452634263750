import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import UserCard from '../../components/Cards/UserCard';
import noFriends from '../Friends/noImage.png';
import { AppContext } from '../../Context/context';
import { AiTwotoneBulb } from 'react-icons/ai';

// [{},{},{}] -> user in frontend

// [{},{}] -> user in backend

const Requests = () => {
  // 1. Declaring a userState and making user an empty string
  const [user, setUser] = useState('');
  // Get token from localStorage
  const token = localStorage.getItem('token');
  const [isLoading, setLoading] = useState(false);
  const { updateFriendRequestReceived } = useContext(AppContext);

  // Declare a useEffect
  useEffect(() => {
    // Create a async function called getUser (wrap inside of a tryCatch block)
    async function getUser() {
      try {
        // create a variable called response, and call the getProfile API
        const response = await axios.get('https://fargo-server.herokuapp.com/profile', {
          // The header is to verify the user has authorization to call this API
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log(response.data.usersInfo, 'response.data.usersInfo');
        // setting the data we get for the get profile onto user state
        setUser(response.data.usersInfo);
        // console.log(response.data.usersInfo.friendRequestsReceived.length, 'friendRequestsReceived')
        // setFriendsNumber(response.data.usersInfo.friendRequestsReceived.length)
      } catch (error) {
        // { message: "Success"}
        console.log(error);
      }
    }
    // Call the function outside of itself
    setLoading(true);
    getUser();
    setLoading(false);
  }, [updateFriendRequestReceived]);

  if (isLoading) {
    return (
      <div className="text-center">
        <h1 className="text-xl mt-5">Loading...</h1>
      </div>
    );
  }
  return (
    <>
      {user.friendRequestsReceived && user.friendRequestsReceived.length === 0 && (
        <div className="text-center">
          <h1 className="text-xl mt-5">
            You currently have no users who have sent you a{' '}
            <span className="font-bold">Friend Request</span>.
          </h1>

          <img className="w-4/5 mx-auto mt-10" src={noFriends} alt="" />
        </div>
      )}
      {!isLoading && Object.keys(user).length > 0 && (
        <div>
          {user.friendRequestsReceived.map((currentUser) => {
            // console.log(currentUser)
            return (
              <>
                <div className="flex justify-left items-center bg-[#7867a7] text-white font-normal text-lg py-2 pl-5 lg:mx-0 rounded mb-3">
                  <AiTwotoneBulb className="mr-3 text-3xl" /> Network
                </div>
                <UserCard
                  showFriendIcon={true}
                  showFavoriteIcon={true}
                  showNoteIcon={true}
                  showContactButtons={false}
                  acceptRejectFriend={true}
                  user={user}
                  setUser={setUser}
                  users={[currentUser]}
                />
              </>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Requests;
