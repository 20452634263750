import axios from 'axios';
import React, { useState, useEffect } from 'react';

import { HiOutlineLightBulb } from 'react-icons/hi';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import userPic from '../../components/Cards/icons/placeholderUser.png';

const RegisterNetwork = () => {
  const [bio, setBio] = useState('');
  const [description, setDescription] = useState('');
  // const [previewSource, setPreviewSource] = useState('');
  // const [image,setImage] = useState('')
  const [fullBioErrorMessage, setBioErrorMessage] = useState('');
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state
  const [base64Image, setBase64Image] = useState('');
  const navigate = useNavigate();

  const handleOnImageFileChange = (e) => {
    let file = e.target.files[0];
    console.log('file', file);

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setBase64Image(reader.result);
      // setPreviewSource(reader.result);
    };
    console.log('base64', base64Image);
  };

  useEffect(() => {
    const prevStoredBio = localStorage.getItem('bio') || '';
    const prevStoredDescription = localStorage.getItem('description') || '';
    const prevStoredImage = localStorage.getItem('profileImage') || '';
    setBio(prevStoredBio);
    setDescription(prevStoredDescription);
    setBase64Image(prevStoredImage);
  }, []);

  const handleClick = async (event) => {
    event.preventDefault();

      return navigate('/signin');

  };

  function handleBioBlur(e) {
    const inputElement = e.target;
    const errorElement = inputElement.nextElementSibling;
    const words = inputElement.value.split(' ');
    if (words.length < 5 || words.length > 15) {
      inputElement.style.borderColor = '';
      setBioErrorMessage('Error: Ad Headline must have between 5 and 15 words');
      errorElement.style.color = 'red';
    } else {
      inputElement.style.borderColor = '';
      setBioErrorMessage('');
      errorElement.textContent = '';
    }
  }

  function handleDescriptionBlur(e) {
    const inputElement = e.target;
    const errorElement = inputElement.nextElementSibling;
    const words = inputElement.value.split(' ');
    if (words.length < 25 || words.length > 350) {
      inputElement.style.borderColor = '';
      setDescriptionErrorMessage('Error: Profile Ad must have between 25 and 350 words');
      errorElement.style.color = 'red';
    } else {
      inputElement.style.borderColor = '';
      setDescriptionErrorMessage('');
      errorElement.textContent = '';
    }
  }

  return (
    <>
      <div className="flex flex-col h-screen w-screen">
        <div className="bg-[#EBEFF4] h-screen">
          <div className=" h-[180px]  bg-[url('https://i.imgur.com/6ZyzH0D.png')] drop-shadow-lg text-center ">
            <h1 className="text-white text-4xl font-semibold pt-20">Create Your Profile</h1>
          </div>
          <div className="border bg-[#EBEFF4]">
            {/* Basic Info, this information is already filled out */}
            <div className=" max-w-[840px] mx-auto">
              <div className=" text-3xl py-10  text-center">
                <h6 className="text-sm mb-3">
                  <span>Profile • </span>
                  <span>Preference • </span>
                  <span className="text-[#4335dc] font-medium">Network • </span>
                  <span>Done</span>
                </h6>
                <h3>Financial Network Post</h3>
                <p className="text-base mt-5 px-10">
                  Apply privately to thousands of tech companies & startups with one profile.
                </p>
              </div>
              <form onSubmit={handleClick} className="border m-4 rounded-lg bg-white">
                <div className="m-5 ">
                  <div className="text-center md:flex md:justify-between">
                    <div className="">
                      <div className="w-36 h-36 mx-auto">
                        {!base64Image && (
                          <img
                            src={userPic}
                            alt="Profile pick"
                            className="w-36 h-36 border p-2 mx-auto object-cover"
                          />
                        )}
                        {base64Image && (
                          <img
                            src={base64Image}
                            alt="chosen"
                            className="w-36 h-36 border p-2 mx-auto object-cover"
                          />
                        )}
                      </div>
                      <div className="mt-3">
                        <label
                          htmlFor="upload-photo"
                          className="cursor-pointer px-4 py-2 text-white  bg-[#17a2b8] hover:bg-[#1c7380] rounded-md  w-40 text-sm"
                        >
                          Upload Avatar
                        </label>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          name="profilefile"
                          id="upload-photo"
                          onChange={(e) => handleOnImageFileChange(e)}
                          className="border-2 opacity-0 absolute z-0"
                        />
                      </div>
                    </div>
                    <div className="border px-5 py-4 md:py-0 text-center rounded bg-[#f3f3f3] mt-3 md:mt-1 md:flex md:items-center md:text-lg relative">
                      <p>
                        Choose an Image that represent you, your product or company brand.
                        <br />
                        Quality images attract 80% more visitors!
                      </p>
                      <HiOutlineLightBulb className="absolute text-3xl bottom-2 right-2" />
                    </div>
                  </div>

                  <div className="flex flex-col w-full mr-10 mb-4"></div>
                  <div className="flex flex-col w-full mr-10 mb-4">
                    <label htmlFor="" className="pb-2 flex justify-between">
                      <p>
                        Ad Headline <span>*</span>
                      </p>
                      <p className="text-sm">
                        Total Word Count:{' '}
                        <strong>{bio.split(' ').filter((word) => word !== '').length}</strong> words
                      </p>
                    </label>
                    <input
                      type="text"
                      value={bio}
                      onChange={(e) => {
                        setBio(e.target.value);
                        localStorage.setItem('bio', e.target.value);
                      }}
                      onBlur={handleBioBlur}
                      name="username"
                      required
                      placeholder="Min. 5 words - Max. 15 words"
                      className="border-2 border-gray-200 rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:border-purple-500 mb-1"
                    />

                    {fullBioErrorMessage && (
                      <p className="text-red-500 text-sm font-normal mb-1">{fullBioErrorMessage}</p>
                    )}
                  </div>

                  <div>
                    <label htmlFor="" className="pb-2 flex justify-between">
                      <p>
                        Profile Ad <span>*</span>
                      </p>
                      <p className="text-sm">
                        Total Word Count:{' '}
                        <strong>
                          {description.split(' ').filter((word) => word !== '').length}
                        </strong>{' '}
                        words
                      </p>
                    </label>
                    <textarea
                      name=""
                      id=""
                      cols="20"
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                        localStorage.setItem('description', e.target.value);
                      }}
                      onBlur={handleDescriptionBlur}
                      rows="4"
                      placeholder="Min. 25 words - Max. 350 words"
                      className="w-full border p-2"
                      required
                    ></textarea>
                    {descriptionErrorMessage && (
                      <p className="text-red-500 font-normal text-sm mb-1">
                        {descriptionErrorMessage}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="text-right m-5">
                    <Link
                      className="bg-[#4335dc] px-3 py-2 rounded mt-3 text-white text-sm"
                      to={'/preference'}
                    >
                      Go Back
                    </Link>
                  </div>

                  <div className="text-right m-5">
                    <button
                      type="submit"
                      className="bg-[#4335dc] px-3 py-2 rounded text-white text-sm disabled:bg-gray-400"
                      disabled={
                        isLoading || description.split(' ').length < 25 || bio.split(' ').length < 5
                      }

                      //  disabled={loader || !( bio.length > 5 && bio.length < 15 && description.length < 25 && description.length < 350)}
                    >
                      {' '}
                      {isLoading ? (
                        <div class="  mx-12 flex flex-col items-center justify-center">
                          <div class="absolute w-5 h-5 rounded-full border-2 border-solid border-gray-700"></div>
                          <div class="w-5 h-5 rounded-full animate-spin border-2 border-solid border-white border-t-transparent shadow-md"></div>
                        </div>
                      ) : (
                        'Submit'
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <div className='mt-auto'>
      <Footer />
    </div> */}
      </div>
    </>
  );
};

export default RegisterNetwork;
