import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import showPwdImg from '../../svg/show-password.svg';
import hidePwdImg from '../../svg/hide-password.svg';
import { RiCloseLine } from 'react-icons/ri';

// import GetStarted from '../../components/homepage/GetStarted';
import GetStarted from '../Homepage/GetStarted.jsx';
import axios from 'axios';

const Signin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [pendingApproval, setPendingApproval] = useState(
    localStorage.getItem('profileAwaitingApproval')
  );
  const [status, setStatus] = useState();

  const navigate = useNavigate();
  // const pendingApproval = localStorage.getItem('profileAwaitingApproval');

  // const handleClick = async (e) => {
  //   e.preventDefault();

  //   //anytime we deal with promises..we can either use async and await or .then and .catch or we can use callbacks

  //   axios
  //     .post('/login', {
  //       email,
  //       password,
  //     })

  //     //basically a call to an api through http protocol is asynchronous, meaning it takes some time
  //     //when a user logs in you grab their data from backend and then you cache the data in the browser to avoid any other calls to backend to get the same data

  //     .then(function (response) {
  //       // setStatus(200)
  //       console.log('response from backend', response.data);
  //       const token = response.data.token;
  //       // this function is storing the token after we get it from response.data.token
  //       localStorage.setItem('token', token);
  //       // response data is an object, we can only store strings inside of localStorage. We'll need to stringify the object by using JSON.stringify
  //       localStorage.setItem('userData', JSON.stringify(response.data));

  //       console.log(response.data.user.fullname);
  //       return navigate('/dashboard');
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  const handleClick = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://fargo-server.herokuapp.com/login', {
        email,
        password,
      });
      const token = response.data.token;
      localStorage.setItem('token', token);
      localStorage.setItem('userData', JSON.stringify(response.data));
      return navigate('/dashboard');
    } catch (error) {
      const errorMessage = error.response.data.message;
      setErrorMsg(errorMessage);
    }
  };

  const handleClose = () => {
    setPendingApproval(false);
    localStorage.removeItem('profileAwaitingApproval');
  };

  return (
    <>
      <div className="flex flex-col h-screen bg-[#f7f9fa] ">
        <div className="bg-[#f7f9fa]">
          <div className=" m-auto w-screen h-[180px] z-10  bg-[url('https://i.imgur.com/6ZyzH0D.png')] drop-shadow-lg relative">
            <h1 className="text-white text-4xl font-semibold absolute top-20 left-40">Login</h1>
          </div>

          <div className="container flex mx-auto max-w-screen-md items-center h-auto mt-20 ">
            <div className="flex flex-col w-11/12 md:w-4/5 mx-auto">
              <div className="flex flex-col items-center bg-white p-4 px-16 rounded border border-gray-primary mb-4">
                <h1 className="flex justify-left font-medium w-full text-2xl py-6">
                  Sign in to your account
                </h1>

                <p className="mb-5 text-[#8f8f8f]">----- Login with Email -----</p>
                <form onSubmit={handleClick} method="POST" className="w-full">
                  <input
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    aria-label="Enter your email address"
                    placeholder="Email Address"
                    name="email"
                    className="text-sm text-gray-base w-full mr-3 py-5 px-4 h-2 border border-gray-primary rounded mb-4"
                  />
                  <div className="relative">
                    <input
                      aria-label="Enter your password"
                      type={showPassword ? 'text' : 'password'}
                      onChange={(e) => setPassword(e.target.value)}
                      id="password"
                      placeholder="Password"
                      name="password"
                      className="text-sm text-gray-base w-full mr-3 py-5 px-4 h-2 border border-gray-primary rounded "
                    />
                    <img
                      className="absolute cursor-pointer w-5 right-2 top-3"
                      title={showPassword ? 'Hide password' : 'Show password'}
                      src={showPassword ? hidePwdImg : showPwdImg}
                      onClick={() => setShowPassword((prevState) => !prevState)}
                      alt="img"
                    />
                  </div>
                  {errorMsg && <p className="text-red-500">{errorMsg}</p>}
                  <Link to="/reset">
                    <p className="my-2 text-sm flex justify-end underline">Forgot Password?</p>
                  </Link>
                  <button
                    type="submit"
                    className="bg-gray-400 mb-3 text-white w-full rounded h-8 font-bold"
                    disabled={true}
                  >
                    Login
                  </button>
                </form>
              </div>
              <div className="flex justify-center items-center flex-col w-full bg-white p-4 border rounded border-gray-primary">
                <p className="text-sm">
                  Not registered?
                  <Link to="/register" className="pl-2 underline">
                    Create Account
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>{' '}
        <div class="bg-[#28a745] fixed bottom-0 w-full px-4 py-4 flex justify-between items-center">
            <p className="text-white">
              Please wait 2-4 hours for Admin to verify your account. Once verified, you'll have
              access to the platform.
            </p>
            {/* <RiCloseLine size={28} className="text-white cursor-pointer	" onClick={handleClose} /> */}
          </div>
      </div>
    </>
  );
};

export default Signin;
