import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import axios from 'axios';
// hide number

const RegisterVerify = () => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [numberErrorMessage, setNumberErrorMessage] = useState('');
  const [requestStatus, setRequestStatus] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [countdown, setCountdown] = useState(60);
  const [otpErrorMessage, setOtpErrorMessage] = useState('');

  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  const handleVerification = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        'https://fargo-server.herokuapp.com/verifyotp',
        {
          otp,
          to: mobileNumber,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        localStorage.setItem('profileAwaitingApproval', 'true');
        navigate('/login');
      }
    } catch (error) {
      console.log('IncorrectOTP');
      setOtpErrorMessage('Invalid Code. Please check the number and resend the code.');
      return;
    }
  };

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setCountdown((countdown) => countdown - 1);
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, []);

  useEffect(() => {
    if (countdown <= 0) {
      setDisabled(false);
    }
  }, [countdown]);

  const handleClick = (event) => {
    event.preventDefault();
    setDisabled(true);
    setCountdown(60);
  };

  const handleNumberBlur = () => {
    if (mobileNumber.replace(/\D/g, '').length < 8) {
      setNumberErrorMessage('Mobile number must be at least 8 digits');
    } else {
      setNumberErrorMessage('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'https://fargo-server.herokuapp.com/sendotp',
        { to: mobileNumber },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.status === 200) {
        setRequestStatus('success');
      }
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="flex flex-col h-screen w-screen">
        <div className="bg-[#EBEFF4] h-screen">
          <div className=" h-[180px]  bg-[url('https://i.imgur.com/6ZyzH0D.png')] drop-shadow-lg text-center ">
            <h1 className="text-white text-4xl font-semibold pt-20">Create Your Profile</h1>
          </div>
          <div className="border bg-[#EBEFF4]">
            {/* Basic Info, this information is already filled out */}
            <div className=" max-w-[840px] mx-auto">
              <div className=" text-3xl py-10  text-center">
                <h6 className="text-sm mb-3">
                  <span>Profile • </span>
                  <span>Preference • </span>
                  <span>Network • </span>
                  <span className="text-[#4335dc] font-medium">Done</span>
                </h6>
                <h3>Verify your phone number</h3>
                <p className="text-base mt-5 px-10">
                  Your phone number will only ever be used to verify your identity.
                </p>
              </div>
              {requestStatus === '' && (
                <form
                  // onSubmit={handleClick}
                  className="border m-4 rounded-lg bg-white mb-20"
                >
                  <div className="md:flex md:justify-between">
                    <div className="flex flex-col m-4 bg-white md:w-full">
                      <label htmlFor="">Phone Number</label>
                      <input
                        type="tel"
                        placeholder="+1 555-555-5555"
                        value={mobileNumber}
                        pattern="[0-9]{10}"
                        onChange={(e) => setMobileNumber(e.target.value)}
                        onBlur={handleNumberBlur}
                        className="bg-white mb-4 md:mb-0 border-2 border-gray-200 rounded  py-2 px-2 text-gray-700 focus:outline-none  focus:border-blue-500 mt-1"
                      />
                      {numberErrorMessage && (
                        <p className="text-red-500 font-normal text-sm mb-1">
                          {numberErrorMessage}
                        </p>
                      )}
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className={`${
                          mobileNumber.length > 9
                            ? 'bg-white  text-[#4335dc] border-[#4335dc] hover:text-white hover:bg-[#4335dc]'
                            : 'bg-[#939393] text-white cursor-default	'
                        }  rounded mt-3  border w-full px-3 py-2 text-sm`}
                        // className="bg-white text-[#4335dc] border border-[#4335dc] px-3 py-2 rounded mt-3 hover:text-white hover:bg-[#4335dc]  text-sm"
                      >
                        Send Code
                      </button>

                      <div>
                        <p className="text-[#4335dc] text-xs p-5 px-10 text-center">
                          {' '}
                          We sent a verification code to your phone. If you don't receive an SMS
                          message within 3 minutes check your phone number and resubmit it to recond
                          the code
                        </p>
                      </div>
                    </div>
                  </div>
                </form>
              )}

              {requestStatus === 'success' && (
                <div className="border m-4 rounded-lg bg-white mb-20">
                  <form onSubmit={handleSubmit}>
                    <div className="md:flex md:justify-between">
                      <div className="flex flex-col m-4 bg-white md:w-full">
                        <label htmlFor="">Phone Number</label>
                        <input
                          type="text"
                          placeholder="+1 555-555-5555"
                          value={mobileNumber}
                          onChange={(e) => setMobileNumber(e.target.value)}
                          className="bg-white mb-4 md:mb-0 border-2 border-gray-200 rounded  py-2 px-2 text-gray-700 focus:outline-none  focus:border-blue-500 mt-1"
                        />
                        <button
                          type="submit"
                          onClick={handleClick}
                          className="bg-white text-[#4335dc] border border-[#4335dc] px-3 py-2 rounded mt-3 hover:text-white hover:bg-[#4335dc] text-sm"
                          disabled={disabled}
                        >
                          {disabled ? `Resend Code in ${countdown} seconds` : 'Resend Code'}
                        </button>

                        <div>
                          <p className="text-[#4335dc] text-xs p-5 px-10 text-center">
                            {' '}
                            We sent a verification code to vour phone. If vou don't receive an SMS
                            message within 3 minutes check vour phone number and resubmit it to
                            recond the code
                          </p>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="m-4">
                    <form onSubmit={(e) => handleVerification(e)}>
                      <div className="flex flex-col bg-white mt-10 md:mt-4 md:w-full">
                        <label htmlFor="">Enter Verification Code</label>
                        <input
                          type="text"
                          className="bg-white mb-4 md:mb-0 border-2 border-gray-200 rounded  py-2 px-2 text-gray-700 focus:outline-none  focus:border-blue-500 mt-1"
                          name="otp"
                          onChange={(e) => {
                            setOtp(e.target.value);
                            if (otpErrorMessage) setOtpErrorMessage('');
                          }}
                          value={otp}
                          required
                        />
                        {otpErrorMessage && <p className="text-red-500">{otpErrorMessage}</p>}
                        <button
                          type="submit"
                          className="bg-[#4335dc] border border-[#4335dc] px-3 py-2 rounded mt-3 text-white text-sm hover:bg-[#4f43db]"
                        >
                          Verify Phone Number
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterVerify;
