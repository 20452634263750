import React from 'react'
import Topbar from '../../layouts/topbar'
import Menu from '../../layouts/menu'
import DemoInbox from './DemoInbox'



const DemoPage = () => {
  return (
    <> 
    <Topbar />
    <Menu />
    <div className="mt-8 max-w-[1240px] mx-auto px-7">
      <DemoInbox />
    </div>
  </>
  )
}

export default DemoPage