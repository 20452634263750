import React from 'react';
import Topbar from '../../layouts/topbar';
import Menu from '../../layouts/menu';
import Sidebar from '../../layouts/sidebar';

// import Users from './Users'
import UserComponent from './UserCard';

const Dashboard = () => {
  return (
    <>
      <Topbar />
      <Menu />
      <div className="mt-8 lg:flex lg:justify-start max-w-[1240px] mx-auto px-7">
        <Sidebar />
        <div>
          <UserComponent />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
