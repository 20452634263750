import React, { useState, useContext, useEffect, useCallback } from 'react';
import UserCard from '../../components/Cards/UserCard';
import axios from 'axios';
import { AiOutlineSearch, AiTwotoneBulb } from 'react-icons/ai';
import PaginationUsers from './PaginationUsers';
// import { useLocation } from 'react-router-dom';

const UserComponent = () => {
  const [users, setUsers] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(5);

  const [filters, setFilters] = useState({
    network: '',
    industry: '',
    profileNumber: '',
  });
  // how many posts do you want to show per page

  const token = localStorage.getItem('token');

  // const { search } = useLocation();
  // const query = new URLSearchParams(search);
  // const paramValue = query.get('redirect_success');

  const getUser = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://fargo-server.herokuapp.com/allUsers?network=${filters.network}&industry=${filters.industry}&profileNumber=${filters.profileNumber}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log('response.data.getAllUsers', response.data.getAllUsers);
      setUsers(response.data.getAllUsers.reverse());
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [setUsers, setLoading, filters]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  useEffect(() => {
    const getCurrentUser = async () => {
      try {
        // get my profile
        const response = await axios.get('https://fargo-server.herokuapp.com/profile', {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log('logged in user data', response.data.usersInfo);
        setLoggedInUser(response.data.usersInfo);
      } catch (error) {
        console.log(error);
      }
    };
    // const getUser = async () => {
    //   try {
    //     const response = await axios.get('/allUsers', {
    //       headers: { Authorization: `Bearer ${token}` },
    //     });
    //     if (response.status === 200 && response.data.getAllUsers && loggedInUser._id) {
    //       let usersExceptLoggedInUser = response.data.getAllUsers.filter(
    //         (elemUser) => elemUser._id !== loggedInUser._id
    //       );
    //       setUsers(usersExceptLoggedInUser);
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };
    getCurrentUser();
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (!value) {
      if (query[name]) {
        let queryCopy = { ...query };
        delete queryCopy[name];
        setQuery({ ...queryCopy });
        return;
      }
      return;
    }
    setQuery({ ...query, [name]: value });
    console.log(query, 'query');
  };

  const searchUsers = async (e) => {
    e.preventDefault();
    if (!Object.keys(query).length) return;
    try {
      const response = await axios.get('https://fargo-server.herokuapp.com/search', {
        params: { ...query },
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log('response.data.searchedUsers', response.data.searchedUsers);
      if (response.status === 200) setUsers([response.data.searchedUsers]);
    } catch (error) {
      console.log(error);
    }
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <div className="text-center">
        <div className="">
          {/* search box */}

          <div>
            <form className="hidden lg:flex lg:justify-between ">
              <select
                name="network"
                id="network"
                // onChange={(e) => handleSearch(e)}
                onChange={(e) => setFilters((prev) => ({ ...prev, network: e.target.value }))}
                // value={query.network ? query.network : ''}
                value={filters.network}
                className="border border-[#9f9f9f] rounded  px-4 py-2 flex justify-between items-center w-full mr-2 text-sm text-[#a8a8a8]"
              >
                <option value="">All Networks</option>
                <option value="startups">Startups &amp; Funding Needed</option>
                <option value="business">Business &amp; Private Investors</option>
                <option value="incubators">Incubators</option>
                <option value="accelerators">Accelerators</option>
              </select>
              <select
                name="industry"
                id="industry"
                // onChange={(e) => handleSearch(e)}
                onChange={(e) => setFilters((prev) => ({ ...prev, industry: e.target.value }))}
                // value={query.industry ? query.industry : ''}
                values={filters.industry}
                className="border border-[#9f9f9f] rounded  px-4 py-2 flex justify-between items-center w-full mr-2 text-sm text-[#a8a8a8]"
              >
                <option value="">All Industries</option>
                <option value="Aerospace">Aerospace</option>
                <option value="Agriculture">Agriculture</option>
                <option value="Biotechnology">Biotechnology</option>
                <option value="Business Products">Business Products</option>
                <option value="Business Services">Business Services</option>
                <option value="Chemicals and Chemical Products">
                  Chemicals and Chemical Products
                </option>
                <option value="Clean Technology">Clean Technology</option>
                <option value="Computers and Peripherals">Computers and Peripherals</option>
                <option value="Construction">Construction</option>
                <option value="Consulting">Consulting</option>
                <option value="Consumer Products">Consumer Products</option>
                <option value="Consumer Services">Consumer Services</option>
                <option value="Digital Marketing">Digital Marketing</option>
                <option value="Education">Education</option>
                <option value="Electronics / Instrumentation">Electronics / Instrumentation</option>
                <option value="Fashion">Fashion</option>
                <option value="Financial Services">Financial Services</option>
                <option value="Fintech">Fintech</option>
                <option value="Food and Beverage">Food and Beverage</option>
                <option value="Gaming">Gaming</option>
                <option value="Healthcare Services">Healthcare Services</option>
                <option value="Industrial/Energy">Industrial/Energy</option>
                <option value="Internet / Web Services">Internet / Web Services</option>
                <option value="IT Services">IT Services</option>
                <option value="Legal">Legal</option>
                <option value="Lifestyle">Lifestyle</option>
                <option value="Marine">Marine</option>
                <option value="Maritime/Shipping">Maritime/Shipping</option>
                <option value="Marketing / Advertising">Marketing / Advertising</option>
                <option value="Media and Entertainment">Media and Entertainment</option>
                <option value="Medical Devices and Equipment">Medical Devices and Equipment</option>
                <option value="Mining">Mining</option>
                <option value="Mobile">Mobile</option>
                <option value="Nanotechnology">Nanotechnology</option>
                <option value="Networking and Equipment">Networking and Equipment</option>
                <option value="Oil and Gas">Oil and Gas</option>
                <option value="Other">Other</option>
                <option value="Real Estate &amp; Construction">
                  Real Estate &amp; Construction
                </option>
                <option value="Retailing / Distribution">Retailing / Distribution</option>
                <option value="Robotics">Robotics</option>
                <option value="Security">Security</option>
                <option value="Semiconductors">Semiconductors</option>
                <option value="Software">Software</option>
                <option value="Sports">Sports</option>
                <option value="Telecommunications">Telecommunications</option>
                <option value="Transportation">Transportation</option>
                <option value="Travel">Travel</option>
              </select>
              <select
                name="country"
                id="country"
                onChange={(e) => handleSearch(e)}
                value={query.country ? query.country : ''}
                className="border border-[#9f9f9f] rounded  px-4 py-2 flex justify-between items-center w-full mr-2 text-sm text-[#a8a8a8]"
              >
                <option value="">All Countries</option>
                <option value="Afghanistan">Afghanistan</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Andorra">Andorra</option>
                <option value="Angola">Angola</option>
                <option value="Anguilla">Anguilla</option>
                <option value="Antarctica">Antarctica</option>
                <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                <option value="Argentina">Argentina</option>
                <option value="Armenia">Armenia</option>
                <option value="Aruba">Aruba</option>
                <option value="Australia">Australia</option>
                <option value="Austria">Austria</option>
                <option value="Azerbaijan">Azerbaijan</option>
                <option value="Bahamas">Bahamas</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Barbados">Barbados</option>
                <option value="Belarus">Belarus</option>
                <option value="Belgium">Belgium</option>
                <option value="Belize">Belize</option>
                <option value="Benin">Benin</option>
                <option value="Bermuda">Bermuda</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                <option value="Botswana">Botswana</option>
                <option value="Bouvet Island">Bouvet Island</option>
                <option value="Brazil">Brazil</option>
                <option value="British Indian Ocean Territory">
                  British Indian Ocean Territory
                </option>
                <option value="Brunei Darussalam">Brunei Darussalam</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Burkina Faso">Burkina Faso</option>
                <option value="Burundi">Burundi</option>
                <option value="Cambodia">Cambodia</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Cape Verde">Cape Verde</option>
                <option value="Cayman Islands">Cayman Islands</option>
                <option value="Central African Republic">Central African Republic</option>
                <option value="Chad">Chad</option>
                <option value="Chile">Chile</option>
                <option value="China">China</option>
                <option value="Christmas Island">Christmas Island</option>
                <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                <option value="Colombia">Colombia</option>
                <option value="Comoros">Comoros</option>
                <option value="Congo">Congo</option>
                <option value="Cook Islands">Cook Islands</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Cote D'Ivoire">Cote D'Ivoire</option>
                <option value="Croatia">Croatia</option>
                <option value="Cuba">Cuba</option>
                <option value="Cyprus">Cyprus</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Democratic Republic of Congo">Democratic Republic of Congo</option>
                <option value="Denmark">Denmark</option>
                <option value="Djibouti">Djibouti</option>
                <option value="Dominica">Dominica</option>
                <option value="Dominican Republic">Dominican Republic</option>
                <option value="East Timor">East Timor</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Equatorial Guinea">Equatorial Guinea</option>
                <option value="Eritrea">Eritrea</option>
                <option value="Estonia">Estonia</option>
                <option value="Ethiopia">Ethiopia</option>
                <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                <option value="Faroe Islands">Faroe Islands</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="France, Metropolitan">France, Metropolitan</option>
                <option value="French Guiana">French Guiana</option>
                <option value="French Polynesia">French Polynesia</option>
                <option value="French Southern Territories">French Southern Territories</option>
                <option value="Gabon">Gabon</option>
                <option value="Gambia">Gambia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                <option value="Gibraltar">Gibraltar</option>
                <option value="Greece">Greece</option>
                <option value="Greenland">Greenland</option>
                <option value="Grenada">Grenada</option>
                <option value="Guadeloupe">Guadeloupe</option>
                <option value="Guam">Guam</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Guinea">Guinea</option>
                <option value="Guinea-bissau">Guinea-bissau</option>
                <option value="Guyana">Guyana</option>
                <option value="Haiti">Haiti</option>
                <option value="Heard and Mc Donald Islands">Heard and Mc Donald Islands</option>
                <option value="Honduras">Honduras</option>
                <option value="Hong Kong">Hong Kong</option>
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="India">India</option>
                <option value="Indonesia">Indonesia</option>
                <option value="Iran (Islamic Republic of)">Iran (Islamic Republic of)</option>
                <option value="Iraq">Iraq</option>
                <option value="Ireland">Ireland</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Jamaica">Jamaica</option>
                <option value="Japan">Japan</option>
                <option value="Jordan">Jordan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kenya">Kenya</option>
                <option value="Kiribati">Kiribati</option>
                <option value="Korea, Republic of">Korea, Republic of</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Kyrgyzstan">Kyrgyzstan</option>
                <option value="Lao People's Democratic Republic">
                  Lao People's Democratic Republic
                </option>
                <option value="Latvia">Latvia</option>
                <option value="Lebanon">Lebanon</option>
                <option value="Lesotho">Lesotho</option>
                <option value="Liberia">Liberia</option>
                <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                <option value="Liechtenstein">Liechtenstein</option>
                <option value="Lithuania">Lithuania</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Macau">Macau</option>
                <option value="Macedonia">Macedonia</option>
                <option value="Madagascar">Madagascar</option>
                <option value="Malawi">Malawi</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Maldives">Maldives</option>
                <option value="Mali">Mali</option>
                <option value="Malta">Malta</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Martinique">Martinique</option>
                <option value="Mauritania">Mauritania</option>
                <option value="Mauritius">Mauritius</option>
                <option value="Mayotte">Mayotte</option>
                <option value="Mexico">Mexico</option>
                <option value="Micronesia, Federated States of">
                  Micronesia, Federated States of
                </option>
                <option value="Moldova, Republic of">Moldova, Republic of</option>
                <option value="Monaco">Monaco</option>
                <option value="Mongolia">Mongolia</option>
                <option value="Montserrat">Montserrat</option>
                <option value="Morocco">Morocco</option>
                <option value="Mozambique">Mozambique</option>
                <option value="Myanmar">Myanmar</option>
                <option value="Namibia">Namibia</option>
                <option value="Nauru">Nauru</option>
                <option value="Nepal">Nepal</option>
                <option value="Netherlands">Netherlands</option>
                <option value="Netherlands Antilles">Netherlands Antilles</option>
                <option value="New Caledonia">New Caledonia</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Niger">Niger</option>
                <option value="Nigeria">Nigeria</option>
                <option value="Niue">Niue</option>
                <option value="Norfolk Island">Norfolk Island</option>
                <option value="North Korea">North Korea</option>
                <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                <option value="Norway">Norway</option>
                <option value="Oman">Oman</option>
                <option value="Pakistan">Pakistan</option>
                <option value="Palau">Palau</option>
                <option value="Panama">Panama</option>
                <option value="Papua New Guinea">Papua New Guinea</option>
                <option value="Paraguay">Paraguay</option>
                <option value="Peru">Peru</option>
                <option value="Philippines">Philippines</option>
                <option value="Pitcairn">Pitcairn</option>
                <option value="Poland">Poland</option>
                <option value="Portugal">Portugal</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Qatar">Qatar</option>
                <option value="Reunion">Reunion</option>
                <option value="Romania">Romania</option>
                <option value="Russian Federation">Russian Federation</option>
                <option value="Rwanda">Rwanda</option>
                <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                <option value="Saint Lucia">Saint Lucia</option>
                <option value="Saint Vincent and the Grenadines">
                  Saint Vincent and the Grenadines
                </option>
                <option value="Samoa">Samoa</option>
                <option value="San Marino">San Marino</option>
                <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Senegal">Senegal</option>
                <option value="Serbia">Serbia</option>
                <option value="Seychelles">Seychelles</option>
                <option value="Sierra Leone">Sierra Leone</option>
                <option value="Singapore">Singapore</option>
                <option value="Slovak Republic">Slovak Republic</option>
                <option value="Slovenia">Slovenia</option>
                <option value="Solomon Islands">Solomon Islands</option>
                <option value="Somalia">Somalia</option>
                <option value="South Africa">South Africa</option>
                <option value="South Georgia &amp; South Sandwich Islands">
                  South Georgia &amp; South Sandwich Islands
                </option>
                <option value="Spain">Spain</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="St. Helena">St. Helena</option>
                <option value="St. Pierre and Miquelon">St. Pierre and Miquelon</option>
                <option value="Sudan">Sudan</option>
                <option value="Suriname">Suriname</option>
                <option value="Svalbard and Jan Mayen Islands">
                  Svalbard and Jan Mayen Islands
                </option>
                <option value="Swaziland">Swaziland</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                <option value="Taiwan">Taiwan</option>
                <option value="Tajikistan">Tajikistan</option>
                <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                <option value="Thailand">Thailand</option>
                <option value="Togo">Togo</option>
                <option value="Tokelau">Tokelau</option>
                <option value="Tonga">Tonga</option>
                <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                <option value="Tunisia">Tunisia</option>
                <option value="Turkey">Turkey</option>
                <option value="Turkmenistan">Turkmenistan</option>
                <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                <option value="Tuvalu">Tuvalu</option>
                <option value="Uganda">Uganda</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Emirates">United Arab Emirates</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="United States">United States</option>
                <option value="United States Minor Outlying Islands">
                  United States Minor Outlying Islands
                </option>
                <option value="Uruguay">Uruguay</option>
                <option value="Uzbekistan">Uzbekistan</option>
                <option value="Vanuatu">Vanuatu</option>
                <option value="Vatican City State (Holy See)">Vatican City State (Holy See)</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Viet Nam">Viet Nam</option>
                <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                <option value="Virgin Islands (U.S.)">Virgin Islands (U.S.)</option>
                <option value="Wallis and Futuna Islands">Wallis and Futuna Islands</option>
                <option value="Western Sahara">Western Sahara</option>
                <option value="Yemen">Yemen</option>
                <option value="Zambia">Zambia</option>
                <option value="Zimbabwe">Zimbabwe</option>
              </select>
              <input
                type="text"
                name="profileNumber"
                placeholder="Enter Profile ID"
                // onChange={(e) => handleSearch(e)}
                onChange={(e) => setFilters((prev) => ({ ...prev, profileNumber: e.target.value }))}
                // value={query.profileNumber ? query.profileNumber : ''}
                value={filters.profileNumber}
                className="border border-[#9f9f9f] rounded  px-4 py-2 flex justify-between items-center w-full mr-2 text-sm text-[#000]"
              />
              <div
                className="border px-4 py-2 mt-2 lg:mt-0 flex justify-center items-center lg:w-30 
                  
                   bg-[#17a2b8]  border-[#17a2b8] rounded text-sm text-[#fff]"
              >
                <AiOutlineSearch className="mr-2" />
                <button onClick={getUser}>Search</button>
              </div>
            </form>
            <div className="flex justify-left items-center bg-[#7867a7] text-white font-normal text-lg py-2 pl-5 lg:mx-0 rounded my-3">
              <AiTwotoneBulb className="mr-3 text-3xl" /> Network
            </div>
          </div>
        </div>
      </div>

      {loading && !Object.keys(loggedInUser).length && (
        <div>
          <div class="my-32 flex items-center justify-center">
            <div class="absolute w-12 h-12 rounded-full border-8 border-solid border-gray-200"></div>
            <div class="w-12 h-12 rounded-full animate-spin border-8 border-solid border-purple-500 border-t-transparent shadow-md"></div>
          </div>
        </div>
      )}

      {Object.keys(loggedInUser).length && (
        <UserCard
          showFriendIcon={true}
          showFavoriteIcon={true}
          showNoteIcon={true}
          showContactButtons={true}
          showEditIcon={false}
          hideAbbreviateName={true}
          user={loggedInUser}
          users={currentUsers}
          loading={loading}
          // refreshUsers={getUser}
        />
      )}
      <PaginationUsers usersPerPage={usersPerPage} totalUsers={users.length} paginate={paginate} />
      {/* {paramValue === 'succeeded' && <p>Payment Successful</p>} */}
    </>
  );
};

export default UserComponent;
