import { useContext, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import './menu.css';
import { FiUsers, FiUserPlus } from 'react-icons/fi';
import { TbNotes } from 'react-icons/tb';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { AiOutlineHeart, AiOutlineMail } from 'react-icons/ai';
import { BsMailbox } from 'react-icons/bs';
import { useEffect } from 'react';
import axios from 'axios';
import { AppContext } from '../Context/context';

const Menu = () => {
  const location = useLocation();
  const [user, setUser] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [messageObjs, setMessageObjs] = useState([]);
  const { updateMenu } = useContext(AppContext);
  const token = localStorage.getItem('token');
  // get loggedin user
  useEffect(() => {
    // Create a async function called getUser (wrap inside of a tryCatch block)

    const getMessages = async () => {
      try {
        const response = await axios.get('https://fargo-server.herokuapp.com/messages', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setMessageObjs(response.data.myMessages);
      } catch (error) {}
    };

    const getUser = async () => {
      try {
        // create a variable called response, and call the getProfile API
        const response = await axios.get('https://fargo-server.herokuapp.com/profile', {
          // The header is to verify the user has authorization to call this API
          headers: { Authorization: `Bearer ${token}` },
        });

        // setting the data we get for the get profile onto user state
        console.log(response.data.usersInfo, 'response.data.usersInfo');
        setUser(response.data.usersInfo);

        // console.log(response.data.usersInfo.friendRequestsReceived.length, 'friendRequestsReceived')
      } catch (error) {
        // { message: "Success"}
        console.log(error);
      }
    };
    // Call the function outside of itself
    setLoading(true);
    getUser();
    getMessages();
    setLoading(false);
  }, [updateMenu]);

  return (
    <>
      <div>
        <div className="m-10 max-w-[1240px] mx-auto px-7">
          <div className="text-3xl ">
            <h1>Network</h1>
          </div>
          <div>
            {/* <ul className="px-2 text-center items-center justify-end mt-10 hidden md:flex "> */}
            <ul className="flex px-2 text-center items-center justify-end mt-10">
              <NavLink to="/friends">
                <li
                  style={{
                    backgroundColor: location.pathname === '/friends' ? '#b1b1b1' : undefined,
                  }}
                  className=" bg-[#e8e7e779] hover:bg-[#dbdbdb] cursor-pointer  font-normal hover:text-blue py-2 px-4  hover:border-transparent rounded-l-lg"
                >
                  <div className="flex justify-start items-center">
                    <FiUsers size={24} className="mr-2" />
                    <div className="flex justify-start items-center">
                      <div className="hidden md:flex md:mr-1">My Friends</div>{' '}
                      <span className="bg-[#ffc107] w-6 h-6 rounded-full">
                        {' '}
                        {!user && !isLoading ? 0 : user.friends.length}
                      </span>
                    </div>
                  </div>
                </li>
              </NavLink>

              <NavLink to="/requests">
                <li
                  style={{
                    backgroundColor: location.pathname === '/requests' ? '#b1b1b1' : undefined,
                  }}
                  className=" bg-[#e8e7e779] hover:bg-[#dbdbdb] cursor-pointer  font-normal hover:text-blue py-2 px-4  hover:border-transparent"
                >
                  <div className="flex justify-start items-center">
                    <FiUserPlus size={24} className="mr-2" />

                    <div className="flex justify-start items-center">
                      <div className="hidden md:flex md:mr-1">Friend Requests</div>
                      <span className="bg-[#ffc107] w-6 h-6 rounded-full">
                        {!user && !isLoading ? 0 : user.friendRequestsReceived.length}
                      </span>
                      {/* <span className="bg-[#ffc107] w-6 h-6 rounded-full">
                        {(!user&&isLoading)&&
                        0
                        }</span> */}
                    </div>
                  </div>
                </li>
              </NavLink>

              <NavLink to="/notes">
                <li
                  style={{
                    backgroundColor: location.pathname === '/notes' ? '#b1b1b1' : undefined,
                  }}
                  className=" bg-[#e8e7e779] hover:bg-[#dbdbdb] cursor-pointer  font-normal hover:text-blue py-2 px-4  hover:border-transparent "
                >
                  <div className="flex justify-start items-center">
                    <TbNotes size={24} className="mr-2" />

                    <div className="flex justify-start items-center">
                      <div className="hidden md:flex md:mr-1">My Notes</div>{' '}
                      <span className="bg-[#ffc107] w-6 h-6 rounded-full">
                        {!user && !isLoading ? 0 : user.notes.length}
                      </span>
                    </div>
                  </div>
                </li>
              </NavLink>
              <NavLink to="/favorites">
                <li
                  style={{
                    backgroundColor: location.pathname === '/favorites' ? '#b1b1b1' : undefined,
                  }}
                  className=" bg-[#e8e7e779] hover:bg-[#dbdbdb] cursor-pointer   font-normal hover:text-blue py-2 px-4  hover:border-transparent "
                >
                  <div className="flex justify-start items-center">
                    <AiOutlineHeart size={24} className="mr-2" />
                    <div className="flex justify-start items-center">
                      <div className="hidden md:flex md:mr-1">My Favorites</div>{' '}
                      <span className="bg-[#ffc107] w-6 h-6 rounded-full">
                        {' '}
                        {!user && !isLoading ? 0 : user.favorites.length}
                      </span>
                    </div>
                  </div>
                </li>
              </NavLink>

              {/* <div>
                <div className="dropdown inline-block relative">
                  <button className="bg-[#e8e7e779] hover:bg-[#dbdbdb] cursor-pointer font-normal hover:text-blue py-2 px-4  hover:border-transparent rounded-r-lg  ">
                    <div className='flex justify-start items-center'>
                      <AiOutlineMail size={24} className="mr-2" />

                      <div className='flex justify-start items-center'>
                      <div  className='hidden md:flex md:mr-1'>Inbox</div>  <span className="bg-[#ffc107] w-6 h-6 rounded-full">0</span>
                    </div>


                    </div>
                  </button>

                  <ul className="dropdown-menu absolute hidden text-gray-700 pt-1">


                    <div className="absolute  border-[#787878] shadow-2xl w-80 top-0 right-[-127px] bg-white">
                      <div className="text-right text-[#777777] hover:text-[#777777] pr-2 border-[#c1c1c1] py-1 bg-[#fff]">
                      </div>
                      <div className="text-left  text-[#838383] text-sm">
                      <p>You currently have no ongoing conversations</p>
                       </div>
                      <div>
                        <NavLink to="/inbox">
                          <button className="text-white text-sm w-full py-3 bg-[#E44D3A] hover:bg-[#bf4030]">
                            View All Messages
                          </button>
                        </NavLink>
                      </div>
                    </div>
                  </ul>
                </div>
              </div> */}

              <div>
                <div class="dropdown inline-block relative">
                  <button class="bg-[#e8e7e779] hover:bg-[#dbdbdb] cursor-pointer font-normal hover:text-blue py-2 px-4  hover:border-transparent rounded-r-lg  ">
                    <div className="flex justify-start items-center">
                      <AiOutlineMail size={24} className="mr-2" />

                      <div className="flex justify-start items-center">
                        <div className="hidden md:flex md:mr-1">Inbox</div>{' '}
                        <span className="bg-[#ffc107] w-6 h-6 rounded-full">0</span>
                      </div>
                    </div>
                  </button>

                  <ul class="dropdown-menu absolute hidden text-gray-700 pt-1 z-[99999]">
                    <div className="absolute  border-[#787878] shadow-2xl w-80 top-0 right-[-127px]">
                      {!user?.isPro ? (
                        <div className="bg-white text-center uppercase p-5">
                          <NavLink to="/upgrade">
                            <button className="bg-[#28A844] uppercase font-semibold hover:bg-[#009BD2] cursor-pointer text-white px-3 rounded-md py-2 mb-5 transition duration-300 ease-in-out hover:shadow-sm">
                              Upgrade now
                            </button>
                          </NavLink>
                          <div className="flex justify-start items-center">
                            <IoMdCheckmarkCircleOutline className="text-[#18ed11] text-lg font-semibold mr-2" />
                            <div className="text-base font-semibold text-black">
                              100% inbox access
                            </div>
                          </div>
                          <div className="flex justify-start items-center">
                            <IoMdCheckmarkCircleOutline className="text-[#18ed11] text-lg font-semibold mr-2" />
                            <div className="text-base font-semibold text-black">
                              instant chat messenger
                            </div>
                          </div>
                          <div className="flex justify-start items-center">
                            <IoMdCheckmarkCircleOutline className="text-[#18ed11] text-lg font-semibold mr-2" />
                            <div className="text-base font-semibold text-black">
                              send & receive messages
                            </div>
                          </div>
                        </div>
                      ) : !messageObjs.length ? (
                        <div className="bg-[#fff]">
                          <div>
                            <BsMailbox className="mx-auto mt-4 text-blue-500" size={48} />
                          </div>
                          <div className="mx-auto my-4">
                            <h4 className="uppercase font-semibold">You have no messages</h4>
                            <p className="text-sm px-6">
                              Your inbox is empty. Send a message to a friend to get started.
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div>
                          {messageObjs.slice(0, 3).map((messageObj) => {
                            return (
                              <div className="bg-[#fff] flex justify-between p-2 hover:bg-[#f8f8f8] cursor-pointer	">
                                <div className="">
                                  <div className="flex justify-start items-start">
                                    <img
                                      src="https://i.imgur.com/1Ge7acx.png"
                                      alt=""
                                      className="w-14 rounded-full"
                                    />
                                    <div className="ml-2">
                                      <div className="text-sm font-bold text-left">
                                        {' '}
                                        {messageObj.conversations[0].receiverFullname ===
                                        user.fullName
                                          ? messageObj.conversations[0].senderFullname
                                          : messageObj.conversations[0].receiverFullname}
                                      </div>
                                      <div className="text-left text-sm">
                                        {' '}
                                        {
                                          messageObj.conversations[
                                            messageObj.conversations.length - 1
                                          ].message
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="text-xs">March 3rd 2:23pm</div>
                              </div>
                            );
                          })}
                        </div>
                      )}

                      <div>
                        <NavLink to={`${user?.isPro ? '/inbox' : '/upgrade'}`}>
                          <button className="text-white text-sm w-full py-3 bg-[#E44D3A] hover:bg-[#bf4030]">
                            View All Messages
                          </button>
                        </NavLink>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </ul>
          </div>
          <div className="h-5 border-b-2 border-[#b4b4b4] text-center text-2xl "></div>
        </div>
      </div>
    </>
  );
};

export default Menu;

/* {!user.pro ? (
                        <div className="text-left  text-[#838383] text-sm">
                          {!user.pro ? (
                            <p>You currently have no ongoing conversations</p>
                          ) : (
                            <div className="bg-white text-center uppercase p-5">
                              <NavLink to="/upgrade">
                                <button className="bg-[#28A844] uppercase font-semibold hover:bg-[#208636] cursor-pointer text-white px-3 rounded-md py-2 mb-5">
                                  Upgrade now
                                </button>
                              </NavLink>
                              <div className="flex justify-start items-center">
                                <IoMdCheckmarkCircleOutline className="text-[#18ed11] text-lg font-semibold mr-2" />
                                <div className="text-base font-semibold text-black">
                                  100% inbox access
                                </div>
                              </div>
                              <div className="flex justify-start items-center">
                                <IoMdCheckmarkCircleOutline className="text-[#18ed11] text-lg font-semibold mr-2" />
                                <div className="text-base font-semibold text-black">
                                  instant chat messenger
                                </div>
                              </div>
                              <div className="flex justify-start items-center">
                                <IoMdCheckmarkCircleOutline className="text-[#18ed11] text-lg font-semibold mr-2" />
                                <div className="text-base font-semibold text-black">
                                  send & receive messages
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        // (conversations && conversations.conversations)
                        // &&
                        // conversations.conversations.map((data, index) => {
                        //   // const other_user_id = response.data.conversation.members.filter( instance => instance != user._id.toString())[0];
                        //   return <ChatLink
                        //     key={index}
                        //     members={data.members}
                        //     id={data._id}
                        //   />
                        // })
                        <div>test</div>
                      )} */
