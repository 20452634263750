import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import UserCard from '../../components/Cards/UserCard';
import addFavoriteChart from './noFavorites.png';
import { AppContext } from '../../Context/context';
import { AiTwotoneBulb } from 'react-icons/ai';

const Favorites = () => {
  const [favorites, setFavorites] = useState([]);
  const token = localStorage.getItem('token');
  const [isLoading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const { updateFavorites } = useContext(AppContext);

  useEffect(() => {
    async function getFavorites() {
      try {
        const response = await axios.get('https://fargo-server.herokuapp.com/profile', {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log(response.data.usersInfo.favorites, 'setFavorites');
        setUser(response.data.usersInfo);
        setFavorites(response.data.usersInfo.favorites);
      } catch (error) {
        console.log(error);
      }
    }
    setLoading(true);
    getFavorites();
    setLoading(false);
  }, [updateFavorites]);

  return (
    <>
      {isLoading && <p>Loading......</p>}
      {!favorites.length && !isLoading && (
        <div className="text-center">
          <h1 className="text-xl mt-5">
            You currently have no users saved as <span className="font-bold"> Favorites</span>.
          </h1>
          <p className="my-4 px-4">
            To begin, click the <span className="font-bold">Add To Favorites</span> icon underneath
            the users Profile Picture.
          </p>
          <img className="w-4/5 mx-auto mt-10" src={addFavoriteChart} alt="" />
        </div>
      )}
      {!isLoading && favorites.length && (
        <div>
          <div className="flex justify-left items-center bg-[#7867a7] text-white font-normal text-lg py-2 pl-5 lg:mx-0 rounded mb-3">
            <AiTwotoneBulb className="mr-3 text-3xl" /> My Favorites
          </div>
          <UserCard
            showFriendIcon={true}
            showFavoriteIcon={true}
            showNoteIcon={true}
            showContactButtons={true}
            acceptRejectFriend={false}
            users={favorites}
            user={user}
          />
        </div>
      )}
    </>
  );
};

export default Favorites;

// now, we're going to dusplay all the email that are inside of our favorites key
// create a useEffect, and call it only once when the app loads up
// inside the useEffect, create a getFavorites
// create a try catch block
// call the axios.get function <- to get all profiles <- store this in a response variable
// create a useState for favorites and set it to an array
// use setFavorites and make the argument the data you want response.data.usersInfo.favorites
// call the function outside of the function.
