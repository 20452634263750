import React from 'react';
import Header from '../../layouts/header';
import Footer from '../../layouts/footer';
import About from './About';
import AllInOne from './AllInOne';
import GetStarted from './GetStarted';
import Hero from './Hero';
import Network from './Network';
import Support from './Support';

const Homepage = () => {
  return (
    <>
      <Header />
      <Hero />
      <About />
      <Network />
      <Support />
      <AllInOne />
      <GetStarted />
      <Footer />
    </>
  );
};

export default Homepage;
