import axios from 'axios';
import React, { useState, useEffect } from 'react';
import UserCard from '../../components/Cards/UserCard';
import noFriends from './noImage.png';
import { AiTwotoneBulb } from 'react-icons/ai';

const Friend = () => {
  const [user, setUser] = useState('');
  const [isLoading, setLoading] = useState(false);
  const token = localStorage.getItem('token');

  useEffect(() => {
    const myProfile = async () => {
      const response = await axios.get('https://fargo-server.herokuapp.com/friends', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUser(response.data);
    };

    setLoading(true);
    myProfile();
    setLoading(false);

    console.log('user -> ', user);
  }, []);

  //useEffect runs as soon as the component renders.

  return (
    <>
      <div>
        {user.friends && user.friends.length === 0 && (
          <div className="text-center">
            <h1 className="text-xl mt-5">
              You currently have no users added as <span className="font-bold">Friends</span>.
            </h1>
            <p className="my-4 px-4">
              To begin, click the <span className="font-bold">Add To Friends</span> icon underneath
              the users Profile Picture. <br></br> When the user{' '}
              <span className="font-bold">accepts</span> your friend request, it'll be saved on this
              page.
            </p>
            <img className="w-4/5 mx-auto mt-10" src={noFriends} alt="" />
          </div>
        )}
        {!isLoading && Object.keys(user).length > 0 && (
          <div>
            {user.friends.map((currentUser, index) => {
              return (
                <div>
                  <>
                    <div className="flex justify-left items-center bg-[#7867a7] text-white font-normal text-lg py-2 pl-5 lg:mx-0 rounded mb-3">
                      <AiTwotoneBulb className="mr-3 text-3xl" /> Network
                    </div>
                    <UserCard
                      showFriendIcon={true}
                      showFavoriteIcon={true}
                      showNoteIcon={true}
                      showContactButtons={true}
                      acceptRejectFriend={false}
                      users={[currentUser]}
                      setUser={setUser}
                      user={user}
                    />
                  </>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default Friend;
