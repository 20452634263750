import React from 'react'
import { Link } from 'react-router-dom'

const Pagination = ({ ticketsPerPage, totalTickets, paginate }) => {
    const pageNumbers = []

    for (let i = 1; i <= Math.ceil(totalTickets / ticketsPerPage); i++) {
        pageNumbers.push(i)
        
    }
  return (
    <nav>
        <ul className='flex'>
            {pageNumbers.map(number => (

            <li key={number} className="border p-2">
                <Link onClick={() => paginate(number)} to={"#"}>
                    {number}
                </Link>
            </li>
            ))}
        </ul>
    </nav>
  )
}

export default Pagination