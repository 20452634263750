import React, { useEffect, useState, useCallback } from 'react';
import Topbar from '../../../layouts/topbar';
import Menu from '../../../layouts/menu';
import Sidebar from '../../../layouts/sidebar';
import Purchase from './Purchase';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

// const stripePromise = ;
const stripePromise = loadStripe(
  'pk_test_51I9f99Kxlg1KCLq7BEXnJQ7lsZfsDCb90ASudmiZURz4kP1sa2MnpVYrrG4I9Q89J713FCJQSWtwWaVyGdt7gwdo001a1VWlpt'
);

const PurchasePage = () => {
  const { subId } = useParams();
  const [clientSecret, setClientSecret] = useState(null);
  const token = localStorage.getItem('token');

  const initializePaymentPage = useCallback(async () => {
    try {
      const response = await axios.post(
        'https://fargo-server.herokuapp.com/subscription/init',
        {
          subscriptionId: subId, // this is the id of the subscription plan
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setClientSecret(response.data.clientSecret);

      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [subId, token, setClientSecret]);
  useEffect(() => {
    initializePaymentPage();
  }, [initializePaymentPage]);
  return (
    <>
      <Topbar />
      <Menu />
      <div className="mt-8 lg:flex lg:justify-start max-w-[1240px] mx-auto px-7">
        <Sidebar />
        {stripePromise && clientSecret ? (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <Purchase />
          </Elements>
        ) : (
          <div>Loading payment page...</div>
        )}
      </div>
    </>
  );
};

export default PurchasePage;
