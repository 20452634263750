import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const DemoInbox = () => {
  //first step -> type a message;
  const [loggedInUser, setLoggedInUser] = useState({});
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [activeUser, setActiveUser] = useState('');
  const [users, setUsers] = useState([]);

  const token = localStorage.getItem('token');

  useEffect(() => {
    const getCurrentUser = async () => {
      try {
        // get my profile
        const response = await axios.get('https://fargo-server.herokuapp.com/profile', {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log('logged in user data', response.data.usersInfo);
        setLoggedInUser(response.data.usersInfo);
      } catch (error) {
        console.log(error);
      }
    };
    // get all users profile
    const getUsers = async () => {
      try {
        const response = await axios.get('https://fargo-server.herokuapp.com/allUsers', {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log('users', response.data.getAllUsers);

        setUsers(response.data.getAllUsers);
      } catch (error) {
        console.log(error);
      }
    };
    // get all my messages
    const getMessages = async () => {
      try {
        const response = await axios.get('https://fargo-server.herokuapp.com/messages', {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log('messages', response.data.myMessages);

        setMessages(response.data.myMessages);
      } catch (err) {
        console.log(err);
      }
    };
    getCurrentUser();
    getMessages();
    getUsers();
  }, []);

  const createMessage = async (e, email) => {
    e.preventDefault();
    if (!message || !email) return;
    try {
      const response = await axios.post(
        'https://fargo-server.herokuapp.com/message',
        { message, receiver: email },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log('message response', response.data);

      let indexOfUpdatedMessageObj = messages.findIndex((messageObj) => {
        return messageObj.participants.includes(activeUser);
      });
      console.log('indexOfUpdatedMessageObj', indexOfUpdatedMessageObj);

      if (indexOfUpdatedMessageObj === -1) {
        setMessages((prevstate) => {
          return [...prevstate, response.data.messageObj];
        });
        setMessage('');

        return;
      }

      let newMessages = [...messages];
      newMessages[indexOfUpdatedMessageObj] = response.data.messageObj;
      setMessages([...newMessages]);
      console.log(response.data);
      setMessage('');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {users
        .filter((elemUser) => elemUser._id !== loggedInUser._id)
        .map((user) => {
          return (
            <form action="" className="border-2 border-red-500">
              {user.fullName} <br />
              IsPro: {user?.isPro ? 'Yes' : 'No'}
              {messages.length &&
                messages.map((messageObj) => {
                  if (messageObj.participants.includes(user._id)) {
                    return messageObj.conversations.map((convo, index) => {
                      // do we have an admin message, and the receiver is loggedd in, then show that admin message. but if you're sender, then show the original message

                      // TODO: redo this function
                      return (
                        <p
                          key={index}
                          className={
                            loggedInUser._id === convo.sender ? 'bg-[#ff0505]' : 'bg-[#0307ff]'
                          }
                        >
                          {convo.adminMessage && convo.receiver === loggedInUser._id
                            ? convo.adminMessage
                            : convo.receiver === loggedInUser._id && convo.paid === 'false'
                            ? ''
                            : convo.message}{' '}
                        </p>
                      );
                    });
                  }
                })}
              {/* h hey you are currently typing on user1's textarea
        [{user1},{user2},{user3},{user4},{user5}] -> users array;

        state(activeUser) -> this state will hold the userid of the user that i am currently chatting

        value of the textarea if activeUser!==userId '' */}
              <div className="mt-6 border-t-4 md:flex md:justify-start items-center">
                <div className="m-4">
                  <textarea
                    value={activeUser === user._id ? message : ''}
                    onChange={(e) => {
                      if (activeUser !== user._id) {
                        setActiveUser(user._id);
                      }
                      setMessage(e.target.value);
                    }}
                    name=""
                    id=""
                    cols="100"
                    rows="3"
                    placeholder="Start typing..."
                    className="py-1 px-1 border w-full"
                  ></textarea>
                </div>
                <div className="text-right">
                  <button
                    onClick={(e) => createMessage(e, user._id)}
                    className="bg-[#4335dc] px-5 py-2 rounded mr-4 mb-2 text-white text-sm"
                  >
                    Send
                  </button>
                </div>
              </div>
            </form>
          );
        })}
    </div>
  );
};

export default DemoInbox;
