import React, { useCallback, useState, useEffect } from 'react';
import { BsCheckLg, BsShieldFillCheck } from 'react-icons/bs';
import {RxCrossCircled} from 'react-icons/rx';
import {IoMdCheckmarkCircleOutline} from 'react-icons/io'
// import shape from './shape.png';
// import shapeHover from './shape_hover.png';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Package = () => {
  const navigation = useNavigate();
  const [plans, setPlans] = useState([]);

  const fetchSubscriptions = useCallback(async () => {
    try {
      const response = await axios.get('https://fargo-server.herokuapp.com/plans');
      console.log(response.data);
      setPlans(response.data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchSubscriptions();
  }, [fetchSubscriptions]);

  //   const packageUpdater = () => {
  //     navigation(`/purchase`);
  //   };
  return (
    <>
      {/* {plans?.map((plan) => ( */}
        <div className="w-full md:w-full md:mx-2 relative cursor-default">
          <div className="border border-[#EAE9E6] rounded-t-xl bg-[#EAE9E6] py-5">
            <div className="text-center">
              <h4 className="text-3xl font-bold uppercase">BRONZE</h4>
              <h5 className="border-b text-lg font-light uppercase py-4">SUBSCRIPTION</h5>
              <div className="mx-auto border-black border-b-2 w-10 mb-5 shadow-lg"></div>
              <h3 className="text-5xl uppercase hover:text-[#FCD129]">FREE</h3>
              <h5 className="text-lg font-light pt-3 uppercase">LIFETIME</h5>
            </div>
          </div>
          <div className="absolute w-full">
            {/* <img className="w-full opacity-0 hover:opacity-100" src={shapeHover} alt="" />
            <img className="w-full absolute top-0 left-0 hover:opacity-0" src={shape} alt="" /> */}
          </div>
          <div className="bg-[#FCD129] rounded-b-lg ">
            <div className="h-44 lg:h-32"></div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Receive Messages</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Send Messages </p>
              <RxCrossCircled/>
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Post Ad to Network</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Free Profile Matching</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Unlock 32,000+ Investor Database </p>
              <RxCrossCircled/>
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>View Phone Numbers & URL </p>
              <RxCrossCircled/>
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Private Account Manager </p>
              <RxCrossCircled/>
            </div>
            <div className="text-center py-6 ">
              <button
                className="bg-[#363636] text-white py-2 px-4 rounded-lg"
                // onClick={packageUpdater}
                // onClick={() => navigation(`/purchase/${plan?._id}`)}
              >
                YOUR SUBSCRIPTION  <IoMdCheckmarkCircleOutline/>

              </button>
            </div>
          </div>
        </div>
      {/* // ))} */}
      <div className="w-full md:w-full md:mx-2 relative cursor-default">
          <div className="border border-[#EAE9E6] rounded-t-xl bg-[#EAE9E6] py-5">
            <div className="text-center">
              <h4 className="text-3xl font-bold uppercase">BUSINESS</h4>
              <h5 className="border-b text-lg font-light uppercase py-4">SUBSCRIPTION</h5>
              <div className="mx-auto border-black border-b-2 w-10 mb-5 shadow-lg"></div>
              <h3 className="text-5xl uppercase hover:text-[#FCD129]">$199</h3>
              <h5 className="text-lg font-light pt-3 uppercase">3 MONTHS (USD)</h5>
            </div>
          </div>
          <div className="absolute w-full">
            {/* <img className="w-full opacity-0 hover:opacity-100" src={shapeHover} alt="" />
            <img className="w-full absolute top-0 left-0 hover:opacity-0" src={shape} alt="" /> */}
          </div>
          <div className="bg-[#FCD129] rounded-b-lg ">
            <div className="h-44 lg:h-32"></div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Receive Messages</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Send Messages </p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Post Ad to Network</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Free Profile Matching</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Unlock 32,000+ Investor Database</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>View Phone Numbers & URL</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Private Account Manager</p>
              <BsCheckLg />
            </div>
            <div className="text-center py-6 ">
              <button
                className="bg-[#363636] text-white py-2 px-4 rounded-lg"
                // onClick={packageUpdater}
                // onClick={() => navigation(`/purchase/${plan?._id}`)}
              >
                BUY NOW 
              </button>
            </div>
          </div>
        </div>
        <div className="w-full md:w-full md:mx-2 relative cursor-default">
          <div className="border border-[#EAE9E6] rounded-t-xl bg-[#EAE9E6] py-5">
            <div className="text-center">
              <h4 className="text-3xl font-bold uppercase">CORPORATE</h4>
              <h5 className="border-b text-lg font-light uppercase py-4">SUBSCRIPTION</h5>
              <div className="mx-auto border-black border-b-2 w-10 mb-5 shadow-lg"></div>
              <h3 className="text-5xl uppercase hover:text-[#FCD129]">$295</h3>
              <h5 className="text-lg font-light pt-3 uppercase">LIFETIME (USD)</h5>
            </div>
          </div>
          <div className="absolute w-full">
            {/* <img className="w-full opacity-0 hover:opacity-100" src={shapeHover} alt="" />
            <img className="w-full absolute top-0 left-0 hover:opacity-0" src={shape} alt="" /> */}
          </div>
          <div className="bg-[#FCD129] rounded-b-lg ">
            <div className="h-44 lg:h-32"></div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Receive Messages</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Send Messages</p>
              <BsCheckLg />

            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Post Ad to Network</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Free Profile Matching</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Unlock 32,000+ Investor Database</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>View Phone Numbers & URL</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Private Account Manager</p>
              <BsCheckLg />
            </div>
            <div className="text-center py-6 ">
              <button
                className="bg-[#363636] text-white py-2 px-4 rounded-lg"
                // onClick={packageUpdater}
                // onClick={() => navigation(`/purchase/${plan?._id}`)}
              >
                BUY NOW
              </button>
            </div>
          </div>
        </div>
      {/* <div className="sm:flex md:flex md:justify-between items-center">
        <div className="w-full mb-10 md:w-full md:mx-2 relative cursor-default parent-div">
          <div className="border border-[#EAE9E6] rounded-t-xl bg-[#EAE9E6] py-5">
            <div className="text-center">
              <h4 className="text-3xl font-bold uppercase">Gurinder</h4>
              <h5 className="border-b text-lg font-light uppercase py-4">Name</h5>
              <div className="mx-auto border-black border-b-2 w-10 mb-5 shadow-lg"></div>
              <h3 className="text-5xl uppercase price">Price</h3>
              <h5 className="text-lg font-light pt-3 uppercase">69 Days</h5>
            </div>
          </div>
          <div className="absolute w-full">

            <img class="w-full absolute top-0 left-0 image" alt="" />
          </div>
          <div className="bg-[#FCD129] rounded-b-lg ">
            <div className="h-44 lg:h-32"></div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Receive Messages</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p> Send Messages</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Post Ad to Network</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Free Profile Matching</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Unlock 32,000+ Investor Database</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>View Phone Numbers & URL</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Private Account Manager</p>
              <BsCheckLg />
            </div>
            <div className="text-center py-6 ">
              <button
                className="bg-[#363636] text-white py-2 px-4 rounded-lg flex justify-between items-center mx-auto"

              >
                <p className="mr-2">Your Subscription</p>
                <BsShieldFillCheck className="text-green-500" />
              </button>
            </div>
          </div>
        </div>
        <div className="w-full mb-10 md:w-full md:mx-2 relative cursor-default parent-div">
          <div className="border border-[#EAE9E6] rounded-t-xl bg-[#EAE9E6] py-5">
            <div className="text-center">
              <h4 className="text-3xl font-bold uppercase">Gurinder</h4>
              <h5 className="border-b text-lg font-light uppercase py-4">Name</h5>
              <div className="mx-auto border-black border-b-2 w-10 mb-5 shadow-lg"></div>
              <h3 className="text-5xl uppercase hover:text-[#FCD129]">Price</h3>
              <h5 className="text-lg font-light pt-3 uppercase">69 Days</h5>
            </div>
          </div>
          <div className="absolute w-full">

            <img class="w-full absolute top-0 left-0 image" alt="" />
          </div>
          <div className="bg-[#FCD129] rounded-b-lg ">
            <div className="h-44 lg:h-32"></div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Receive Messages</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p> Send Messages</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Post Ad to Network</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Free Profile Matching</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Unlock 32,000+ Investor Database</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>View Phone Numbers & URL</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Private Account Manager</p>
              <BsCheckLg />
            </div>
            <div className="text-center py-6 ">
              <button
                className="bg-[#363636] text-white py-2 px-4 rounded-lg"

              >
                Buy Now
              </button>
            </div>
          </div>
        </div>
        <div className="w-full mb-10 md:w-full md:mx-2 relative cursor-default parent-div">
          <div className="border border-[#EAE9E6] rounded-t-xl bg-[#EAE9E6] py-5">
            <div className="text-center">
              <h4 className="text-3xl font-bold uppercase">Gurinder</h4>
              <h5 className="border-b text-lg font-light uppercase py-4">Name</h5>
              <div className="mx-auto border-black border-b-2 w-10 mb-5 shadow-lg"></div>
              <h3 className="text-5xl uppercase hover:text-[#FCD129]">Price</h3>
              <h5 className="text-lg font-light pt-3 uppercase">69 Days</h5>
            </div>
          </div>
          <div className="absolute w-full">

            <img class="w-full absolute top-0 left-0 image" alt="" />
          </div>
          <div className="bg-[#FCD129] rounded-b-lg ">
            <div className="h-44 lg:h-32"></div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Receive Messages</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p> Send Messages</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Post Ad to Network</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Free Profile Matching</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Unlock 32,000+ Investor Database</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>View Phone Numbers & URL</p>
              <BsCheckLg />
            </div>
            <div className=" mx-6 py-1 flex justify-between items-center border-b text-sm">
              <p>Private Account Manager</p>
              <BsCheckLg />
            </div>
            <div className="text-center py-6 ">
              <button
                className="bg-[#363636] text-white py-2 px-4 rounded-lg"

              >
                Buy Now
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Package;
